import Swal from "sweetalert2";

// FUNCTION TO CHECK THE EMAIL
export const checkTheEmail = (emailRecived) => {
	// Remove white spaces
	const emailToCheck = emailRecived.trim()

	// Regular expression for validating email address
	var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	// Check if the email input matches the email format
	if (emailRecived !== "" && !emailRegex.test(emailToCheck)) {
		Swal.fire({
			icon: 'error',
			text: 'Please enter just one valid email adress.',
			showConfirmButton: false,
			timer: 5000,
			timerProgressBar: true,
			didOpen: () => {
				Swal.hideLoading()
			},
		})

		return ""; // Prevent form submission
	}

	// Check if there is more than one email address
	if (emailRecived !== "" && emailToCheck.indexOf(',') !== -1) {
		Swal.fire({
			icon: 'error',
			text: 'Please enter only one email address.',
			showConfirmButton: false,
			timer: 5000,
			timerProgressBar: true,
			didOpen: () => {
				Swal.hideLoading()
			},
		})

		return ""; // Prevent form submission
	}

	return emailRecived
}