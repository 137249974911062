import "../../assets/css/tabs/staff.css"
import countryInformation from "../../assets/jsonData/countryInformation.json"

import view from "../../assets/images/visibility.svg"
import deleteIcon from "../../assets/images/delete.svg"
import exampleProfilePhoto from "../../assets/images/profileExample.png"
import close from "../../assets/images/close.svg"

import { useEffect, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import Swal from "sweetalert2"
import QRCode from "react-qr-code"
import axios from "axios"

const CLIENT_URL = process.env.REACT_APP_URI_CLIENT

function StaffModalView(props) {
    const downloadQR = (idQr) => {
        const qrCode = document.getElementById(idQr);

        const svgString = new XMLSerializer().serializeToString(qrCode)

        const blob = new Blob([svgString], { type: 'image/svg+xml' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'qrCode.svg';
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    return (
        <Modal
            className="staffModal"
            size="xl"
            centered
            show={props.show}
            onHide={props.onHide}
        >
            <Modal.Body>
                <img className="closeModal" src={close} onClick={() => props.onHide()} alt="Prodensa Supplier" />
                {props.data._id && <QRCode id={props.data._id} className="qrCode" bgColor="transparent" size={256} value="https://cattenna.com/" viewBox="0 0 256 256" />}
                <div className="staffInformation">
                    <img className="profilePhotoModal"
                        src={props.data.profilePhoto && (props.data.profilePhoto.document && !props.data.profilePhoto.document.name) ? process.env.REACT_APP_URI_UPLOADS + props.rfc + "/" + props.data.profilePhoto.document : exampleProfilePhoto}
                        alt="Prodensa Supplier"
                    />
                    <div className="employeeInformation">
                        <span className="textInfo">Employee information</span>
                        <div className="titlePhotoMobile">
                            <img className="profilePhotoModal d-lg-none"
                                src={props.data.profilePhoto && (props.data.profilePhoto.document && !props.data.profilePhoto.document.name) ? process.env.REACT_APP_URI_UPLOADS + props.rfc + "/" + props.data.profilePhoto.document : exampleProfilePhoto}
                                alt="Prodensa Supplier"
                            />
                            <span className="textName">{props.data.name}</span>
                            <span className="textInfo">Name</span>
                        </div>
                        <div className="dataContainer">
                            <span className="textValue">{props.data.imss}</span>
                            <span className="textInfo">IMSS</span>
                            <span className="textValue">{props.data.rfc}</span>
                            <span className="textInfo">RFC</span>
                            <span className="textValue">{props.companyName}</span>
                            <span className="textInfo">Company name</span>
                        </div>
                    </div>
                </div>
                {props.data._id && <span className="downloadQR">Download employee QR Code <label>(JPG format)</label></span>}
                {props.data._id && <button className="btnBgBlueTextGradient" onClick={() => downloadQR(props.data._id)}>Download QR Code</button>}
                <button className="btnBgRedTextWhite d-lg-none" onClick={() => { props.deleteStaff(props.clientIndex, props.staffPosition); props.onHide() }}>Delete</button>
            </Modal.Body>
        </Modal>
    )
}

function StaffModalAdd(props) {
    const [name, setName] = useState("")
    const [imss, setImss] = useState("")
    const [rfc, setRfc] = useState("")
    const [profilePicture, setProfilePicture] = useState("")

    const addEmployee = async() => {
        if (props.clients[props.clientIndex].staffs.some(staff => staff.rfc === rfc)) {
            Swal.fire({
                icon: 'error',
                text: `Failed to save information, the ${countryInformation.find(country => country.code === props.country).rfcLabel} already exist`,
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
            })
        } else {

            props.clients[props.clientIndex].staffs = [...props.clients[props.clientIndex].staffs, {
                name: name,
                imss: imss,
                rfc: rfc,
                profilePhoto: {
                    document: profilePicture,
                    uploadedAt: null,
                    yearCreatedAt: props.serverDate.year,
                    monthCreatedAt: props.serverDate.month,
                    dayCreatedAt: props.serverDate.day,
                },
            }]

            const supplierToUpdate = {
                ...props.supplier,
                clients: props.clients
            }

            const response = await props.handleSaveChanges(supplierToUpdate)
            
            if (response) {
                setName("")
                setImss("")
                setRfc("")
                setProfilePicture("")
    
                props.onHide()
            }
        }
    }

    const cancelEmployee = () => {
        setName("")
        setImss("")
        setRfc("")
        setProfilePicture("")

        props.onHide()
    }

    return (
        <Modal
            className="staffModal"
            size="lg"
            centered
            show={props.show}
            onHide={props.onHide}
        >
            <Modal.Body>
                <div className="addStaff">
                    <img className="closeModal" src={close} onClick={() => props.onHide()} alt="Prodensa Supplier" />
                    <h4 className="title">Add a new employee</h4>
                    <div className="inputContainer">
                        <span className="label">Name</span>
                        <input value={name} type="text" placeholder="Name of employee" onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className="inputContainer">
                        <span className="label">IMSS / Health Insurance Number</span>
                        <input value={imss} type="text" placeholder="000000000000" onChange={(e) => setImss(e.target.value)} />
                    </div>
                    <div className="inputContainer">
                        <span className="label">
                            {countryInformation.find(country => country.code === props.country).rfcLabel}
                        </span>
                        <input value={rfc}
                            type={countryInformation.find(country => country.code === props.country).rfcInputType}
                            placeholder={countryInformation.find(country => country.code === props.country).rfcPlaceholder}
                            onChange={(e) => setRfc(e.target.value)}
                        />
                    </div>
                    <div className="inputContainer">
                        <span className="label">Profile picture <label>Upload (5mb max)</label></span>
                        <input type="file" onChange={(e) => setProfilePicture(e.target.files[0])} />
                    </div>
                    <div className="actionButtons">
                        <button className="btnBgBlueTextGradient" onClick={() => cancelEmployee()}>Cancel</button>
                        <button className="btnBgBlueTextGradient" onClick={() => addEmployee()}>Add employee</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default function Staff(props) {
    //VARIABLE TO MANAGE THE STAFF PER CLIENT
    const clients = props.clients

    // VARIABLES TO MANAGE THE MODALs
    const [showModalAdd, setShowModalAdd] = useState(false)
    const [showModalView, setShowModalView] = useState(false)
    const [data, setData] = useState({})
    const [clientIndex, setClientIndex] = useState(null)
    const [staffPosition, setStaffPosition] = useState(null)

    // VARIABLE TO SAVE ALL THE CLIENTS
    const [allClients, setAllClients] = useState(null)

    useEffect(() => {
        getAllClients()
    }, [])

    const deleteStaff = (clientIndex, position) => {
        clients[clientIndex].staffs.splice(position, 1)

        props.setSupplier(prevState => {
            return {
                ...prevState,
                clients: clients
            }
        })
    }

    const getAllClients = () => {
        axios.get(CLIENT_URL).then(response => {
            setAllClients(response.data)
        }).catch(err => {
            console.log("Error al obtener todos los clientes")
        })
    }

    const openAddModal = (clientPosition) => {
        setShowModalAdd(true)
        setClientIndex(clientPosition)
    }

    const openViewModal = (staff, indexClient, position) => {
        setData(staff);
        setShowModalView(true);
        setClientIndex(indexClient)
        setStaffPosition(position)
    }

    return (
        <section className="staff">
            {clients.map((client, indexClient) => (
                <div key={indexClient} className="tableContainer">
                    <h5 className="titleClient">{allClients && allClients.find(allClient => allClient.entities.find(entity => entity._id === client.clientID)).entities.find(entity => entity._id === client.clientID).entityName}</h5>
                    <table className="tableStaff">
                        <thead>
                            <tr>
                                <th></th>
                                <th><span>Name</span></th>
                                <th><span>IMSS / Health Insurance Number</span></th>
                                <th><span>{countryInformation.find(country => country.code === props.country).rfcLabel}</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {client.staffs.map((staff, index) => (
                                <tr key={index}>
                                    <td>
                                        <div className="infoContainer">
                                            <img className="profilePhoto"
                                                src={staff.profilePhoto.document && !staff.profilePhoto.document.name ? process.env.REACT_APP_URI_UPLOADS + props.rfc + "/" + staff.profilePhoto.document : exampleProfilePhoto}
                                                alt="Prodensa Supplier"
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="infoContainer">
                                            <span>{staff.name}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="infoContainer">
                                            <span>{staff.imss}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="infoContainer">
                                            <span>{staff.rfc}</span>
                                            <div className="optionsTable">
                                                <img className="viewStaff" src={view} onClick={() => openViewModal(staff, indexClient, index)} alt="Prodensa Supplier" />
                                                <img className="deleteStaff" src={deleteIcon} onClick={() => deleteStaff(indexClient, index)} alt="Prodensa Supplier" />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <button className="btnBgBlueTextGradient" onClick={() => openAddModal(indexClient)}>Add employee</button>
                </div>
            ))}


            {clients.map((client, indexClient) => (
                <div className="staffsContainer d-lg-none" key={indexClient}>
                    <h5 className="titleClient">{allClients && allClients.find(allClient => allClient.entities.find(entity => entity._id === client.clientID)).entities.find(entity => entity._id === client.clientID).entityName}</h5>
                    {client.staffs.map((staff, index) => (
                        <div key={index} className="staffInformation" onClick={() => openViewModal(staff, indexClient, index)}>
                            <div className="titleAndPhoto">
                                <h5>{staff.name}</h5>
                                <span>Name</span>
                                <img src={staff.profilePhoto.document && !staff.profilePhoto.document.name ? process.env.REACT_APP_URI_UPLOADS + props.rfc + "/" + staff.profilePhoto.document : exampleProfilePhoto}
                                    alt="Prodensa Supplier"
                                />
                            </div>
                            <div className="imssAndRfc">
                                <div>
                                    <h5>{staff.imss}</h5>
                                    <span>IMSS</span>
                                </div>
                                <div>
                                    <h5>{staff.rfc}</h5>
                                    <span>RFC</span>
                                </div>
                            </div>
                        </div>
                    ))}
                    <button className="btnBgBlueTextGradient" onClick={() => openAddModal(indexClient)}>Add employee</button>
                </div>
            ))}

            <StaffModalView
                data={data}
                clientIndex={clientIndex}
                show={showModalView}
                onHide={() => setShowModalView(false)}
                companyName={props.companyName}
                rfc={props.rfc}
                staffPosition={staffPosition}
                deleteStaff={deleteStaff}
                />
            <StaffModalAdd
                supplier={props.supplier}
                clients={clients}
                clientIndex={clientIndex}
                show={showModalAdd}
                onHide={() => setShowModalAdd(false)}
                country={props.country}
                setSupplier={props.setSupplier}
                serverDate={props.serverDate}
                handleSaveChanges={props.handleSaveChanges}
            />
        </section>
    )
}