import "../../assets/css/tabs/documentCompliance.css"
import validated from "../../assets/images/validated.svg"
import validateDocuments from "../../assets/jsonData/legalDocumentsID.json"

import React, { useEffect, useState } from "react"
import moment from "moment"
import axios from "axios"
import Swal from "sweetalert2"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const CLIENT_URL = process.env.REACT_APP_URI_CLIENT
const SUPPLIER_DOCUMENT_REFERENCE_COMPLIANCE = process.env.REACT_APP_URI_SUPPLIER_DOCUMENT_REFERENCE_COMPLIANCE
const SITUACION_FISCAL_COMPLIANCE_URL = process.env.REACT_APP_URI_VALIDATE_SITUACION_FISCAL
const OPINION_CUMPLIMIENTO_URL = process.env.REACT_APP_URI_VALIDATE_OPINION_CUMPLIMIENTO
const REPSE_URL = process.env.REACT_APP_URI_VALIDATE_REPSE
const OPINION_IMSS_URL = process.env.REACT_APP_URI_VALIDATE_OPINION_IMSS
const OPINION_INFONAVIT_URL = process.env.REACT_APP_URI_VALIDATE_OPINION_INFONAVIT

export default function DocumentCompliance(props) {
	// VARIABLE TO SAVE ALL THE CLIENTS
	const [allClients, setAllClients] = useState(null)

	// VARIABLE TO MANAGE THE DOCUMENT REFERENCE FROM THE SERVER
	const documents = props.documentCompliance

	// VARIABLE TO MANAGE DE THE NUMBER OF THE ROW IN THE TABLE
	let indexCounter = 0

	useEffect(() => {
		getAllClients()
		getDocumentReferenceCompliance(props.supplierID)
	}, [])

	//FUNCTION TO CHANGES THE FILE FOR SPECIFIC GENERIC REGISTER
	const handleFileChangeGenericDocuments = async (documentType, documentPosition, value, target) => {
		let saveInformation = true

		if (value) {
			if (value.type === "application/pdf") {
				documents.genericDocuments[documentType][documentPosition].document = value

				const currentDate = {
					day: props.serverDate.day,
					month: props.serverDate.month,
					year: props.serverDate.year
				}

				if (documents.genericDocuments[documentType][documentPosition].documentID === validateDocuments.situacionFiscalFisica ||
					documents.genericDocuments[documentType][documentPosition].documentID === validateDocuments.situacionFiscalMoral) {
					Swal.fire({
						footer: 'Loading, please wait',
						showConfirmButton: false,
						allowOutsideClick: false,
						allowEscapeKey: false,
						didOpen: () => {
							Swal.showLoading()
						},
					})

					const formData = new FormData()

					formData.append("rfc", "temporal")
					formData.append("document", value, `${props.serverDate.year}-${props.serverDate.month}-${value.name}`)
					formData.append("typePerson", props.typePerson)
					formData.append("companyName", props.companyName)
					formData.append("rfcSupplier", props.rfc)
					formData.append("docDateAttempt", JSON.stringify(currentDate))

					await axios.post(SITUACION_FISCAL_COMPLIANCE_URL, formData).then(response => {
						const data = response.data
						console.log(data)
						if (data.error) {
							saveInformation = false
							Swal.fire({
								icon: 'error',
								text: `${data.message}`,
								showConfirmButton: false,
								timer: 5000,
								timerProgressBar: true,
							})
							documents.genericDocuments[documentType][documentPosition].validated = false
							documents.genericDocuments[documentType][documentPosition].document = ""
							documents.genericDocuments[documentType][documentPosition].compliant = false
							documents.genericDocuments[documentType][documentPosition].compliantPending = true
							value.value = null
							target.value = null
						} else if (data) {
							Swal.fire({
								icon: 'success',
								text: 'Document validated successfully',
								showConfirmButton: false,
								timer: 5000,
								timerProgressBar: true,
							})
							documents.genericDocuments[documentType][documentPosition].validated = true
							documents.genericDocuments[documentType][documentPosition].compliant = true
							documents.genericDocuments[documentType][documentPosition].compliantPending = false
						}
					}).catch(err => {
						saveInformation = false
						Swal.fire({
							icon: 'error',
							text: 'Failed to validate document',
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
						})
						console.log("Error al validar el archivo: " + err)
					})
				}

				if (documents.genericDocuments[documentType][documentPosition].documentID === validateDocuments.opinionCumplimientoFisica ||
					documents.genericDocuments[documentType][documentPosition].documentID === validateDocuments.opinionCumplimientoMoral) {
					Swal.fire({
						footer: 'Loading, please wait',
						showConfirmButton: false,
						allowOutsideClick: false,
						allowEscapeKey: false,
						didOpen: () => {
							Swal.showLoading()
						},
					})

					const formData = new FormData()

					formData.append("rfc", "temporal")
					formData.append("companyName", props.companyName)
					formData.append("rfcSupplier", props.rfc)
					formData.append("document", value, `${props.serverDate.year}-${props.serverDate.month}-${value.name}`)
					formData.append("docDateAttempt", JSON.stringify(currentDate))

					await axios.post(OPINION_CUMPLIMIENTO_URL, formData).then(response => {
						const data = response.data

						if (data.error) {
							saveInformation = false
							Swal.fire({
								icon: 'error',
								text: `${data.message}`,
								showConfirmButton: false,
								timer: 5000,
								timerProgressBar: true,
							})
							documents.genericDocuments[documentType][documentPosition].validated = false
							documents.genericDocuments[documentType][documentPosition].document = ""
							documents.genericDocuments[documentType][documentPosition].compliant = false
							documents.genericDocuments[documentType][documentPosition].compliantPending = true
							value.value = null
							target.value = null
						} else if (data) {
							Swal.fire({
								icon: 'success',
								text: 'Document validated successfully',
								showConfirmButton: false,
								timer: 5000,
								timerProgressBar: true,
							})
							documents.genericDocuments[documentType][documentPosition].validated = true
							documents.genericDocuments[documentType][documentPosition].compliant = true
							documents.genericDocuments[documentType][documentPosition].compliantPending = false
						}
					}).catch(err => {
						saveInformation = false
						Swal.fire({
							icon: 'error',
							text: 'Failed to validate document',
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
						})
						console.log("Error al validar el archivo: " + err)
					})
				}

				if (documents.genericDocuments[documentType][documentPosition].documentID === validateDocuments.repseMoral ||
					documents.genericDocuments[documentType][documentPosition].documentID === validateDocuments.repseFisica) {
					Swal.fire({
						footer: 'Loading, please wait',
						showConfirmButton: false,
						allowOutsideClick: false,
						allowEscapeKey: false,
						didOpen: () => {
							Swal.showLoading()
						},
					})

					const formData = new FormData()

					formData.append("rfc", "temporal")
					formData.append("document", value, `${props.serverDate.year}-${props.serverDate.month}-${value.name}`)
					formData.append("companyName", props.companyName)
					formData.append("rfcSupplier", props.rfc)
					formData.append("docDateAttempt", JSON.stringify(currentDate))

					await axios.post(REPSE_URL, formData).then(response => {
						const data = response.data

						if (data.error) {
							saveInformation = false
							console.log("Enter error")
							Swal.fire({
								icon: 'error',
								text: `${data.message}`,
								showConfirmButton: false,
								timer: 5000,
								timerProgressBar: true,
							})
							documents.genericDocuments[documentType][documentPosition].validated = false
							documents.genericDocuments[documentType][documentPosition].document = ""
							documents.genericDocuments[documentType][documentPosition].compliant = false
							documents.genericDocuments[documentType][documentPosition].compliantPending = true
							value.value = null
							target.value = null
						} else if (data) {
							console.log("Enter")
							Swal.fire({
								icon: 'success',
								text: 'Document validated successfully',
								showConfirmButton: false,
								timer: 5000,
								timerProgressBar: true,
							})
							documents.genericDocuments[documentType][documentPosition].validated = true
							documents.genericDocuments[documentType][documentPosition].compliant = true
							documents.genericDocuments[documentType][documentPosition].compliantPending = false
						}
					}).catch(err => {
						saveInformation = false
						Swal.fire({
							icon: 'error',
							text: 'Failed to validate document',
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
						})
						console.log("Error al validar el archivo: " + err)
					})
				}

				if (documents.genericDocuments[documentType][documentPosition].documentID === validateDocuments.opinionIMSSMoral ||
					documents.genericDocuments[documentType][documentPosition].documentID === validateDocuments.opinionIMSSFisica) {
					Swal.fire({
						footer: 'Loading, please wait',
						showConfirmButton: false,
						allowOutsideClick: false,
						allowEscapeKey: false,
						didOpen: () => {
							Swal.showLoading()
						},
					})

					const formData = new FormData()

					formData.append("rfc", "temporal")
					formData.append("document", value, `${props.serverDate.year}-${props.serverDate.month}-${value.name}`)
					formData.append("companyName", props.companyName)
					formData.append("rfcSupplier", props.rfc)
					formData.append("docDateAttempt", JSON.stringify(currentDate))

					await axios.post(OPINION_IMSS_URL, formData).then(response => {
						const data = response.data

						if (data.error) {
							saveInformation = false
							Swal.fire({
								icon: 'error',
								text: `${data.message}`,
								showConfirmButton: false,
								timer: 5000,
								timerProgressBar: true,
							})
							documents.genericDocuments[documentType][documentPosition].validated = false
							documents.genericDocuments[documentType][documentPosition].document = ""
							documents.genericDocuments[documentType][documentPosition].compliant = false
							documents.genericDocuments[documentType][documentPosition].compliantPending = true
							value.value = null
							target.value = null
						} else if (data) {
							Swal.fire({
								icon: 'success',
								text: 'Document validated successfully',
								showConfirmButton: false,
								timer: 5000,
								timerProgressBar: true,
							})
							documents.genericDocuments[documentType][documentPosition].validated = true
							documents.genericDocuments[documentType][documentPosition].compliant = true
							documents.genericDocuments[documentType][documentPosition].compliantPending = false
						}
					}).catch(err => {
						saveInformation = false
						Swal.fire({
							icon: 'error',
							text: 'Failed to validate document',
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
						})
						console.log("Error al validar el archivo: " + err)
					})
				}

				if (documents.genericDocuments[documentType][documentPosition].documentID === validateDocuments.opinionInfonavitMoral ||
					documents.genericDocuments[documentType][documentPosition].documentID === validateDocuments.opinionInfonavitFisica) {
					Swal.fire({
						footer: 'Loading, please wait',
						showConfirmButton: false,
						allowOutsideClick: false,
						allowEscapeKey: false,
						didOpen: () => {
							Swal.showLoading()
						},
					})

					const formData = new FormData()

					formData.append("rfc", "temporal")
					formData.append("companyName", props.companyName)
					formData.append("rfcSupplier", props.rfc)
					formData.append("document", value, `${props.serverDate.year}-${props.serverDate.month}-${value.name}`)
					formData.append("docDateAttempt", JSON.stringify(currentDate))

					await axios.post(OPINION_INFONAVIT_URL, formData).then(response => {
						const data = response.data

						if (data.error) {
							saveInformation = false
							Swal.fire({
								icon: 'error',
								text: `${data.message}`,
								showConfirmButton: false,
								timer: 5000,
								timerProgressBar: true,
							})
							documents.genericDocuments[documentType][documentPosition].validated = false
							documents.genericDocuments[documentType][documentPosition].document = ""
							documents.genericDocuments[documentType][documentPosition].compliant = false
							documents.genericDocuments[documentType][documentPosition].compliantPending = true
							value.value = null
							target.value = null
						} else if (data) {
							Swal.fire({
								icon: 'success',
								text: 'Document validated successfully',
								showConfirmButton: false,
								timer: 5000,
								timerProgressBar: true,
							})
							documents.genericDocuments[documentType][documentPosition].validated = true
							documents.genericDocuments[documentType][documentPosition].compliant = true
							documents.genericDocuments[documentType][documentPosition].compliantPending = false
						}
					}).catch(err => {
						saveInformation = false
						Swal.fire({
							icon: 'error',
							text: 'Failed to validate document',
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
						})
						console.log("Error al validar el archivo: " + err)
					})
				}

				if (saveInformation) {
					props.handleSaveChanges({
						...props.supplier,
						documentCompliance: documents
					}, false)
				}

			} else {
				value.value = null
				target.value = null
				Swal.fire({
					icon: 'error',
					text: 'The file is not a PDF.',
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
				})
			}
		}
	}

	//FUNCTION TO CHANGES THE FILE FOR SPECIFIC CLIENT REGISTER
	const handleFileChangeClientDocuments = async (clientIndex, documentType, documentPosition, value, target) => {
		if (value) {
			if (value.type === "application/pdf") {
				documents.clients[clientIndex].clientDocuments[documentType][documentPosition].document = value

				props.handleSaveChanges({
					...props.supplier,
					documentCompliance: documents
				}, false)

				// props.setSupplier(prevState => {
				// 	return {
				// 		...prevState,
				// 		documentCompliance: documents
				// 	}
				// })
			} else {
				value.value = null
				target.value = null
				Swal.fire({
					icon: 'error',
					text: 'The file is not a PDF.',
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
				})
			}
		}
	}

	// FUNCTION TO GET ALL CLIENTS
	const getAllClients = () => {
		axios.get(CLIENT_URL).then(response => {
			setAllClients(response.data)
		}).catch(err => {
			console.log("Error al obtener todos los clientes")
		})
	}

	// FUNCTION TO GET ALL DOCUMENT REFERENCE
	const getDocumentReferenceCompliance = (id) => {
		axios.get(SUPPLIER_DOCUMENT_REFERENCE_COMPLIANCE + id).then(response => {
			props.setSupplier(prevState => {
				return {
					...prevState,
					documentCompliance: response.data
				}
			})
		}).catch(err => {
			console.log("Error al obtener todos los clientes")
		})
	}

	return (
		<section className="documentCompliance">
			<h5 className="titleDocuments">Documents {moment(props.serverDate.month + "/01/" + props.serverDate.year).format("MMMM YYYY")}</h5>
			<table className="tableDocuments">
				<thead>
					<tr>
						<th></th>
						<th><span>Name</span></th>
						<th><span>Status</span></th>
						<th><span>File</span></th>
					</tr>
				</thead>
				<tbody>
					{documents && documents.genericDocuments.general.length > 0 && documents.genericDocuments.general.map((document, documentPosition) => (
						<tr key={documentPosition}>
							<td>
								<div className="infoContainer">
									<span>{indexCounter + 1 < 10 ? `0${indexCounter + 1}` : indexCounter + 1}</span>
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<span>{document.documentName}</span>
									{document.validated &&
										<OverlayTrigger placement="top"
											overlay={<Tooltip id="validatedTooltip">
												This document is validated
											</Tooltip>}>
											<img className="validated" src={validated} alt="Cattenna Platform" />
										</OverlayTrigger>
									}
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<span className={`${document.document ? "success" : "failed"}`}>{document.document ? "Uploaded" : "Pending"}</span>
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<div className="inputContainer">
										<input className={`${document.document ? "uploaded" : ""}`} accept=".pdf" type="file" id={`fileUploaded${indexCounter}`} onChange={(e) => handleFileChangeGenericDocuments("general", documentPosition, e.target.files[0], e.target)} />
										<label htmlFor={`fileUploaded${indexCounter}`}>{document.document ? "Replace" : "Select File"}</label>
									</div>
								</div>
							</td>
							{(() => { indexCounter++ })()}
						</tr>
					))}
					{documents && documents.genericDocuments.periodic.length > 0 && documents.genericDocuments.periodic.map((document, documentPosition) => (
						<tr key={documentPosition}>
							<td>
								<div className="infoContainer">
									<span>{indexCounter + 1 < 10 ? `0${indexCounter + 1}` : indexCounter + 1}</span>
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<span>{document.documentName}</span>
									{document.validated &&
										<OverlayTrigger placement="top"
											overlay={<Tooltip id="validatedTooltip">
												This document is validated
											</Tooltip>}>
											<img className="validated" src={validated} alt="Cattenna Platform" />
										</OverlayTrigger>
									}
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<span className={`${document.document ? "success" : "failed"}`}>{document.document ? "Uploaded" : "Pending"}</span>
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<div className="inputContainer">
										<input className={`${document.document ? "uploaded" : ""}`} accept=".pdf" type="file" id={`fileUploaded${indexCounter}`} onChange={(e) => handleFileChangeGenericDocuments("periodic", documentPosition, e.target.files[0], e.target)} />
										<label htmlFor={`fileUploaded${indexCounter}`}>{document.document ? "Replace" : "Select File"}</label>
									</div>
								</div>
							</td>
							{(() => { indexCounter++ })()}
						</tr>
					))}
				</tbody>
			</table>

			{documents && documents.clients.map((client, indexClient) => (client.clientDocuments.general.length > 0 || client.clientDocuments.periodic.length > 0) && (
				<React.Fragment key={indexClient}>
					<h5 className="titleDocuments d-lg-block d-none">{allClients && allClients.find(allClient => allClient.entities.find(entity => entity._id === client.clientID)).entities.find(entity => entity._id === client.clientID).entityName}</h5>
					<table className="tableDocuments">
						<thead>
							<tr>
								<th></th>
								<th><span>Name</span></th>
								<th><span>Status</span></th>
								<th><span>File</span></th>
							</tr>
						</thead>
						<tbody>
							{client.clientDocuments.general.length > 0 && client.clientDocuments.general.map((document, documentPosition) => (
								<tr key={documentPosition}>
									<td>
										<div className="infoContainer">
											<span>{indexCounter + 1 < 10 ? `0${indexCounter + 1}` : indexCounter + 1}</span>
										</div>
									</td>
									<td>
										<div className="infoContainer">
											<span>{document.documentName}</span>
											{document.validated &&
												<OverlayTrigger placement="top"
													overlay={<Tooltip id="validatedTooltip">
														This document is validated
													</Tooltip>}>
													<img className="validated" src={validated} alt="Cattenna Platform" />
												</OverlayTrigger>
											}
										</div>
									</td>
									<td>
										<div className="infoContainer">
											<span className={`${document.document ? "success" : "failed"}`}>{document.document ? "Uploaded" : "Pending"}</span>
										</div>
									</td>
									<td>
										<div className="infoContainer">
											<div className="inputContainer">
												<input className={`${document.document ? "uploaded" : ""}`} accept=".pdf" type="file" id={`fileUploaded${indexCounter}`} onChange={(e) => handleFileChangeClientDocuments(indexClient, "general", documentPosition, e.target.files[0], e.target)} />
												<label htmlFor={`fileUploaded${indexCounter}`}>{document.document ? "Replace" : "Select File"}</label>
											</div>
										</div>
									</td>
									{(() => { indexCounter++ })()}
								</tr>
							))}
							{client.clientDocuments.periodic.length > 0 && client.clientDocuments.periodic.map((document, documentPosition) => (
								<tr key={documentPosition}>
									<td>
										<div className="infoContainer">
											<span>{indexCounter + 1 < 10 ? `0${indexCounter + 1}` : indexCounter + 1}</span>
										</div>
									</td>
									<td>
										<div className="infoContainer">
											<span>{document.documentName}</span>
											{document.validated &&
												<OverlayTrigger placement="top"
													overlay={<Tooltip id="validatedTooltip">
														This document is validated
													</Tooltip>}>
													<img className="validated" src={validated} alt="Cattenna Platform" />
												</OverlayTrigger>
											}
										</div>
									</td>
									<td>
										<div className="infoContainer">
											<span className={`${document.document ? "success" : "failed"}`}>{document.document ? "Uploaded" : "Pending"}</span>
										</div>
									</td>
									<td>
										<div className="infoContainer">
											<div className="inputContainer">
												<input className={`${document.document ? "uploaded" : ""}`} accept=".pdf" type="file" id={`fileUploaded${indexCounter}`} onChange={(e) => handleFileChangeClientDocuments(indexClient, "periodic", documentPosition, e.target.files[0], e.target)} />
												<label htmlFor={`fileUploaded${indexCounter}`}>{document.document ? "Replace" : "Select File"}</label>
											</div>
										</div>
									</td>
									{(() => { indexCounter++ })()}
								</tr>
							))}
						</tbody>
					</table>
				</React.Fragment>
			))}

			<div className="d-lg-none documentComplianceMobile">
				{(() => { indexCounter = 0 })()}
				{documents && documents.genericDocuments.general.length > 0 && documents.genericDocuments.general.map((document, documentPosition) => (
					<div key={documentPosition} className="documentInformation">
						<div className="documentHeader">
							<span className="number">{indexCounter + 1 < 10 ? `0${indexCounter + 1}` : indexCounter + 1}</span>
							<span className="name">
								{document.documentName}
								{document.validated &&
									<OverlayTrigger placement="top"
										overlay={<Tooltip id="validatedTooltip">
											This document is validated
										</Tooltip>}>
										<img className="validated" src={validated} alt="Cattenna Platform" />
									</OverlayTrigger>
								}
							</span>
							<span className={`status ${document.document ? "success" : "failed"}`}></span>
						</div>
						<div className="documentBody">
							<div className="inputContainer">
								<input className={`${document.document ? "uploaded" : ""}`} accept=".pdf" type="file" id={`fileUploaded${indexCounter}`} onChange={(e) => handleFileChangeGenericDocuments("general", documentPosition, e.target.files[0], e.target)} />
								<label htmlFor={`fileUploaded${indexCounter}`}>{document.document ? "Replace" : "Select File"}</label>
							</div>
						</div>
						{(() => { indexCounter++ })()}
					</div>
				))}
				{documents && documents.genericDocuments.periodic.length > 0 && documents.genericDocuments.periodic.map((document, documentPosition) => (
					<div key={documentPosition} className="documentInformation">
						<div className="documentHeader">
							<span className="number">{indexCounter + 1 < 10 ? `0${indexCounter + 1}` : indexCounter + 1}</span>
							<span className="name">
								{document.documentName}
								{document.validated &&
									<OverlayTrigger placement="top"
										overlay={<Tooltip id="validatedTooltip">
											This document is validated
										</Tooltip>}>
										<img className="validated" src={validated} alt="Cattenna Platform" />
									</OverlayTrigger>
								}
							</span>
							<span className={`status ${document.document ? "success" : "failed"}`}></span>
						</div>
						<div className="documentBody">
							<div className="inputContainer">
								<input className={`${document.document ? "uploaded" : ""}`} accept=".pdf" type="file" id={`fileUploaded${indexCounter}`} onChange={(e) => handleFileChangeGenericDocuments("periodic", documentPosition, e.target.files[0], e.target)} />
								<label htmlFor={`fileUploaded${indexCounter}`}>{document.document ? "Replace" : "Select File"}</label>
							</div>
						</div>
						{(() => { indexCounter++ })()}
					</div>
				))}
			</div>

			{documents && documents.clients.map((client, indexClient) => (client.clientDocuments.general.length > 0 || client.clientDocuments.periodic.length > 0) && (
				<React.Fragment key={indexClient}>
					<h5 className="titleDocuments d-lg-none">{allClients && allClients.find(allClient => allClient.entities.find(entity => entity._id === client.clientID)).entities.find(entity => entity._id === client.clientID).entityName}</h5>
					<div className="d-lg-none documentComplianceMobile">
						{(() => { indexCounter = 0 })()}
						{client.clientDocuments.general.length > 0 && client.clientDocuments.general.map((document, documentPosition) => (
							<div key={documentPosition} className="documentInformation">
								<div className="documentHeader">
									<span className="number">{indexCounter + 1 < 10 ? `0${indexCounter + 1}` : indexCounter + 1}</span>
									<span className="name">
										{document.documentName}
										{document.validated &&
											<OverlayTrigger placement="top"
												overlay={<Tooltip id="validatedTooltip">
													This document is validated
												</Tooltip>}>
												<img className="validated" src={validated} alt="Cattenna Platform" />
											</OverlayTrigger>
										}
									</span>
									<span className={`status ${document.document ? "success" : "failed"}`}></span>
								</div>
								<div className="documentBody">
									<div className="inputContainer">
										<input className={`${document.document ? "uploaded" : ""}`} accept=".pdf" type="file" id={`fileUploaded${indexCounter}`} onChange={(e) => handleFileChangeClientDocuments(indexClient, "general", documentPosition, e.target.files[0], e.target)} />
										<label htmlFor={`fileUploaded${indexCounter}`}>{document.document ? "Replace" : "Select File"}</label>
									</div>
								</div>
								{(() => { indexCounter++ })()}
							</div>
						))}
						{client.clientDocuments.periodic.length > 0 && client.clientDocuments.periodic.map((document, documentPosition) => (
							<div key={documentPosition} className="documentInformation">
								<div className="documentHeader">
									<span className="number">{indexCounter + 1 < 10 ? `0${indexCounter + 1}` : indexCounter + 1}</span>
									<span className="name">
										{document.documentName}
										{document.validated &&
											<OverlayTrigger placement="top"
												overlay={<Tooltip id="validatedTooltip">
													This document is validated
												</Tooltip>}>
												<img className="validated" src={validated} alt="Cattenna Platform" />
											</OverlayTrigger>
										}
									</span>
									<span className={`status ${document.document ? "success" : "failed"}`}></span>
								</div>
								<div className="documentBody">
									<div className="inputContainer">
										<input className={`${document.document ? "uploaded" : ""}`} accept=".pdf" type="file" id={`fileUploaded${indexCounter}`} onChange={(e) => handleFileChangeClientDocuments(indexClient, "periodic", documentPosition, e.target.files[0], e.target)} />
										<label htmlFor={`fileUploaded${indexCounter}`}>{document.document ? "Replace" : "Select File"}</label>
									</div>
								</div>
								{(() => { indexCounter++ })()}
							</div>
						))}
					</div>
				</React.Fragment>
			))}
		</section>
	)
}