import "../../../assets/css/tabs/productAssignment.css"

import Axios from "axios"
import { useState, useEffect, useMemo } from "react"

import sortIcon from "../../../assets/images/sort.svg"
import Product from "./product.jsx"
import { HEADER_TITLES } from "./utils.js"

export default function ProductAssignment(props) {
    const {
        supplier,
        supplierProducts,
        supplierWarehouses,
    } = props

    const [genericList, setGenericList] = useState([])
    const [selectedInput, setSelectedInput] = useState(null)

    const [sort, setSort] = useState("locationName")
    const [sortType, setSortType] = useState("ASC")

    const supplierRfc = useMemo(() => {
        return supplier.informationCompliance.generalInformation.rfc
    }, [supplier])


    // GET THE GENERIC PRODUCTS ASSIGN TO THE SUPPLIER
    useEffect(() => {
        Axios.get(`${process.env.REACT_APP_URI_PURCHASING_SERVER}/supplier/${supplier._id}/getGenericProductsAssignedToAProvider`).then(res => {
            const result = res?.data?.assignedProducts || []
            setGenericList(result)
        }).catch(err => console.log(err?.response?.data?.error))
    }, [supplierWarehouses])

    const handleUpdateProduct = (prodToUpdate, specificProdId, whId) => {
        const genericListCopy = [...genericList]
        const prodIndex = genericListCopy.findIndex(item => item.product.locationProductId === prodToUpdate.product.locationProductId)

        genericListCopy[prodIndex].product.specificProductId = specificProdId
        genericListCopy[prodIndex].product.warehouseID = whId
        setGenericList(genericListCopy)
    }

    const handleSelectedInput = (item) => {
        setSelectedInput(item)
    }

    const handleSortItems = (property) => {
        let newSort
        if (sortType === property) {
            newSort = sort === "ASC" ? "DSC" : "ASC"
        } else {
            newSort = "ASC"
        }
        setSort(newSort)

        setSortType(property)
    }

    return (
        <section className="product-assignment">
            <div className="table-header d-none d-lg-grid">
                {HEADER_TITLES.map(item => {
                    return (
                        <div key={item.label} className="header-item">
                            <span>{item.label}</span>
                            <img src={sortIcon} alt="Prodensa Supplier" onClick={() => handleSortItems(item.property)} />
                        </div>
                    )
                })}
            </div>

            <div className="table-content">
                {
                    genericList.sort((a, b) => {
                        switch (sortType) {
                            case "locationName":
                                if (sort === "ASC") {
                                    return a.locationName.localeCompare(b.locationName)
                                } else {
                                    return b.locationName.localeCompare(a.locationName)
                                }
                            case "name":
                                if (sort === "ASC") {
                                    return a?.product?.name.localeCompare(b?.product?.name)
                                } else {
                                    return b?.product?.name.localeCompare(a?.product?.name)
                                }
                            case "productName":
                                const itemA = supplierProducts.find(item => item._id === a.product?.specificProductId)
                                const itemB = supplierProducts.find(item => item._id === b.product?.specificProductId)

                                if (sort === "ASC") {
                                    return itemA?.name.localeCompare(itemB?.name)
                                } else {
                                    return itemB?.name.localeCompare(itemA?.name)
                                }
                            case "warehouseName":
                                const itemC = supplierWarehouses.find(wh => wh._id.toString() === a?.product?.warehouseID?.toString())
                                const itemD = supplierWarehouses.find(wh => wh._id.toString() === b?.product?.warehouseID?.toString())

                                if (sort === "ASC") {
                                    return itemC?.name.localeCompare(itemD?.name)
                                } else {
                                    return itemD?.name.localeCompare(itemC?.name)
                                }
                            default:
                                return 0;
                        }
                    })
                        .map(prod => {
                            return (
                                <Product
                                    key={`${prod.product.locationProductId}-${prod.locationId}`}
                                    selectedInput={selectedInput}
                                    product={prod}
                                    supplierProducts={supplierProducts}
                                    supplierWarehouses={supplierWarehouses}
                                    supplierRfc={supplierRfc}
                                    handleSelectedInput={handleSelectedInput}
                                    handleUpdateProduct={handleUpdateProduct}
                                />
                            )
                        })}
            </div>
        </section>
    )
}

