import "../../assets/css/myProfile/myProfile.css"

import addProfilePhoto from "../../assets/images/uploadPicture.svg"
import starRating from "../../assets/images/starRating.svg"
import save from "../../assets/images/save.svg"
import cvpBadge from "../../assets/images/CVPBagdeProfile.png"

import { useEffect, useState } from "react"
import { Modal, Tab, Tabs } from "react-bootstrap"

import InformationCompliance from "../tabs/informationCompliance.jsx";
import DocumentCompliance from "../tabs/documentCompliance.jsx";
import QualitySurvey from "../tabs/qualitySurvey.jsx";
import Staff from "../tabs/staff.jsx";
import DocumentComplianceByDate from "../tabs/documentComplianceByDate.jsx"
import DocumentComplianceNew from "../tabs/documentCompliance/documentCompliance.jsx"
import Swal from "sweetalert2"
import axios from "axios"
import { useCookies } from "react-cookie"
import ProductsAndServices from "../tabs/productAndService.jsx"
import { isSameMonth, addMonthsToDate } from "../../utilities/utils.js"
import WarehousesTab from "../tabs/warehousesTab/warehousesTab.jsx"
import ProductAssignment from "../tabs/productAssignment/productAssignment.jsx"

const URI_SUPPLIER = process.env.REACT_APP_URI_SUPPLIERS
const URI_SERVER_DATE = process.env.REACT_APP_URI_SERVER_DATE
const URI_CLIENT = process.env.REACT_APP_URI_CLIENT

// MODAL TO SHOW THE CLIENT LIST
function SupplierClientsModalMyProfile(props) {
    return (
        <Modal
            className="supplierClientsModalMyProfile"
            size="lg"
            centered
            show={props.show}
            onHide={props.onHide}
        >
            <Modal.Body>
                <h4 className="commentTitle">Clients</h4>
                <table className="tableClients">
                    <thead>
                        <tr>
                            <th><span>Name</span></th>
                            <th><span>Status</span></th>
                            <th><span>Actions</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.supplierClients.map((supplierClient, index) => (
                            <tr key={index}>
                                <td>
                                    <div className="infoContainer">
                                        <span>{props.clients.find(clients => clients.entities.find(entity => entity._id === supplierClient.clientID)).entities.find(entity => entity._id === supplierClient.clientID).entityName}</span>
                                    </div>
                                </td>
                                <td>
                                    <div className="infoContainer">
                                        <span className={`${supplierClient.status === "Ready for review" ? "success" : ""} ${supplierClient.status === "Pre-authorized" || supplierClient.status === "Review requested" ? "pending" : ""} ${supplierClient.status === "New request" ? "new" : ""}`}>
                                            {supplierClient.status}
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <div className="infoContainer">
                                        <span className="actions">
                                            {
                                                (supplierClient.status === "Review requested" || supplierClient.status === "Pre-authorized") ?
                                                    <button className="btnBgBlueTextGradient" onClick={() => props.readyForReview(supplierClient.clientID)}>
                                                        Ready for review
                                                    </button> :
                                                    "No actions required"
                                            }
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="d-lg-none mobileCientList">
                    {props.supplierClients.map((supplierClient, index) => (
                        <div key={index} className="clientRow">
                            <span className="clientHeaderTitle">{props.clients.find(clients => clients.entities.find(entity => entity._id === supplierClient.clientID)).entities.find(entity => entity._id === supplierClient.clientID).entityName}</span>
                            <span className={`clientValue ${(() => {
                                switch (supplierClient.status) {
                                    case "Active":
                                    case "Ready for review":
                                        return "success"

                                    case "Pre-authorized":
                                    case "Review requested":
                                        return "pending"

                                    case "New request":
                                        return "new"

                                    case "Rejected":
                                        return "failed"

                                    default:
                                        break;
                                }
                            })()}`}>
                                {supplierClient.status}
                            </span>
                            <span className="clientTitle">Status</span>
                            <span className="clientValue">
                                {
                                    (supplierClient.status === "Review requested" || supplierClient.status === "Pre-authorized") ?
                                        <button className="btnBgBlueTextGradient" onClick={() => props.readyForReview(supplierClient.clientID)}>
                                            Ready for review
                                        </button> :
                                        "No actions required"
                                }
                            </span>
                            <span className="clientTitle">Action</span>
                        </div>
                    ))}
                </div>

                <div className="actionButton">
                    <button className="btnBgBlueTextGradient" onClick={props.onHide}>Close</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

// MODAL TO SHOW ALL THE SUPPLIER INDUSTRIES
function SupplierIndustriesModalMyProfile(props) {
    const { show, onHide, supplierIndustries } = props
    return (
        <Modal
            className="supplierIndustriesModalMyProfile"
            size="lg"
            centered
            show={show}
            onHide={onHide}
        >
            <Modal.Body>
                <h4 className="commentTitle">Industries</h4>

                <div className="industriesContainer">
                    {supplierIndustries.map((industry, index) => (
                        <span key={index} className="industry">{industry}</span>
                    ))}
                </div>

                <div className="actionButton">
                    <button className="btnBgBlueTextGradient" onClick={onHide}>Close</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default function MyProfile() {
    // VARIABLES TO MANAGE THE USER LOGGED
    const [cookie] = useCookies(["userLogged"])
    const userLogged = cookie.userLogged

    // VARIABLE TO MANAGE SUPPLIER INFO
    const [supplier, setSupplier] = useState(null);

    // VARIABLE TO MANAGE CLIENTS
    const [clients, setClients] = useState(null)

    // VARIABLE TO MANAGE SERVER DATE
    const [serverDate, setServerDate] = useState(null);

    // VARIABLE TO MANAGE THE SELECTED TAB
    const [key, setKey] = useState('Information Completed');

    // VARIABLE TO SHOW THE CLIENTS MODAL
    const [showClientsModal, setShowClientsModal] = useState(false)

    // VARIABLE TO SHOW THE INDUSTRIES MODAL
    const [showIndustriesModal, setShowIndustriesModal] = useState(false)

    const [showDocPerYearAndMonth, setShowDocPerYearAndMonth] = useState(false)
    const [monthToShow, setMonthToShow] = useState("")
    const [yearToShow, setYearToShow] = useState("")

    // VARIABLES TO CONTROL THE PERCERT COMPLETED FOR THE TABS
    const [informationCompliancePercent, setInformationCompliancePercent] = useState(0)
    const [documentCompliancePercent, setDocumentCompliancePercent] = useState(0)

    useEffect(() => {
        getServerDate();
        getSupplierByID(userLogged && userLogged.dataID);
        getAllClients()
    }, [userLogged])

    useEffect(() => {
        let contadorInformationCompliance = 0
        let contadorTotalInformationCompliance = 0

        supplier && Object.values(supplier.informationCompliance.generalInformation).forEach(value => {
            return (
                value !== "" ? contadorInformationCompliance++ : undefined,
                contadorTotalInformationCompliance++
            )
        })

        supplier && Object.values(supplier.informationCompliance.facilitiesCapabilities).forEach(value => {
            return (
                value !== "" ? contadorInformationCompliance++ : undefined,
                contadorTotalInformationCompliance++
            )
        })

        supplier && Object.values(supplier.informationCompliance.salesInformation).forEach(value => {
            return (
                value !== "" ? contadorInformationCompliance++ : undefined,
                contadorTotalInformationCompliance++
            )
        })

        supplier && setInformationCompliancePercent(Math.round(contadorInformationCompliance / contadorTotalInformationCompliance * 100))

        let contadorDocumentCompliance = 0
        let contadorTotalDocumentCompliance = 0

        let documentsForCheck = []
        supplier && supplier.documentCompliance.genericDocuments.general.length > 0 && supplier.documentCompliance.genericDocuments.general.forEach(document => {
            documentsForCheck.push(document)
        })

        supplier && supplier.documentCompliance.genericDocuments.periodic.length > 0 && supplier.documentCompliance.genericDocuments.periodic.forEach(document => {
            documentsForCheck.push(document)
        })

        supplier && Object.values(documentsForCheck).forEach(value => {
            return (
                value.compliant ? contadorDocumentCompliance++ : undefined,
                contadorTotalDocumentCompliance++
            )
        })

        supplier && setDocumentCompliancePercent(Math.round(contadorDocumentCompliance / contadorTotalDocumentCompliance * 100))
    }, [supplier])

    const handleSaveChanges = (updatedSupplier) => {
        return new Promise((resolve, _reject) => {
            const formData = new FormData();
            const supplierToSave = updatedSupplier || supplier

            formData.append("rfc", supplierToSave.informationCompliance.generalInformation.rfc) //This is specific to recive the rfc to save files by carpets
            let saveWithFile = false
            const filesToSave = []

            if (supplierToSave.informationCompliance.generalInformation.curriculum.document.name) {
                saveWithFile = true
                const fileToSave = supplierToSave.informationCompliance.generalInformation.curriculum.document
                const fileNewName = `${supplierToSave.informationCompliance.generalInformation.curriculum.yearCreatedAt}-${supplierToSave.informationCompliance.generalInformation.curriculum.monthCreatedAt}-${supplierToSave.informationCompliance.generalInformation.curriculum.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`
                filesToSave.push({
                    file: fileToSave,
                    fileNewName: fileNewName
                })
                supplierToSave.informationCompliance.generalInformation.curriculum.checkThis = true
            }
            // supplierToSave.informationCompliance.generalInformation.curriculum.document.name && formData.append("document", supplierToSave.informationCompliance.generalInformation.curriculum.document, `${supplierToSave.informationCompliance.generalInformation.curriculum.yearCreatedAt}-${supplierToSave.informationCompliance.generalInformation.curriculum.monthCreatedAt}-${supplierToSave.informationCompliance.generalInformation.curriculum.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`)
            supplierToSave.informationCompliance.generalInformation.curriculum.uploadedAt = supplierToSave.informationCompliance.generalInformation.curriculum.uploadedAt ? supplierToSave.informationCompliance.generalInformation.curriculum.document && supplierToSave.informationCompliance.generalInformation.curriculum.document.name ? `${serverDate.month}/${serverDate.day}/${serverDate.year}` : supplierToSave.informationCompliance.generalInformation.curriculum.document ? supplierToSave.informationCompliance.generalInformation.curriculum.uploadedAt : "" : supplierToSave.informationCompliance.generalInformation.curriculum.document && `${serverDate.month}/${serverDate.day}/${serverDate.year}`
            supplierToSave.informationCompliance.generalInformation.curriculum.document = supplierToSave.informationCompliance.generalInformation.curriculum.document && (supplierToSave.informationCompliance.generalInformation.curriculum.document.name ? `${supplierToSave.informationCompliance.generalInformation.curriculum.yearCreatedAt}-${supplierToSave.informationCompliance.generalInformation.curriculum.monthCreatedAt}-${supplierToSave.informationCompliance.generalInformation.curriculum.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}` : supplierToSave.informationCompliance.generalInformation.curriculum.document)

            if (!supplierToSave.informationCompliance.generalInformation.profilePhoto.yearCreatedAt || !supplierToSave.informationCompliance.generalInformation.profilePhoto.monthCreatedAt || !supplierToSave.informationCompliance.generalInformation.profilePhoto.dayCreatedAt) {
                supplierToSave.informationCompliance.generalInformation.profilePhoto.yearCreatedAt = serverDate.year
                supplierToSave.informationCompliance.generalInformation.profilePhoto.monthCreatedAt = serverDate.month
                supplierToSave.informationCompliance.generalInformation.profilePhoto.dayCreatedAt = serverDate.day
            }

            if (supplierToSave.informationCompliance.generalInformation.profilePhoto.document?.name) {
                saveWithFile = true
                const fileToSave = supplierToSave.informationCompliance.generalInformation.profilePhoto.document
                const fileNewName = `${serverDate.year}-${serverDate.month}-${supplierToSave.informationCompliance.generalInformation.profilePhoto.document?.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`
                filesToSave.push({
                    file: fileToSave,
                    fileNewName: fileNewName
                })
                supplierToSave.informationCompliance.generalInformation.profilePhoto.checkThis = true
            }
            // supplierToSave.informationCompliance.generalInformation.profilePhoto.document?.name && formData.append("document", supplierToSave.informationCompliance.generalInformation.profilePhoto.document, `${serverDate.year}-${serverDate.month}-${supplierToSave.informationCompliance.generalInformation.profilePhoto.document?.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`)
            supplierToSave.informationCompliance.generalInformation.profilePhoto.uploadedAt = supplierToSave.informationCompliance.generalInformation.profilePhoto.uploadedAt ? supplierToSave.informationCompliance.generalInformation.profilePhoto.document && supplierToSave.informationCompliance.generalInformation.profilePhoto.document?.name ? `${serverDate.month}/${serverDate.day}/${serverDate.year}` : supplierToSave.informationCompliance.generalInformation.profilePhoto.document ? supplierToSave.informationCompliance.generalInformation.profilePhoto.uploadedAt : "" : supplierToSave.informationCompliance.generalInformation.profilePhoto.document && `${serverDate.month}/${serverDate.day}/${serverDate.year}`
            supplierToSave.informationCompliance.generalInformation.profilePhoto.document = supplierToSave.informationCompliance.generalInformation.profilePhoto.document && (supplierToSave.informationCompliance.generalInformation.profilePhoto.document?.name ? `${serverDate.year}-${serverDate.month}-${supplierToSave.informationCompliance.generalInformation.profilePhoto.document?.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}` : supplierToSave.informationCompliance.generalInformation.profilePhoto.document)

            supplierToSave.documentCompliance.genericDocuments.general.forEach(document => {
                const docUploadAtValue = document.uploadedAt ? (
                    (document.document && document.document.name) ?
                        `${serverDate.month}/${serverDate.day}/${serverDate.year}` :
                        document.document ?
                            document.uploadedAt :
                            "") :
                    document.document && `${serverDate.month}/${serverDate.day}/${serverDate.year}`

                // const stringDateToConvert = isSameMonth(docDueDateValue, document.periodicity)

                // const docDueDateValue = document.dueDate ? 
                //                         document.dueDate : 
                //                         (docUploadAtValue ? 
                //                         addMonthsToDate(stringDateToConvert, document.periodicity) : 
                //                         "")

                if (document.document && document.document.name) {
                    saveWithFile = true
                    const fileToSave = document.document
                    const fileNewName = `${document.yearCreatedAt}-${document.monthCreatedAt}-${document.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`
                    filesToSave.push({
                        file: fileToSave,
                        fileNewName: fileNewName
                    })
                    document.checkThis = true
                }
                // document.document && document.document.name && formData.append("document", document.document, `${document.yearCreatedAt}-${document.monthCreatedAt}-${document.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`)
                document.uploadedAt = docUploadAtValue
                document.document = document.document && (document.document.name ? `${document.yearCreatedAt}-${document.monthCreatedAt}-${document.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}` : document.document)
                // document.dueDate = docDueDateValue

                if (!document.yearCreatedAt || !document.monthCreatedAt || !document.dayCreatedAt) {
                    document.yearCreatedAt = serverDate.year
                    document.monthCreatedAt = serverDate.month
                    document.dayCreatedAt = serverDate.day
                }
            });

            supplierToSave.documentCompliance.genericDocuments.periodic.forEach(document => {
                const docUploadAtValue = document.uploadedAt ? (
                    (document.document && document.document.name) ?
                        `${serverDate.month}/${serverDate.day}/${serverDate.year}` :
                        document.document ?
                            document.uploadedAt :
                            "") :
                    document.document && `${serverDate.month}/${serverDate.day}/${serverDate.year}`

                const stringDateToConvert = docUploadAtValue ? isSameMonth(docUploadAtValue, document.monthCreatedAt) : ""

                const docDueDateValue = document.dueDate ?
                    document.dueDate :
                    (docUploadAtValue ?
                        addMonthsToDate(stringDateToConvert, document.periodicity) :
                        "")

                if (document.document && document.document.name) {
                    saveWithFile = true
                    const fileToSave = document.document
                    const fileNewName = `${document.yearCreatedAt}-${document.monthCreatedAt}-${document.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`
                    filesToSave.push({
                        file: fileToSave,
                        fileNewName: fileNewName
                    })
                    document.checkThis = true
                }
                // document.document && document.document.name && formData.append("document", document.document, `${document.yearCreatedAt}-${document.monthCreatedAt}-${document.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`)
                document.uploadedAt = docUploadAtValue
                document.document = document.document && (document.document.name ? `${document.yearCreatedAt}-${document.monthCreatedAt}-${document.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}` : document.document)
                document.dueDate = docDueDateValue

                if (!document.yearCreatedAt || !document.monthCreatedAt || !document.dayCreatedAt) {
                    document.yearCreatedAt = serverDate.year
                    document.monthCreatedAt = serverDate.month
                    document.dayCreatedAt = serverDate.day
                }
            });

            supplierToSave.documentCompliance.clients.forEach(client => {
                client.clientDocuments.general.forEach(document => {
                    const docUploadAtValue = document.uploadedAt ? (
                        (document.document && document.document.name) ?
                            `${serverDate.month}/${serverDate.day}/${serverDate.year}` :
                            document.document ?
                                document.uploadedAt :
                                "") :
                        document.document && `${serverDate.month}/${serverDate.day}/${serverDate.year}`

                    // const stringDateToConvert = isSameMonth(docDueDateValue, document.periodicity)

                    // const docDueDateValue = document.dueDate ? 
                    //                         document.dueDate : 
                    //                         (docUploadAtValue ? 
                    //                         addMonthsToDate(stringDateToConvert, document.periodicity) : 
                    //                         "")

                    if (document.document && document.document.name) {
                        saveWithFile = true
                        const fileToSave = document.document
                        const fileNewName = `${document.yearCreatedAt}-${document.monthCreatedAt}-${document.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`
                        filesToSave.push({
                            file: fileToSave,
                            fileNewName: fileNewName
                        })
                        document.checkThis = true
                    }
                    // document.document && document.document.name && formData.append("document", document.document, `${document.yearCreatedAt}-${document.monthCreatedAt}-${document.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`)
                    document.uploadedAt = docUploadAtValue
                    document.document = document.document && (document.document.name ? `${document.yearCreatedAt}-${document.monthCreatedAt}-${document.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}` : document.document)
                    // document.dueDate = docDueDateValue

                    if (!document.yearCreatedAt || !document.monthCreatedAt || !document.dayCreatedAt) {
                        document.yearCreatedAt = serverDate.year
                        document.monthCreatedAt = serverDate.month
                        document.dayCreatedAt = serverDate.day
                    }
                });

                client.clientDocuments.periodic.forEach(document => {
                    const docUploadAtValue = document.uploadedAt ? (
                        (document.document && document.document.name) ?
                            `${serverDate.month}/${serverDate.day}/${serverDate.year}` :
                            document.document ?
                                document.uploadedAt :
                                "") :
                        document.document && `${serverDate.month}/${serverDate.day}/${serverDate.year}`

                    const stringDateToConvert = docUploadAtValue ? isSameMonth(docUploadAtValue, document.monthCreatedAt) : ""

                    const docDueDateValue = document.dueDate ?
                        document.dueDate :
                        (docUploadAtValue ?
                            addMonthsToDate(stringDateToConvert, document.periodicity) :
                            "")

                    if (document.document && document.document.name) {
                        saveWithFile = true
                        const fileToSave = document.document
                        const fileNewName = `${document.yearCreatedAt}-${document.monthCreatedAt}-${document.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`
                        filesToSave.push({
                            file: fileToSave,
                            fileNewName: fileNewName
                        })
                        document.checkThis = true
                    }
                    // document.document && document.document.name && formData.append("document", document.document, `${document.yearCreatedAt}-${document.monthCreatedAt}-${document.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`)
                    document.uploadedAt = docUploadAtValue
                    document.document = document.document && (document.document.name ? `${document.yearCreatedAt}-${document.monthCreatedAt}-${document.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}` : document.document)
                    document.dueDate = docDueDateValue

                    if (!document.yearCreatedAt || !document.monthCreatedAt || !document.dayCreatedAt) {
                        document.yearCreatedAt = serverDate.year
                        document.monthCreatedAt = serverDate.month
                        document.dayCreatedAt = serverDate.day
                    }
                });
            });

            supplierToSave.productsAndServices.products.forEach(product => {
                if (product.drawing.document && product.drawing.document.name) {
                    saveWithFile = true
                    const fileToSave = product.drawing.document
                    const fileNewName = `${product.drawing.yearCreatedAt}-${product.drawing.monthCreatedAt}-${product.drawing.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`
                    filesToSave.push({
                        file: fileToSave,
                        fileNewName: fileNewName
                    })
                    product.drawing.checkThis = true
                }
                // product.drawing.document && product.drawing.document.name && formData.append("document", product.drawing.document, `${product.drawing.yearCreatedAt}-${product.drawing.monthCreatedAt}-${product.drawing.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`)
                product.drawing.uploadedAt = product.drawing.uploadedAt ? product.drawing.document && product.drawing.document.name ? `${serverDate.month}/${serverDate.day}/${serverDate.year}` : product.drawing.document ? product.drawing.uploadedAt : "" : product.drawing.document && `${serverDate.month}/${serverDate.day}/${serverDate.year}`
                product.drawing.document = product.drawing.document && (product.drawing.document.name ? `${product.drawing.yearCreatedAt}-${product.drawing.monthCreatedAt}-${product.drawing.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}` : product.drawing.document)

                if (product.picture.document && product.picture.document.name) {
                    saveWithFile = true
                    const fileToSave = product.picture.document
                    const fileNewName = `${product.picture.yearCreatedAt}-${product.picture.monthCreatedAt}-${product.picture.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`
                    filesToSave.push({
                        file: fileToSave,
                        fileNewName: fileNewName
                    })
                    product.picture.checkThis = true
                }
                // product.picture.document && product.picture.document.name && formData.append("document", product.picture.document, `${product.picture.yearCreatedAt}-${product.picture.monthCreatedAt}-${product.picture.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`)
                product.picture.uploadedAt = product.picture.uploadedAt ? product.picture.document && product.picture.document.name ? `${serverDate.month}/${serverDate.day}/${serverDate.year}` : product.picture.document ? product.picture.uploadedAt : "" : product.picture.document && `${serverDate.month}/${serverDate.day}/${serverDate.year}`
                product.picture.document = product.picture.document && (product.picture.document.name ? `${product.picture.yearCreatedAt}-${product.picture.monthCreatedAt}-${product.picture.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}` : product.picture.document)

            })

            supplierToSave.qualitySurvey.certifications.forEach(certification => {
                if (!certification.certification.yearCreatedAt || !certification.certification.monthCreatedAt || !certification.certification.dayCreatedAt) {
                    certification.certification.yearCreatedAt = serverDate.year
                    certification.certification.monthCreatedAt = serverDate.month
                    certification.certification.dayCreatedAt = serverDate.day
                }

                if (certification.certification.document && certification.certification.document.name) {
                    saveWithFile = true
                    const fileToSave = certification.certification.document
                    const fileNewName = `${serverDate.year}-${serverDate.month}-${certification.certification.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`
                    filesToSave.push({
                        file: fileToSave,
                        fileNewName: fileNewName
                    })
                    certification.certification.checkThis = true
                }
                certification.certification.document && certification.certification.document.name && formData.append("document", certification.certification.document, `${serverDate.year}-${serverDate.month}-${certification.certification.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`)
                certification.certification.uploadedAt = certification.certification.uploadedAt ? certification.certification.document && certification.certification.document.name ? `${serverDate.month}/${serverDate.day}/${serverDate.year}` : certification.certification.document ? certification.certification.uploadedAt : "" : certification.certification.document && `${serverDate.month}/${serverDate.day}/${serverDate.year}`
                certification.certification.document = certification.certification.document && (certification.certification.document.name ? `${serverDate.year}-${serverDate.month}-${certification.certification.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}` : certification.certification.document)
            })

            saveWithFile && filesToSave.forEach(doc => {
                formData.append("document", doc.file, doc.fileNewName)
            })
            formData.append("saveWithFile", saveWithFile)
            formData.append("supplier", JSON.stringify(supplierToSave))

            Swal.fire({
                toast: true,
                footer: 'Loading, please wait',
                showConfirmButton: false,
                // allowOutsideClick: false,
                // allowEscapeKey: false,
                position: "bottom-end",
                didOpen: () => {
                    Swal.showLoading()
                },
            })

            axios.put(URI_SUPPLIER + userLogged.dataID, formData).then(response => {
                setSupplier(response.data.supplier)
                Swal.fire({
                    toast: true,
                    icon: !saveWithFile || (saveWithFile && response.data.fileSaved) ? 'success' : "error",
                    text: !saveWithFile || (saveWithFile && response.data.fileSaved) ? 'Information saved successfully' : "Failed to save information",
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    position: "bottom-end"
                })

                getSupplierByID(userLogged && userLogged.dataID);
                resolve(true)
            }).catch(err => {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    text: 'Failed to save information',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    position: "bottom-end"
                })
                console.log("Error al guardar la informacion del supplier: " + err)
                resolve(false)
            })
        })
    }

    const showYearAndMonth = (year, month) => {
        setShowDocPerYearAndMonth(true)
        setYearToShow(year)
        setMonthToShow(month)
    }

    // const closeYearAndMonth = () => {
    //     setShowDocPerYearAndMonth(false)
    // }

    // GET THE SERVER DATE
    const getServerDate = () => {
        axios.get(URI_SERVER_DATE).then(response => {
            setServerDate(response.data)
        }).catch(err => {
            console.log("Error al obtener la fecha del servidor" + err)
        })
    }

    // GET THE SUPPLIER BY THE ID
    const getSupplierByID = (id) => {
        axios.get(URI_SUPPLIER + id).then(response => {
            setSupplier(response.data)
        }).catch(err => {
            console.log("Error al obtener el supplier por id:" + err)
        })
    }

    // FUNCTION TO GET ALL CLIENTS
    const getAllClients = () => {
        axios.get(URI_CLIENT).then(response => {
            setClients(response.data)
        }).catch(err => {
            console.log("Error al obtener los clientes")
        })
    }

    // FUNCTION TO SET THE CLIENT STATUS READY F0R REVIEW
    const readyForReview = (clientID) => {
        Swal.fire({
            footer: 'Loading, please wait',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading()
            },
        })

        const supplierToSave = supplier

        supplierToSave.clients.find(client => client.clientID === clientID).status = "Ready for review"

        axios.put(URI_SUPPLIER + userLogged.dataID, {
            supplier: JSON.stringify(supplierToSave),
            sendEmail: true,
            supplierClientID: JSON.stringify([clientID]),
            dataID: userLogged.dataID,
            rol: userLogged.rol
        }).then(response => {
            Swal.fire({
                icon: 'success',
                text: 'Supplier ready for review successfully',
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
            })

            getSupplierByID(userLogged.dataID);
        }).catch(err => {
            Swal.fire({
                icon: 'error',
                text: 'Failed to ready for review the supplier',
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
            })
            console.log("Error al autorizar el supplier: " + err)
        })
    }

    // FUNCTION TO HANDLE DE PROFILE PICTURE CHANGE
    const handleProfilePictureChange = (element) => {
        if (element.files[0].name) {
            const supplierClone = { ...supplier }

            supplierClone.informationCompliance.generalInformation.profilePhoto.document = element.files[0]

            handleSaveChanges(supplierClone)
        }
    }

    return (
        <section className="myProfile">
            <div className="header">
                <div className="generalInformation">
                    <div className="headInformation">
                        <div className="profilePhotoContainer">
                            <input className="inputProfilePhoto" type="file" onChange={(e) => handleProfilePictureChange(e.target)} />
                            <img className="profilePhoto" src={
                                supplier && supplier.informationCompliance.generalInformation.profilePhoto.document && !supplier.informationCompliance.generalInformation.profilePhoto.document.name
                                    ? process.env.REACT_APP_URI_UPLOADS + supplier.informationCompliance.generalInformation.rfc + "/" + supplier.informationCompliance.generalInformation.profilePhoto.document
                                    : addProfilePhoto
                            } alt="Prodensa Supplier" />
                        </div>
                        <h3>{supplier && supplier.informationCompliance.generalInformation.companyName}</h3>
                        <h4 className="ratingValue">
                            {supplier && supplier.rating ? supplier.rating.$numberDecimal : "Unrated"}
                            {supplier && supplier.rating && supplier.rating.$numberDecimal ? <img className="ratingImg" src={starRating} alt="Prodensa Supplier" /> : undefined}
                        </h4>
                    </div>
                    <div className="industriesInformation">
                        <div className="industriesContainer">
                            {supplier && supplier.informationCompliance.facilitiesCapabilities.categories.map((category, index) => (
                                index < 4 && <span key={index}>{category}</span>
                            ))}
                        </div>
                        {supplier && supplier.informationCompliance.facilitiesCapabilities.categories.length > 3 && <button className="btnBgBlueTextGradient" onClick={() => setShowIndustriesModal(true)}>
                            See all ({supplier && supplier.informationCompliance.facilitiesCapabilities.categories.length})
                        </button>}
                    </div>
                    <p className="description">{supplier && supplier.informationCompliance.facilitiesCapabilities.description}</p>
                    <button className="btnBgBlueTextGradient" onClick={() => setShowClientsModal(true)}>
                        View clients
                    </button>
                </div>

                <div className="contactInformation">
                    {supplier && supplier.informationCompliance.generalInformation.cvp.certified && <img className="cvpBadge" src={cvpBadge} alt="Prodensa Supplier" />}
                    <div className="info">
                        <span className="infoTitle">Address</span>
                        <span className="infoText">
                            {supplier && supplier.informationCompliance.generalInformation.address.streetAndNumber}<br />
                            C.P. {supplier && supplier.informationCompliance.generalInformation.address.zipCode}<br />
                            {supplier && supplier.informationCompliance.generalInformation.address.city + " " + supplier.informationCompliance.generalInformation.address.state}

                        </span>
                    </div>
                    <div className="info">
                        <span className="infoTitle">Contact information</span>
                        <span className="infoText">
                            {supplier && supplier.informationCompliance.generalInformation.contactInformation?.name}<br />
                            {supplier && supplier.informationCompliance.generalInformation.contactInformation?.email}<br />
                            {supplier && supplier.informationCompliance.generalInformation.contactInformation?.phone}
                        </span>
                    </div>
                    <div className="info">
                        <span className="infoTitle">Website</span>
                        <span className="infoText">{supplier && supplier.informationCompliance.generalInformation.website}</span>
                    </div>
                </div>
                <button className="btnBgBlueTextGradient d-lg-none" onClick={() => setShowClientsModal(true)}>
                    View clients
                </button>
            </div>

            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
            >
                <Tab eventKey="Information Completed" title={`1 Information Completed (${informationCompliancePercent}%)`}>
                    {supplier && serverDate && <InformationCompliance
                        informationCompliance={supplier.informationCompliance}
                        setSupplier={setSupplier}
                        username={userLogged && userLogged.username}
                        serverDate={serverDate}
                    />}
                </Tab>
                <Tab eventKey="Document compliance" title={`2 Document compliance (${documentCompliancePercent}%)`}>
                    {/* {supplier && serverDate && !showDocPerYearAndMonth && <DocumentComplianceByDate
                        showYearAndMonth={showYearAndMonth}
                        documentCompliance={supplier.documentCompliance}
                        setSupplier={setSupplier}
                        supplierID={userLogged && userLogged.dataID}
                        serverDate={serverDate}
                        typePerson={supplier.informationCompliance.generalInformation.typePerson}
                        rfc={supplier.informationCompliance.generalInformation.rfc}
                        companyName={supplier.informationCompliance.generalInformation.companyName}
                    />}
                    {supplier && serverDate && showDocPerYearAndMonth && <DocumentCompliance
                        yearToShow={yearToShow}
                        monthToShow={monthToShow}
                        closeYearAndMonth={closeYearAndMonth}
                        documentCompliance={supplier.documentCompliance}
                        setSupplier={setSupplier}
                        typePerson={supplier.informationCompliance.generalInformation.typePerson}
                        rfc={supplier.informationCompliance.generalInformation.rfc}
                        serverDate={serverDate}
                        companyName={supplier.informationCompliance.generalInformation.companyName}
                    />} */}
                    {supplier && serverDate && <DocumentComplianceNew
                        showYearAndMonth={showYearAndMonth}
                        documentCompliance={supplier.documentCompliance}
                        supplier={supplier}
                        setSupplier={setSupplier}
                        supplierID={userLogged && userLogged.dataID}
                        serverDate={serverDate}
                        typePerson={supplier.informationCompliance.generalInformation.typePerson}
                        rfc={supplier.informationCompliance.generalInformation.rfc}
                        companyName={supplier.informationCompliance.generalInformation.companyName}
                        handleSaveChanges={handleSaveChanges}
                    />}
                </Tab>
                <Tab eventKey="Quality Survey" title="3 Quality Survey">
                    {supplier && <QualitySurvey
                        qualitySurvey={supplier.qualitySurvey}
                        setSupplier={setSupplier}
                        rfc={supplier.informationCompliance.generalInformation.rfc}
                    />}
                </Tab>
                <Tab eventKey="Products/Services" title="4 Products/Services">
                    {supplier && serverDate && <ProductsAndServices
                        supplier={supplier}
                        productsAndServices={supplier.productsAndServices}
                        setSupplier={setSupplier}
                        serverDate={serverDate}
                        userLogged={userLogged}
                        rfc={supplier.informationCompliance.generalInformation.rfc}
                        handleSaveChanges={handleSaveChanges}
                    />}
                </Tab>
                <Tab eventKey="Warehouses" title="5 Warehouses">
                    {supplier && serverDate && <WarehousesTab
                        supplier={supplier}
                        supplierProducts={supplier.productsAndServices.products}
                        supplierWarehouses={supplier.warehouses}
                        setSupplier={setSupplier}
                        handleSaveChanges={handleSaveChanges}
                    />}
                </Tab>
                <Tab eventKey="Products Assignation" title="6 Product Assignment">
                    {supplier && serverDate && <ProductAssignment
                        supplier={supplier}
                        supplierProducts={supplier.productsAndServices.products}
                        supplierWarehouses={supplier.warehouses}
                        setSupplier={setSupplier}
                        handleSaveChanges={handleSaveChanges}
                    />}
                </Tab>
            </Tabs>

            {key !== "Document compliance" && <div className="actionButtons">
                <button className="btnBgBlueTextGradient" onClick={() => handleSaveChanges(supplier)}>Save changes</button>
            </div>}

            <button className="saveChanges" onClick={() => handleSaveChanges(supplier)}>
                <img src={save} alt="Prodensa Supplier" />
            </button>

            {supplier && clients && <SupplierClientsModalMyProfile
                supplierClients={supplier.clients}
                clients={clients}
                show={showClientsModal}
                onHide={() => setShowClientsModal(false)}
                readyForReview={readyForReview}
            />}

            {supplier && <SupplierIndustriesModalMyProfile
                supplierIndustries={supplier.informationCompliance.facilitiesCapabilities.categories}
                show={showIndustriesModal}
                onHide={() => setShowIndustriesModal(false)}
            />}
        </section>
    )
}