import "../../assets/css/clientsSupplierView/clientStaff.css"
import countryInformation from "../../assets/jsonData/countryInformation.json"

import view from "../../assets/images/visibility.svg"
import deleteIcon from "../../assets/images/delete.svg"
import exampleProfilePhoto from "../../assets/images/profileExample.png"
import close from "../../assets/images/close.svg"

import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2"
import QRCode from "react-qr-code"
import axios from "axios"

const URI_SUPPLIER = process.env.REACT_APP_URI_SUPPLIERS

function StaffModalView(props) {
    // VARIABLE TO MANAGE THE SUPPLIER COMPANY NAME, RFC, STAFF POSITION, DELETE STAFF AND STAFF DATA
    const { data, supplierCompanyName, supplierRFC, staffPosition, deleteStaff } = props

    const downloadQR = (idQr) => {
        const qrCode = document.getElementById(idQr);

        const svgString = new XMLSerializer().serializeToString(qrCode)

        const blob = new Blob([svgString], { type: 'image/svg+xml' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'qrCode.svg';
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    return (
        <Modal
            className="staffModal"
            size="xl"
            centered
            show={props.show}
            onHide={props.onHide}
        >
            <Modal.Body>
                <img className="closeModal" src={close} onClick={() => props.onHide()} alt="Prodensa Supplier" />
                {data._id && <QRCode id={data._id} className="qrCode" bgColor="transparent" size={256} value="https://cattenna.com/" viewBox="0 0 256 256" />}
                <div className="staffInformation">
                    <img className="profilePhotoModal"
                        src={data.profilePhoto && (data.profilePhoto.document && !data.profilePhoto.document.name) ? process.env.REACT_APP_URI_UPLOADS + supplierRFC + "/" + data.profilePhoto.document : exampleProfilePhoto}
                        alt="Prodensa Supplier"
                    />
                    <div className="employeeInformation">
                        <span className="textInfo">Employee information</span>
                        <div className="titlePhotoMobile">
                            <img className="profilePhotoModal d-lg-none"
                                src={data.profilePhoto && (data.profilePhoto.document && !data.profilePhoto.document.name) ? process.env.REACT_APP_URI_UPLOADS + supplierRFC + "/" + data.profilePhoto.document : exampleProfilePhoto}
                                alt="Prodensa Supplier"
                            />
                            <span className="textName">{data.name}</span>
                            <span className="textInfo">Name</span>
                        </div>
                        <div className="dataContainer">
                            <span className="textValue">{data.imss}</span>
                            <span className="textInfo">IMSS</span>
                            <span className="textValue">{data.rfc}</span>
                            <span className="textInfo">RFC</span>
                            <span className="textValue">{supplierCompanyName}</span>
                            <span className="textInfo">Company name</span>
                        </div>
                    </div>
                </div>
                {data._id && <span className="downloadQR">Download employee QR Code <label>(JPG format)</label></span>}
                {data._id && <button className="btnBgBlueTextGradient" onClick={() => downloadQR(data._id)}>Download QR Code</button>}
                <button className="btnBgRedTextWhite d-lg-none" onClick={() => { deleteStaff(staffPosition); props.onHide() }}>Delete</button>
            </Modal.Body>
        </Modal>
    )
}

function StaffModalAdd(props) {
    const [name, setName] = useState("")
    const [imss, setImss] = useState("")
    const [rfc, setRfc] = useState("")
    const [profilePicture, setProfilePicture] = useState("")

    const addEmployee = () => {
        if (props.clientStaff.some(staff => staff.rfc === rfc)) {
            Swal.fire({
                icon: 'error',
                text: `Failed to save information, the ${countryInformation.find(country => country.code === props.country).rfcLabel} already exist`,
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
            })
        } else {
            const copyClientStaff = [...props.clientStaff, {
                name: name,
                imss: imss,
                rfc: rfc,
                profilePhoto: {
                    document: profilePicture,
                    uploadedAt: null,
                    yearCreatedAt: props.serverDate.year,
                    monthCreatedAt: props.serverDate.month,
                    dayCreatedAt: props.serverDate.day,
                },
            }]

            props.setClientStaff(copyClientStaff)

            setName("")
            setImss("")
            setRfc("")
            setProfilePicture("")

            props.onHide()
        }
    }

    const cancelEmployee = () => {
        setName("")
        setImss("")
        setRfc("")
        setProfilePicture("")

        props.onHide()
    }

    return (
        <Modal
            className="staffModal"
            size="lg"
            centered
            show={props.show}
            onHide={props.onHide}
        >
            <Modal.Body>
                <div className="addStaff">
                    <img className="closeModal" src={close} onClick={() => props.onHide()} alt="Prodensa Supplier" />
                    <h4 className="title">Add a new employee</h4>
                    <div className="inputContainer">
                        <span className="label">Name</span>
                        <input value={name} type="text" placeholder="Name of employee" onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className="inputContainer">
                        <span className="label">IMSS / Health Insurance Number</span>
                        <input value={imss} type="text" placeholder="000000000000" onChange={(e) => setImss(e.target.value)} />
                    </div>
                    <div className="inputContainer">
                        <span className="label">
                            {countryInformation.find(country => country.code === props.country).rfcLabel}
                        </span>
                        <input value={rfc}
                            type={countryInformation.find(country => country.code === props.country).rfcInputType}
                            placeholder={countryInformation.find(country => country.code === props.country).rfcPlaceholder}
                            onChange={(e) => setRfc(e.target.value)}
                        />
                    </div>
                    <div className="inputContainer">
                        <span className="label">Profile picture <label>Upload (5mb max)</label></span>
                        <input type="file" onChange={(e) => setProfilePicture(e.target.files[0])} />
                    </div>
                    <div className="actionButtons">
                        <button className="btnBgBlueTextGradient" onClick={() => cancelEmployee()}>Cancel</button>
                        <button className="btnBgBlueTextGradient" onClick={() => addEmployee()}>Add employee</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default function ClientStaff(props) {
    // VARIABLE TO MANAGE THE CLIENT STAFF
    const [clientStaff, setClientStaff] = useState(props.clientStaff)

    // VARIABLE TO CONTROL THE AUTOSAVE
    const [enableSave, setEnableSave] = useState(false)

    // VARIABLE TO MANAGE THE SUPPLIER COUNTRY AND RFC
    const { supplierCopyToSave, supplierCompanyName, supplierRFC, supplierCountry, serverDate, entityID } = props

    // VARIABLES TO MANAGE THE MODALs
    const [showModalAdd, setShowModalAdd] = useState(false)
    const [showModalView, setShowModalView] = useState(false)
    const [data, setData] = useState({})
    const [staffPosition, setStaffPosition] = useState(null)

    // PREVENT TO AUTO SAVE THE FIRST TIME COMPONENT RENDERS, UPDATE WHEN CLIENT STAFF CHANGES
    useEffect(() => {
        if (!enableSave) {
            setEnableSave(true)
            return
        }

        handleSaveStaff()
    }, [clientStaff])

    // FUNCTION TO DELETE A SPECIFIC STAFF
    const deleteStaff = (position) => {
        const copyClientStaff = JSON.parse(JSON.stringify(clientStaff))

        copyClientStaff.splice(position, 1)

        setClientStaff(copyClientStaff)
    }

    const openAddModal = () => {
        setShowModalAdd(true)
    }

    const openViewModal = (staff, position) => {
        setData(staff);
        setShowModalView(true);
        setStaffPosition(position)
    }

    // FUNCTION TO SAVE THE STAFF IN THE SUPPLIER
    const handleSaveStaff = () => {
        supplierCopyToSave.clients.find(entity => entity.clientID === entityID).staffs = [...clientStaff]

        const formData = new FormData();

        formData.append("rfc", supplierCopyToSave.informationCompliance.generalInformation.rfc) //This is specific to recive the rfc to save files by carpets

        supplierCopyToSave.clients.forEach(client => {
            client.staffs.forEach(staff => {
                staff.profilePhoto.document && staff.profilePhoto.document.name && formData.append("document", staff.profilePhoto.document, `${staff.profilePhoto.yearCreatedAt}-${staff.profilePhoto.monthCreatedAt}-${staff.profilePhoto.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`)
                staff.profilePhoto.uploadedAt = staff.profilePhoto.uploadedAt ? staff.profilePhoto.document && staff.profilePhoto.document.name ? `${serverDate.month}/${serverDate.day}/${serverDate.year}` : staff.profilePhoto.document ? staff.profilePhoto.uploadedAt : "" : staff.profilePhoto.document && `${serverDate.month}/${serverDate.day}/${serverDate.year}`
                staff.profilePhoto.document = staff.profilePhoto.document && (staff.profilePhoto.document.name ? `${staff.profilePhoto.yearCreatedAt}-${staff.profilePhoto.monthCreatedAt}-${staff.profilePhoto.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}` : staff.profilePhoto.document)
            });
        })

        formData.append("supplier", JSON.stringify(supplierCopyToSave))

        Swal.fire({
            toast: true,
            footer: 'Loading, please wait',
            showConfirmButton: false,
            // allowOutsideClick: false,
            // allowEscapeKey: false,
            position: "bottom-end",
            didOpen: () => {
                Swal.showLoading()
            },
        })

        axios.put(URI_SUPPLIER + supplierCopyToSave._id, formData).then(response => {
            Swal.fire({
                toast: true,
                icon: 'success',
                text: 'Information saved successfully',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                position: "bottom-end"
            })

        }).catch(err => {
            Swal.fire({
                toast: true,
                icon: 'error',
                text: 'Failed to save information',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                position: "bottom-end"
            })
            console.log("Error al guardar la informacion del supplier: " + err)
        })
    }

    return (
        <section className="clientStaff">
            <table className="clientTableStaff">
                <thead>
                    <tr>
                        <th></th>
                        <th><span>Name</span></th>
                        <th><span>IMSS / Health Insurance Number</span></th>
                        <th><span>{countryInformation.find(country => country.code === supplierCountry).rfcLabel}</span></th>
                    </tr>
                </thead>
                <tbody>
                    {clientStaff.map((staff, index) => (
                        <tr key={index}>
                            <td>
                                <div className="infoContainer">
                                    <img className="profilePhoto"
                                        src={staff.profilePhoto.document && !staff.profilePhoto.document.name ? process.env.REACT_APP_URI_UPLOADS + supplierRFC + "/" + staff.profilePhoto.document : exampleProfilePhoto}
                                        alt="Prodensa Supplier"
                                    />
                                </div>
                            </td>
                            <td>
                                <div className="infoContainer">
                                    <span>{staff.name}</span>
                                </div>
                            </td>
                            <td>
                                <div className="infoContainer">
                                    <span>{staff.imss}</span>
                                </div>
                            </td>
                            <td>
                                <div className="infoContainer">
                                    <span>{staff.rfc}</span>
                                    <div className="optionsTable">
                                        <img className="viewStaff" src={view} onClick={() => openViewModal(staff, index)} alt="Prodensa Supplier" />
                                        <img className="deleteStaff" src={deleteIcon} onClick={() => deleteStaff(index)} alt="Prodensa Supplier" />
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="clientStaffsContainer d-lg-none">
                {clientStaff.map((staff, index) => (
                    <div key={index} className="staffInformation" onClick={() => openViewModal(staff, index)}>
                        <div className="titleAndPhoto">
                            <h5>{staff.name}</h5>
                            <span>Name</span>
                            <img src={staff.profilePhoto.document && !staff.profilePhoto.document.name ? process.env.REACT_APP_URI_UPLOADS + supplierRFC + "/" + staff.profilePhoto.document : exampleProfilePhoto}
                                alt="Prodensa Supplier"
                            />
                        </div>
                        <div className="imssAndRfc">
                            <div>
                                <h5>{staff.imss}</h5>
                                <span>IMSS</span>
                            </div>
                            <div>
                                <h5>{staff.rfc}</h5>
                                <span>RFC</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <button className="btnBgBlueTextGradient" onClick={() => openAddModal()}>Add employee</button>

            <StaffModalView
                data={data}
                show={showModalView}
                onHide={() => setShowModalView(false)}
                supplierCompanyName={supplierCompanyName}
                supplierRFC={supplierRFC}
                staffPosition={staffPosition}
                deleteStaff={deleteStaff}
            />

            <StaffModalAdd
                show={showModalAdd}
                onHide={() => setShowModalAdd(false)}
                country={supplierCountry}
                serverDate={serverDate}
                clientStaff={clientStaff}
                setClientStaff={setClientStaff}
            />
        </section>
    )
}