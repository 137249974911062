import "../assets/css/pages/home.css"

import profileExample from "../assets/images/profileExample.png"
import logo from "../assets/images/logo.png"
import menuBlue from "../assets/images/menuBlue.svg"

import { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, Outlet, useNavigate } from "react-router-dom";
import NavigationMenu from "../components/navigationMenu/navigationMenu.jsx";
import axios from "axios";
import { useCookies } from "react-cookie";

const URI_SUPPLIER = process.env.REACT_APP_URI_SUPPLIERS
const URI_CLIENT = process.env.REACT_APP_URI_CLIENT


export default function Home() {
	const [cookie, setCookie, removeCookie] = useCookies(["userLogged"])

	const userLogged = cookie.userLogged

	const [supplier, setSupplier] = useState(null)
	const [client, setClient] = useState(null)

	const [showMobileMenu, setShowMobileMenu] = useState(false);
	const [showUserOption, setShowUserOption] = useState(false);
	const userOptionRef = useRef();
	const navigate = useNavigate()

	useEffect(() => {
		if (userLogged) {
			const actualDate = new Date();
			const cookieExpiration = actualDate.setDate(actualDate.getDate() + 7)
			const maxAge = Math.floor((cookieExpiration - new Date()) / 1000)

			setCookie("userLogged", userLogged, { maxAge })

			if (userLogged.rol === "Supplier") {
				getSupplierByID(userLogged.dataID);
			} else {
				getClientByID(userLogged.dataID);
			}
		} else {
			navigate("/404")
		}

		document.body.addEventListener("click", closeUserOption)

		return function cleanup() {
			document.body.removeEventListener("click", closeUserOption)
		}
	}, [])

	const closeUserOption = (e) => {
		if (!userOptionRef.current.contains(e.target)) {
			setShowUserOption(false)
		}
	}

	const getSupplierByID = (id) => {
		axios.get(URI_SUPPLIER + id).then(response => {
			setSupplier(response.data)
		}).catch(err => {
			console.log("Error al obtener el supplier por id:" + err)
		})
	}

	const getClientByID = (id) => {
		axios.post(URI_CLIENT + id, {
			rol: userLogged.rol
		}).then(response => {
			setClient(response.data)
		}).catch(err => {
			console.log("Error al obtener el client por id:" + id)
		})
	}

	return (
		<>
			{supplier && <NavigationMenu firstTime={supplier.firstTime} showMobileMenu={showMobileMenu} setShowMobileMenu={setShowMobileMenu} /> || client && <NavigationMenu showMobileMenu={showMobileMenu} setShowMobileMenu={setShowMobileMenu} />}

			<div className="container">
				<Row>
					<Col className="d-none d-lg-block" xs={{ span: 10, offset: 1 }} lg={{ span: 3, offset: 9 }}>
						<div ref={userOptionRef} className={`userLogged ${showUserOption ? "active" : ""}`} onClick={() => setShowUserOption(prevState => !prevState)}>
							<img className="userImage" src={profileExample} alt="Prodensa Supplier" />
							{userLogged && (userLogged.rol === "Master" || userLogged.rol === "Super admin" || userLogged.rol === "Admin client") && client && <span>{client.generalInformation.clientName}</span>}
							{userLogged && userLogged.rol === "Client" && client && <span>{client.entities.find(entity => entity._id.toString() === userLogged.dataID.toString()).entityName}</span>}
							{userLogged && userLogged.rol === "Supplier" && supplier && <span>{supplier.informationCompliance.generalInformation.contactInformation?.name}</span>}
							<Link
								to={"/login"}
								className={`logOut ${showUserOption ? "show" : ""}`}
								onClick={() => {
									removeCookie("userLogged")
									sessionStorage.removeItem("currentPageSupplier")
									sessionStorage.removeItem("currentPageRequest")
								}}>
								Sign Out
							</Link>
						</div>
					</Col>
					<Col className="d-lg-none" xs={{ span: 12, offset: 0 }} lg={{ span: 3, offset: 9 }}>
						<div className="logoAndMenuMobile">
							<img className="hamburguer" src={menuBlue} alt="Prodensa Supplier" onClick={() => setShowMobileMenu(true)} />
							<img className="logo" src={logo} alt="Prodensa Supplier" />
						</div>
					</Col>
					<Col xs={{ span: 12, offset: 0 }} lg={{ span: 12, offset: 0 }}>
						<Outlet />
					</Col>
				</Row>
			</div >
		</>
	)
}