import Axios from "axios"

import { useEffect, useState, useMemo } from "react"
import { useCookies } from "react-cookie"
import { Modal } from "react-bootstrap"

import ProdAsignTable from "./prodAndOrdersTable.jsx"
import { HEADER_INFO, PRODUCTS_TABS, ABLE_TO_DO_ROLS, getTextToShow, filterProducts, updateProdIdsRemovedFromWarehouse } from "./utils.js"

import edit from "../../../assets/images/editBlue.svg"
import close from "../../../assets/images/close.svg"

export default function Warehouse(props) {
    const {
        supplier,
        supplierProducts,
        selectedWarehouse,
        setSelectedWarehouse,
        handleEditWarehouse,
        handleSaveChanges
    } = props

    const [productTab, setProductTab] = useState(PRODUCTS_TABS[0])
    const [showModal, setShowModal] = useState(false)
    const [productsList, setProductsList] = useState([])
    const [prodListPrevState, setProdListPrevState] = useState([])
    const [searchProduct, setSearchProduct] = useState("")
    const [itemsToShow, setItemsToShow] = useState([])
    const [showMoreOptions, setShowMoreOptions] = useState(null)

    const [cookie] = useCookies(["userLogged"])
    const userLogged = useMemo(() => cookie?.userLogged, [cookie?.userLogged]);
    const ableToDo = ABLE_TO_DO_ROLS.includes(userLogged?.rol)

    useEffect(() => {
        const result = supplierProducts.map(item => {
            return {
                _id: item._id,
                barcode: item.barcode,
                stock: selectedWarehouse.products.find(checkItem => checkItem.barcode === item.barcode)?.stock ?? 0,
                inWarehouse: selectedWarehouse.products.some(checkItem => checkItem.barcode === item.barcode)
            }
        })

        setProductsList(result)
        setProdListPrevState(result.filter(item => item.inWarehouse))
    }, [selectedWarehouse, showModal, supplierProducts])

    useEffect(() => {
        function closeMoreOptions(e) {
            if (e.target.className !== "more-dots") {
                setShowMoreOptions(null)
            }
        }

        document.body.addEventListener("click", closeMoreOptions)

        return function cleanUp() {
            document.body.removeEventListener("click", closeMoreOptions)
        }
    }, [])

    useEffect(() => {
        if (productTab === PRODUCTS_TABS[0]) {
            setItemsToShow(selectedWarehouse.products)

            return
        }

        Axios.get(`${process.env.REACT_APP_URI_PURCHASING_SERVER}/order/supplier/${supplier._id}/warehouse/${selectedWarehouse._id}/getAllBySupplierAndWarehouse`).then(res => {
            setItemsToShow(res.data?.orders)
        }).catch(err => {
            setItemsToShow([])
            console.log(err)
        })
    }, [productTab, selectedWarehouse])

    const handleProductTab = (tab) => {
        setProductTab(tab)
    }

    const handleSearchProduct = (value) => {
        setSearchProduct(value)
        const filteredList = filterProducts(supplierProducts, value)

        setProductsList(filteredList)
    }

    const handleCloseModal = () => {
        setShowModal(false)
    }

    const handleAddProduct = () => {
        setShowModal(true)
    }

    const handleCheckProduct = (barcode) => {
        const productsListCopy = [...productsList]

        const productIndex = productsListCopy.findIndex(item => item.barcode === barcode)

        productsListCopy[productIndex].inWarehouse = !productsListCopy[productIndex].inWarehouse
        productsListCopy[productIndex].stock = productsListCopy[productIndex].stock ?? 0

        setProductsList(productsListCopy)
    }


    const handleUpdateProducts = () => {
        const selectedWarehouseCopy = { ...selectedWarehouse }

        selectedWarehouseCopy.products = productsList.filter(item => item.inWarehouse)


        // CHECK IF A PRODUCT HAS BEEN DELETED FROM A WAREHOUSE TO SEND A NOTIFY TO PURCHASING
        updateProdIdsRemovedFromWarehouse(productsList, prodListPrevState, selectedWarehouse._id)

        setProdListPrevState(productsList.filter(item => item.inWarehouse))


        // SAVE NEW STATE OF PRODUCTS IN SPECIFIC WAREHOUSE
        const warehousesCopy = [...supplier.warehouses]
        const warehouseIndex = supplier.warehouses.findIndex(warehouse => warehouse._id === selectedWarehouseCopy._id)
        warehousesCopy[warehouseIndex] = selectedWarehouseCopy

        const supplierToSave = {
            ...supplier,
            warehouses: warehousesCopy
        }

        setSelectedWarehouse(selectedWarehouseCopy)

        handleSaveChanges(supplierToSave)

        setShowModal(false)
    }

    const handleMoreOptions = (folio) => {
        if (folio === showMoreOptions) {
            setShowMoreOptions(null)

            return
        }

        setShowMoreOptions(folio)
    }

    const handleUpdateOrderStatus = (order, status) => {
        if (order.status === "Order completed" || order.status === "Canceled") return

        const dataToSend = {
            status
        }

        Axios.put(`${process.env.REACT_APP_URI_PURCHASING_SERVER}/order/${order._id}/updateStatus`, dataToSend).then(res => {
            const itemsCopy = [...itemsToShow]

            const orderUpdatedIndex = itemsCopy.findIndex(item => item._id === res.data?.order?._id)
            if (orderUpdatedIndex !== -1) {
                itemsCopy[orderUpdatedIndex].status = res.data.order.status
            }

            setItemsToShow(itemsCopy)
        }).catch(err => console.log(err))
    }

    const isProductTab = productTab === PRODUCTS_TABS[0]

    return (
        <div className="warehouse-container">
            <div className="warehouse-header">
                <h5>Warehouse information</h5>
                {ableToDo && <img src={edit} alt="Supplier Prodensa" onClick={handleEditWarehouse} />}
            </div>
            <div className="warehouse-info-section">
                {HEADER_INFO.map(item => {
                    const textToShow = getTextToShow(selectedWarehouse, item.property)

                    return (
                        <div className="warehouse-info" key={item.property}>
                            <strong>{item.label}</strong>
                            <span>{textToShow}</span>
                        </div>
                    )
                })}
            </div>

            <div className="table-section">
                <div className="tabs-container">
                    <div className="customTabs">
                        {PRODUCTS_TABS.map(tab => {
                            const tabClass = `customTab ${tab === productTab ? "selected" : ""}`

                            return (
                                <div className={tabClass} onClick={() => handleProductTab(tab)} key={tab}>
                                    <span>{tab}</span>
                                </div>
                            )
                        })}
                    </div>

                    {isProductTab && ableToDo &&
                        <button id="add-product-button" className="btnBgBlueTextGradient add" onClick={handleAddProduct}>Add product</button>
                    }
                </div>

                <ProdAsignTable
                    supplier={supplier}
                    tab={productTab}
                    supplierProducts={supplierProducts}
                    items={itemsToShow}
                    selectedWarehouse={selectedWarehouse}
                    setSelectedWarehouse={setSelectedWarehouse}
                    showMoreOptions={showMoreOptions}
                    handleMoreOptions={handleMoreOptions}
                    handleUpdateOrderStatus={handleUpdateOrderStatus}
                    handleSaveChanges={handleSaveChanges}
                />

                {/* Modal to add/remove products */}
                {ableToDo && <Modal className="document-modal product-modal" centered show={showModal} onHide={handleCloseModal} >
                    <img className="close-modal" src={close} alt="Catenna Platform" onClick={handleCloseModal} />

                    <Modal.Header>
                        <Modal.Title>
                            <h4>Update Products</h4>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="searchContainer">
                            <input
                                type="text"
                                placeholder="Search product by barcode"
                                value={searchProduct}
                                onChange={(e) => handleSearchProduct(e.target.value)}
                            />
                        </div>

                        <div className="products-list" style={{ maxHeight: "150px", overflowY: "auto" }}>
                            {productsList.sort((a, b) => {
                                if (a.inWarehouse === b.inWarehouse) return 0

                                return a.inWarehouse ? -1 : 1
                            })
                                .map((product, index) => {
                                    const productInfo = supplierProducts.find(item => item._id === product._id)

                                    return (
                                        <div key={product?.barcode || index} className="product-container" onClick={() => handleCheckProduct(product.barcode)}>
                                            <input
                                                type="checkbox"
                                                onChange={() => null}
                                                checked={product.inWarehouse}
                                            />
                                            <div>
                                                <span className="product-name">Product name: {productInfo?.name}</span>
                                                <span className="product-barcode">Barcode: {productInfo?.barcode}</span>
                                            </div>
                                        </div>
                                    )
                                })}
                        </div>

                        <div className="warehouse-modal-actions">
                            <button className="btnBgTextRed" onClick={handleCloseModal}>Cancel</button>
                            <button id="action-product" className="btnBgBlueTextGradient" onClick={handleUpdateProducts}>Update products</button>
                        </div>
                    </Modal.Body>
                </Modal>}
            </div>
        </div>
    )
}
