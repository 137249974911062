import Axios from "axios"

export const HEADER_INFO = [
    {
        label: "Warehouse",
        property: "name"
    },
    {
        label: "Location",
        property: "address"
    },
    {
        label: "Hours",
        property: "hours"
    },
    {
        label: "Warehouse supervisor",
        property: "supervisor"
    },
]

export const PRODUCTS_TABS = [
    "Products",
    "Purchase orders",
]

export const TABLE_TAB_HEADER = {
    "Products": [
        {
            title: "Barcode",
            property: "barcode"
        },
        {
            title: "Name",
            property: "name"
        },
        // {
        //     title: "Stock",
        //     property: "stock"
        // },
        {
            title: "Category",
            property: "commodity"
        },
    ],
    "Purchase orders": [
        {
            title: "Folio",
            property: "folio"
        },
        {
            title: "Location",
            property: "locationName"
        },
        {
            title: "Category",
            property: "category"
        },
        {
            title: "Order date",
            property: "orderDate"
        },
        {
            title: "Status",
            property: "status"
        },
    ]
}

export const MODAL_INPUTS = [
    {
        label: "Name",
        placeholder: "Warehouse name",
        property: "name"
    },
    {
        label: "Supervisor",
        placeholder: "Name of supervisor",
        property: "supervisor"
    },
    {
        label: "Hours",
        placeholder: "Available schedule",
        property: "hours"
    },
    {
        label: "City",
        placeholder: "City name",
        property: "city"
    },
    {
        label: "State",
        placeholder: "State name",
        property: "state"
    },
    {
        label: "Address",
        placeholder: "Street name, number, ZIP Code",
        property: "address"
    },
]

export const PO_STATUS = {
    inPreparation: "In preparation",
    sent: "Sent",
    delivered: "Delivered",
}

export const ABLE_TO_DO_ROLS = [
    "Master",
    "Supplier",
    "Super admin"
]

export const getTextToShow = (warehouse, property) => {
    if (property === "address") {
        return `${warehouse?.[property]} ${warehouse?.city}, ${warehouse?.state}`
    }

    return warehouse?.[property]
}

export const filterProducts = (productsList, searchValue) => {
    return productsList.filter(item => item.barcode.toUpperCase().includes(searchValue.toUpperCase()))
}

export const updateProdIdsRemovedFromWarehouse = (actualState, previousState, warehouseId) => {
    const prodOutOfWarehouse = actualState.filter(item => !item.inWarehouse)
    const prodIdsOutOfWarehouse = previousState.map(previous => {
        return prodOutOfWarehouse.some(item => item.barcode === previous.barcode) ? previous._id : null
    }).filter(item => item !== null)

    if (prodIdsOutOfWarehouse.length === 0) return

    const dataToSend = {
        specificProductIds: prodIdsOutOfWarehouse,
        warehouseId: warehouseId
    }

    Axios.put(`${process.env.REACT_APP_URI_PURCHASING_SERVER}/supplier/deactivateProducts`, dataToSend).then(res => {
        console.log(res.data)
    }).catch(err => console.log(err))
}
