import { useEffect, useState } from 'react'
import { Dropdown, Modal } from 'react-bootstrap'
import { STATUS_LABELS, filterClients } from './utils.js'

import close from "../../../assets/images/close.svg"

function LabelsSection({ mobile=false }) {
    const sectionClass = mobile ? 'd-lg-none mobile-header-status' : 'd-none d-lg-flex header-status'
    
    return (
        <section className={sectionClass}>
            <span className='with-dot green-dot'>{STATUS_LABELS.compliant}</span>
            <span> <div className='dot'></div> {STATUS_LABELS.noActions}</span>
            <span className='with-dot red-dot'>{STATUS_LABELS.nonCompliant}</span>
            <span className='with-dot grey-dot'>{STATUS_LABELS.pendingMonth}</span>
            <span className='with-dot yellow-pending-dot'>{STATUS_LABELS.withFile}</span>
            <span className='with-dot yellow-dot'>{STATUS_LABELS.comment}</span>
        </section>
    )
}

export default function DocumentComplianceHeader(props) {
    const { 
        year, 
        yearList,
        handleYearFilter,
        headerTitle, 
        showStatus, 
        clientFilter, 
        selectedClient,
        handleChangeClient,
        clients } = props
        
    const [showClientFilter, setShowClientFilter] = useState(false)
    const [clientSearch, setClientSearch] = useState("")
    const [filteredClients, setFilteredClients] = useState(clients)
    
    useEffect(() => {
        setFilteredClients(clients)
    }, [clients])
    
    useEffect(() => {
        function handleCloseClientFilter(e) {
            const clientFilterCondition = e.target.className === "client-button-filter" || e.target.closest(".mobile-search-client")
            if (clientFilterCondition) return 

            setShowClientFilter(false)
        }
        
        window.addEventListener('click', handleCloseClientFilter)

        return () => {
            window.removeEventListener('click', handleCloseClientFilter)
        }
    }, [])
    
    const handleShowClientFilter = () => {
        setShowClientFilter(!showClientFilter)
    }

    const handleClientSearch = (e) => {
        setClientSearch(e.target.value)
        
        const filteredClients = filterClients(clients, e.target.value)
        
        setFilteredClients(filteredClients)
    }

    const handleClearClientSearch = (e) => {
        e.stopPropagation()
        setClientSearch("")
        setFilteredClients(clients)
    }
    
    const clientFilterClass = `client-filter ${showClientFilter ? 'show' : ''}`

    return (
        <main>
            {showStatus && <LabelsSection mobile /> }

            {clientFilter && 
                <section className={`d-lg-none client-filter client-filter-mobile`}>
                    <button className='client-button-filter' onClick={handleShowClientFilter}>
                        {selectedClient?.clientName}
                    </button>
                </section>
            }
            
            <header className='doc-comp-table-header'>
                <h5 className='title'>{headerTitle}</h5>

                {showStatus && <LabelsSection /> }

                {clientFilter && 
                    <section className={`d-none d-lg-flex ${clientFilterClass}`}>
                        <button className='client-button-filter' onClick={handleShowClientFilter}>
                            {selectedClient?.clientName}
                        </button>

                        <div className='d-none d-lg-block search-client-container'>
                            <input 
                                type="text" 
                                placeholder='Search Client' 
                                value={clientSearch} 
                                onChange={handleClientSearch} 
                                onClick={(e) => e.stopPropagation()}
                            />
                        </div>

                        <div className='d-none d-lg-flex clients-container'>
                            <div className='clients-list'>
                                {filteredClients?.map(client => {
                                    const clientClass = `client with-dot ${client.hasCompliance ? 'red-dot' : 'green-dot'}`
                                    
                                    return (
                                        <span 
                                            key={client.clientID} 
                                            className={clientClass}
                                            onClick={() => handleChangeClient(client)}
                                        >
                                                {client.clientName}
                                        </span>
                                    )
                                })}
                            </div>
                            <button onClick={handleClearClientSearch}>Clear</button>
                        </div>
                    </section>
                }

                <Dropdown className='year-filter'>
                    <Dropdown.Toggle>
                        {year}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {yearList?.map(year => {
                            return (
                                <Dropdown.Item key={year} onClick={() => handleYearFilter(year)}>{year}</Dropdown.Item>
                            )
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </header>

            <Modal className='d-lg-none mobile-search-client' centered show={showClientFilter} onHide={() => setShowClientFilter(false)}>
                <img className="close-modal" src={close} alt="Catenna Platform" onClick={handleShowClientFilter}/>
                
                <Modal.Header>
                    <Modal.Title>{selectedClient?.clientName}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className='search-client-container'>
                        <input type="text" placeholder='Search Client' value={clientSearch} onChange={handleClientSearch}/>
                    </div>

                    <div className='clients-list'>
                        {filteredClients.map(client => {
                            // const clientClass = `client with-dot ${client.compliant ? 'green-dot' : 'red-dot'}`
                            
                            return (
                                // <span key={client.id} className={clientClass}>{client.name}</span>
                                <span key={client.clientID} className="client with-dot">{client.clientName}</span>
                            )
                        })}
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <button onClick={handleClearClientSearch}>Clear</button>
                </Modal.Footer>
            </Modal>
        </main>
    )
}