import { Tooltip, OverlayTrigger } from "react-bootstrap"
import { 
    MONTHS,
    STATUS_LABELS,
    getDocumentStatus,
    getLastUploaded,
    getNoActionsAccess,
    getSelectedDocument
} from "./utils.js"

function squareTooltip(statusLabel, uploadedLabel, lastUploadedDate) {

    return (
        <Tooltip id="toolTipDocComSquare" className="square-tooltip">
            <div className='info-container'>
                <span>{statusLabel}</span>
                <span>{`${uploadedLabel}: ${lastUploadedDate}`}</span>
            </div>
        </Tooltip>
    )
}

export default function DocumentMonthsSection(props) {
    const {
        items,
        handleModalInfo,
        yearFilter,
        currentMonth,
        serverDate,
        modalInfo
    } = props

    const headerMonthClass = (month) => {
        return `${currentMonth === month ? "current" : ""}`
    }

    const selectedSquare = (month, year, modalId) => {
        if (modalInfo?._id === modalId && modalInfo?.monthCreatedAt === month && modalInfo?.yearCreatedAt === year) {
            return "selected"
        }

        return ""
    }
    
    return (
        <section className='months-section'>
            <div className='months-row months-header'>
                {MONTHS.map(month => (
                    <span className={headerMonthClass(month.value)} key={month.value}>{month.label}</span>
                ))}
            </div>
            {items?.allDocs?.map(document => (
                <div key={document._id} className='months-row'>
                    {MONTHS.map(month => {
                        const docSquareClass = getDocumentStatus(items, document, yearFilter, month.value, currentMonth, serverDate.year)

                        const noActionsAccess = getNoActionsAccess(docSquareClass, document, month.value, yearFilter, items, currentMonth, serverDate.year)
                        
                        const classForNoActions = noActionsAccess.noActionAccess &&
                        !noActionsAccess.beforeFirstRequest &&
                        !noActionsAccess.dateAfterToday ? "cursor" : ""

                        const statusLabel = STATUS_LABELS[docSquareClass]
                        const uploadedLabel = document.periodicity === 1 ? "Uploaded" : "Last uploaded"
                        const lastUploadedDate = getLastUploaded(document, items, yearFilter, month.value)

                        const documentClient = getSelectedDocument(document, items, yearFilter, month.value)
                        const squareWithComment = !documentClient.compliant && documentClient.comment !== "" && documentClient.comment !== null ? "with-comment" : ""

                        return (
                            <OverlayTrigger key={`${document._id}, ${month.value}`} placement='top' 
                                overlay={squareTooltip(statusLabel, uploadedLabel, lastUploadedDate)}>
                                <div className={`month-square-section ${headerMonthClass(month.value)} ${selectedSquare(month.value, yearFilter, document._id)}`}>
                                    <div className={`month-square ${docSquareClass} ${classForNoActions} ${squareWithComment}`} 
                                        onClick={(e) => 
                                            handleModalInfo(items, document, 
                                            month.value, yearFilter, e, docSquareClass)}
                                        />
                                </div>
                            </OverlayTrigger>
                        )
                    })}
                </div>
            ))}
        </section>
    )
}