import "../assets/css/pages/login.css"
import world from "../assets/images/world.webp";
import logo from "../assets/images/logoProdensa.png";
import close from "../assets/images/close.svg";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Modal, Row } from "react-bootstrap";

import axios from "axios"
import Swal from "sweetalert2";
import { useCookies } from "react-cookie";

const URI = process.env.REACT_APP_URI_AUTHENTIFICATE
const URI_RESET = process.env.REACT_APP_URI_RESET_PASSWORD

const actualDate = new Date();
const cookieExpiration = actualDate.setDate(actualDate.getDate() + 7)
const maxAge = Math.floor((cookieExpiration - new Date()) / 1000)

function LoginForgetPassword(props) {
	const [emailToReset, setEmailToReset] = useState("")

	const resetPassword = () => {
		Swal.fire({
			footer: 'Loading, please wait',
			showConfirmButton: false,
			allowOutsideClick: false,
			allowEscapeKey: false,
			didOpen: () => {
				Swal.showLoading()
			},
		})

		axios.put(URI_RESET, {
			email: emailToReset,
		}).then(response => {
			if (response.data.founded) {
				Swal.fire({
					icon: 'success',
					text: 'Password successfully reset',
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
				})
			} else {
				Swal.fire({
					icon: 'error',
					text: "Email doesn't exist",
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
				})
			}

			setEmailToReset("")
			props.onHide()
		}).catch(err => {
			console.log("Error al resetear la contraseña: " + err)
			Swal.fire({
				icon: 'error',
				text: 'Error when resetting password',
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			})
		})
	}

	return (
		<Modal
			className="forgottenPassword"
			{...props}
			size="md"
			centered
		>
			<Modal.Body>
				<img src={close} alt="Prodensa Supplier" onClick={() => props.onHide()} />
				<h4 className="title">Reset password</h4>
				<div className="inputContainer">
					<input type="text" placeholder="contact@companyinc.com" value={emailToReset}
						onChange={(e) => setEmailToReset(e.target.value)} />
				</div>
				<div className="actions">
					<button onClick={() => props.onHide()}>Cancel</button>
					<button onClick={() => resetPassword()}>Reset</button>
				</div>
			</Modal.Body>
		</Modal>
	);
}

function Login() {
	const [cookie, setCookie] = useCookies(null)

	const userLogged = cookie.userLogged

	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");

	const [showPassword, setShowPassword] = useState(false);
	const [showTooltip, setShowTooltip] = useState(false);

	const [modalShow, setModalShow] = useState(false)

	const navigate = useNavigate();

	const loginClick = (e) => {
		e.preventDefault();

		Swal.fire({
			footer: 'Loading, please wait',
			showConfirmButton: false,
			allowOutsideClick: false,
			allowEscapeKey: false,
			didOpen: () => {
				Swal.showLoading()
			},
		})

		axios.post(URI, {
			username: username,
			password: password,
		}).then(response => {
			if (response.data.authentification) {
				setCookie("userLogged", {
					authentification: response.data.authentification,
					username: response.data.username,
					rol: response.data.rol,
					dataID: response.data.dataID
				}, { maxAge })
				navigate("/home")
				Swal.fire({
					icon: 'success',
					text: 'Successful authentication',
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
				})
			} else {
				Swal.fire({
					icon: 'error',
					text: 'Username/password incorrect.',
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
				})
			}
		}).catch(err => {
			console.log("Error al verificar los datos: " + err)
			Swal.fire({
				icon: 'error',
				text: 'Client not found.',
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			})
		})
	}

	useEffect(() => {
		// if (userLogged) {
		// 	navigate("/home")
		// }
	}, [])

	return (
		<section className="login">
			<div className="container">
				<Row>
					<Col xs={{ span: 10, offset: 1 }} lg={{ span: 3, offset: 8 }}>
						<img className="world" src={world} alt="Prodensa cattenna Portal" />
						<img className="logo" src={logo} alt="Prodensa cattenna Portal" />
						<form onSubmit={(e) => loginClick(e)}>
							<div className="inputContainer">
								<input onChange={(e) => setUsername(e.target.value)} type="text" placeholder="Username" autoComplete="username" />
							</div>
							<div className="inputContainer passwordContainer">
								<input onChange={(e) => setPassword(e.target.value)} type={`${!showPassword ? "password" : "text"}`} placeholder="Password" autoComplete="current-password" />
								<div className={`passwordIcon ${showPassword ? "show" : ""}`} onClick={() => setShowPassword(!showPassword)} onMouseEnter={() => setShowTooltip(!showTooltip)} onMouseLeave={() => setShowTooltip(!showTooltip)} />
								<span className={`passwordTooltip ${showTooltip ? "show" : ""}`}>{showPassword ? "Hide password" : "Show password"}</span>
							</div>
							<button className="loginSubmit">
								Login
							</button>
							<button type="button" className="forgotedPass" onClick={() => setModalShow(true)}>Forgot password?</button>
							<a className="contactUs" href="mailto:cattenna@prodensa.com">Contact Us</a>
						</form>
					</Col>
				</Row>
			</div >

			<LoginForgetPassword show={modalShow} onHide={() => setModalShow(false)} />
		</section >
	)
}

export default Login;