import "../../assets/css/clientAlerts/holdPaymentList.css"

import sortIcon from "../../assets/images/sort.svg"
import industry from "../../assets/images/industries.svg"
import location from "../../assets/images/location.svg"
import next from "../../assets/images/arrowForward.svg"
import starRating from "../../assets/images/starRating.svg"

import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import moment from "moment"

export default function HoldPaymentList(props) {
    // VARIABLE TO CONTROL DATA FROM TABLE
    const [dataTable, setDataTable] = useState(props.suppliersHoldPayment)
    const [dataTableCopy, setDataTableCopy] = useState(props.suppliersHoldPayment)

    const [currentPage, setCurrentPage] = useState(1)

    let totalPages = Math.ceil(dataTableCopy.length / 20);
    let indexOfLastItem = +currentPage * 20;
    let indexOfFirstItem = indexOfLastItem - 20;
    let currentItems = dataTableCopy.slice(indexOfFirstItem, indexOfLastItem);

    let paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // VARAIBLE TO CONTROL THE SORT ORDER
    const [order, setOrder] = useState(true)

    // VARIABLE TO MANAGE FILTER AND MOBILE SORT MENU
    const filterRef = useRef(null)

    // VARIABLE TO CONTROL THE LIST OF LOCATIONS
    const [locationList, setLocationList] = useState([])
    const [locationListCopy, setLocationListCopy] = useState([])

    // VARIABLES TO FILTER DATA
    const [industryFilter, setIndustryFilter] = useState([])
    const [locationFilter, setLocationFilter] = useState([])

    // VARIABLES TO KNOW IF THE FILTER IS ON
    const [industryIsFiltered, setIndustryIsFiltered] = useState(false)
    const [locationIsFiltered, setLocationIsFiltered] = useState(false)

    //VARIABLE TO SHOW RESET ALL FILTERS
    const [resetAll, setResetAll] = useState(false)

    // VARIABLE TO MANAGE THE NAVIGATE TO OTHER COMPONENTS
    const navigate = useNavigate()

    // useEffect to add body listener
    useEffect(() => {
        createLocationFilter(dataTable)

        document.body.addEventListener("click", closeMenusFilters)

        return function cleanUp() {
            document.body.removeEventListener("click", closeMenusFilters)
        }
    }, [])

    // COMPARATION TO SHOW THE RESET ALL BUTTON
    useEffect(() => {
        if (industryIsFiltered || locationIsFiltered) {
            setResetAll(true)
        } else {
            setResetAll(false)
        }
    })

    useEffect(() => {
        clearFilter()
        setCurrentPage(1)
        setDataTable(props.suppliersHoldPayment)
        setDataTableCopy(props.suppliersHoldPayment)
    }, [props.suppliersHoldPayment])

    // FUNCTION TO CLOSE MENU FILTERS
    const closeMenusFilters = (e) => {
        let filtersContainer;
        let menuFiltersContainer;
        const searchContainer = document.getElementsByClassName("searchContainer")

        if (filterRef.current && filterRef.current.lastElementChild && filterRef.current.contains(e.target)) {
            filtersContainer = Array.prototype.slice.call(document.getElementsByClassName("holdPaymentFilter"))
            filtersContainer.map(element => {
                if (!filterRef.current.contains(element)) {
                    element.classList.remove("show")
                }
            })
            filterRef.current.classList.toggle("show")

            menuFiltersContainer = Array.prototype.slice.call(document.getElementsByClassName("holdPaymentFilterOptions"))
            menuFiltersContainer.map(element => {
                if (!filterRef.current.lastElementChild.contains(element)) {
                    element.classList.remove("show")
                }
            })
            filterRef.current.lastElementChild.classList.toggle("show")

            if (filterRef.current.classList.contains("location")) {
                searchContainer[1].classList.toggle("show")
            } else {
                searchContainer[1].classList.remove("show")
            }
        } else {
            filtersContainer = Array.prototype.slice.call(document.getElementsByClassName("holdPaymentFilter"))
            filtersContainer.map(element => {
                element.classList.remove("show")
            })

            menuFiltersContainer = Array.prototype.slice.call(document.getElementsByClassName("holdPaymentFilterOptions"))
            menuFiltersContainer.map(element => {
                element.classList.remove("show")
            })

            if (searchContainer[1]) {
                searchContainer[1].classList.remove("show")
            }
        }
    }

    // FUNCTIONS TO ADD FILTERS
    const addIndustry = (target) => {
        if (industryFilter.some(valueToFind => valueToFind === target.value)) {
            industryFilter.splice(industryFilter.findIndex(valueToFind => valueToFind === target.value), 1)
            setIndustryFilter(industryFilter)
        } else {
            industryFilter.push(target.value)
            setIndustryFilter(industryFilter)
        }
    }

    const addLocation = (target) => {
        if (locationFilter.some(valueToFind => valueToFind === target.value)) {
            locationFilter.splice(locationFilter.findIndex(valueToFind => valueToFind === target.value), 1)
            setLocationFilter(locationFilter)
        } else {
            locationFilter.push(target.value)
            setLocationFilter(locationFilter)
        }
    }

    // FUNCTION TO FILTER THE SUPPLIER DIRECTORY BY FILTER BUTTONS
    const filterData = () => {
        let dataFiltered = JSON.parse(JSON.stringify(dataTable));

        if (industryFilter.length > 0) {
            dataFiltered = dataFiltered.filter(prevState => {
                return prevState.industries.some(industry => {
                    return industryFilter.includes(industry)
                })
            })

            setIndustryIsFiltered(true)
        }

        if (locationFilter.length > 0) {
            dataFiltered = dataFiltered.filter(prevState => {
                return locationFilter.includes(prevState.location)
            })

            setLocationIsFiltered(true)
        }

        setDataTableCopy(dataFiltered)
    }

    // FUNCTION TO CLEAR THE SUPPLIER DIRECTORY BY FILTER BUTTONS AND RESET ALL ON DEFAULT CASE
    const clearFilter = (filter) => {
        switch (filter) {
            case "industry":
                const industryChecks = Array.prototype.slice.call(document.getElementsByClassName("industryCheck"))

                setIndustryFilter([])
                industryChecks.forEach(element => {
                    element.checked = false
                });
                setIndustryIsFiltered(false)
                break;

            case "location":
                const locationChecks = Array.prototype.slice.call(document.getElementsByClassName("locationCheck"))

                setLocationFilter([])
                locationChecks.forEach(element => {
                    element.checked = false
                });
                setLocationIsFiltered(false)
                break;

            default:
                clearFilter("industry")
                clearFilter("location")
                setDataTableCopy(dataTable)
                break;
        }
    }

    // FUNCTION TO CREATE THE INDUSTRY LIST TO SHOW ON FILTER BUTTON
    const createIndustryFilter = () => {
        const filterIndustry = new Set(dataTable.flatMap(supplier => {
            return supplier.industries.map(industry => industry)
        }))

        return Array.from(filterIndustry)
    }

    // FUNCTION TO CREATE THE LOCATION LIST TO SHOW ON FILTER BUTTON
    const createLocationFilter = (data) => {
        const filterLocation = new Set(data.map(supplier => {
            return supplier.location
        }))

        const locationList = Array.from(filterLocation).sort((a, b) => {
            if (a.toLocaleLowerCase() > b.toLocaleLowerCase()) {
                return 1
            }

            if (a.toLocaleLowerCase() < b.toLocaleLowerCase()) {
                return -1
            }

            return 0
        })

        setLocationList(locationList)
        setLocationListCopy(locationList)
    }

    // FUNCTION TO FILTER THE SUPPLIER DIRECTORY BY INPUT SEARCH
    const searchLocation = (value) => {

        let dataFiltered = []
        dataFiltered = locationList.filter(prevState => {
            if (prevState.toLocaleLowerCase().includes(value.toLocaleLowerCase())) {
                return prevState
            }

        })

        setLocationListCopy(dataFiltered)
    }

    // FUNCTION TO SORT TABLE COLUMN
    const sortTable = (order, sortBy) => {
        // ORDER FALSE = ASC; ORDER TRUE = DSC 
        switch (sortBy) {
            case "Name":
                setDataTableCopy(dataTableCopy.sort((a, b) => {
                    let fa = a.companyName.toLocaleLowerCase();
                    let fb = b.companyName.toLocaleLowerCase();

                    if (!order) {
                        if (fa < fb) {
                            return -1
                        }

                        if (fa > fb) {
                            return 1
                        }
                    } else {
                        if (fa > fb) {
                            return -1
                        }

                        if (fa < fb) {
                            return 1
                        }
                    }

                    return 0
                }))

                setOrder(!order)
                break

            case "Industry":
                setDataTableCopy(dataTableCopy.sort((a, b) => {
                    let fa = a.industries[0].toLocaleLowerCase();
                    let fb = b.industries[0].toLocaleLowerCase();

                    if (!order) {
                        if (fa < fb) {
                            return -1
                        }

                        if (fa > fb) {
                            return 1
                        }
                    } else {
                        if (fa > fb) {
                            return -1
                        }

                        if (fa < fb) {
                            return 1
                        }
                    }

                    return 0
                }))
                setOrder(!order)
                break

            case "Location":
                setDataTableCopy(dataTableCopy.sort((a, b) => {
                    let fa = a.location.toLocaleLowerCase();
                    let fb = b.location.toLocaleLowerCase();

                    if (!order) {
                        if (fa < fb) {
                            return -1
                        }

                        if (fa > fb) {
                            return 1
                        }
                    } else {
                        if (fa > fb) {
                            return -1
                        }

                        if (fa < fb) {
                            return 1
                        }
                    }

                    return 0
                }))
                setOrder(!order)
                break

            case "Rating":
                setDataTableCopy(dataTableCopy.sort((a, b) => {
                    if (!order) {
                        return a.rating - b.rating
                    } else {
                        return b.rating - a.rating
                    }
                }))
                setOrder(!order)
                break

            case "Date":
                setDataTableCopy(dataTableCopy.sort((a, b) => {
                    let da = new Date(a.holdPaymentDate);
                    let db = new Date(b.holdPaymentDate);
                    if (!order) {
                        return da - db
                    } else {
                        return db - da
                    }
                }))
                setOrder(!order)
                break

            default:
                break;
        }
    }

    return (
        <section className="holdPayment">
            <div className="filters">
                <div className={`holdPaymentFilter ${industryIsFiltered ? "active" : undefined}`} onClick={(e) => filterRef.current = e.target}>
                    <img src={industry} alt="Prodensa Supplier" />
                    <span>Industry</span>
                    <div className="holdPaymentFilterOptions" onClick={(e) => !e.target.matches("button") && e.stopPropagation()}>
                        <span className="title">
                            <img className="d-lg-none imgMenu" src={industry} alt="Prodensa Supplier" />
                            Industry
                        </span>
                        <div className="optionsScroll">
                            {createIndustryFilter().map((industry, index) => {
                                return (
                                    <div key={index} className="option">
                                        <input className="industryCheck" value={industry} type="checkbox" id={`industry${index}`} onClick={(e) => addIndustry(e.target)} />
                                        <label htmlFor={`industry${index}`} >{industry}</label>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="actions">
                            <button onClick={() => clearFilter("industry")}>Clear</button>
                            <button onClick={() => filterData()}>Apply</button>
                        </div>
                    </div>
                </div>
                <div className={`holdPaymentFilter location ${locationIsFiltered ? "active" : undefined}`} onClick={(e) => filterRef.current = e.target}>
                    <img src={location} alt="Prodensa Supplier" />
                    <span>Location</span>
                    <div className="searchContainer" onClick={(e) => e.stopPropagation()}>
                        <input type="text" placeholder="Search cities"
                            onChange={(e) => searchLocation(e.target.value)} />
                    </div>
                    <div className="holdPaymentFilterOptions location" onClick={(e) => !e.target.matches("button") && e.stopPropagation()}>
                        <span className="title">
                            <img className="d-lg-none imgMenu" src={location} alt="Prodensa Supplier" />
                            Location
                        </span>
                        <div className="optionsScroll">
                            {locationListCopy.map((filter, index) => (
                                <div key={index} className="option">
                                    <input className="locationCheck" value={filter} type="checkbox" id={`location${index}`} onClick={(e) => addLocation(e.target)} />
                                    <label htmlFor={`location${index}`} >{filter}</label>
                                </div>
                            ))}
                        </div>
                        <div className="actions">
                            <button onClick={() => clearFilter("location")}>Clear</button>
                            <button onClick={() => filterData()}>Apply</button>
                        </div>
                    </div>
                </div>

                <span className={`resetAll ${resetAll ? "show" : ""}`} onClick={() => clearFilter()}>Reset All</span>
            </div>
            <table className="tableHoldPayment">
                <thead>
                    <tr>
                        <th>
                            <span>Name</span>
                            <img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Name")} />
                        </th>
                        <th>
                            <span>Industry</span>
                            <img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Industry")} />
                        </th>
                        <th>
                            <span>Location</span>
                            <img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Location")} />
                        </th>
                        <th>
                            <span>Rating</span>
                            <img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Rating")} />
                        </th>
                        <th>
                            <span>Date</span>
                            <img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Date")} />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((supplier, index) => (
                        <tr key={index}>
                            <td>
                                <div className="infoContainer">
                                    <span onClick={() => navigate("/home/suppliers/supplierProfile/" + supplier._id)}>
                                        {supplier.companyName}
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div className="infoContainer">
                                    <span>{supplier.industries.map((industry, index) => {
                                        return supplier.industries.length > index + 1 ? industry + ", " : industry
                                    })}</span>
                                </div>
                            </td>
                            <td>
                                <div className="infoContainer">
                                    <span>{supplier.location}</span>
                                </div>
                            </td>
                            <td>
                                <div className="infoContainer">
                                    <span>{supplier.rating?.$numberDecimal ?? "Unrated"}</span>
                                    {supplier.rating?.$numberDecimal && <img className="starRating" src={starRating} alt="Prodensa Supplier" />}
                                </div>
                            </td>
                            <td>
                                <div className="infoContainer">
                                    <span>
                                        {moment(supplier.holdPaymentDate).format("DD MMMM YYYY")}
                                    </span>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="d-lg-none holdPaymentList">
                {currentItems.map((supplier, index) => (
                    <div key={index} className="holdPaymentRow" onClick={() => navigate("/home/suppliers/supplierProfile/" + supplier._id)}>
                        <h5 className="holdPaymentName">{supplier.companyName}</h5>
                        <div className="holdPaymentInformation">
                            <span className="holdPaymentValue">{supplier.industries.map((industry, index) => {
                                return supplier.industries.length > index + 1 ? industry + ", " : industry
                            })}</span>
                            <span className="holdPaymentTitle">Industry</span>
                            <span className="holdPaymentValue">
                                {supplier.location}
                            </span>
                            <span className="holdPaymentTitle">Location</span>
                            <span className="holdPaymentValue">
                                {supplier.rating?.$numberDecimal ?? "Unrated"}
                                {supplier.rating?.$numberDecimal && <img className="starRating" src={starRating} alt="Prodensa Supplier" />}
                            </span>
                            <span className="holdPaymentTitle">Rating</span>
                            <span className="holdPaymentValue">{moment(supplier.holdPaymentDate).format("DD MMMM YYYY")}</span>
                            <span className="holdPaymentTitle">Date</span>
                        </div>
                    </div>
                ))}
            </div>

            <div className="addAndPagination">
                <div className="pagination">
                    {Array.from({ length: totalPages }).map((_, index) => (
                        <button key={index} className={`number ${+currentPage === index + 1 ? 'active' : ''}`} onClick={() => paginate(index + 1)}>
                            {index + 1}
                        </button>
                    ))}
                    <img className="nextPage" src={next} alt="Prodensa Supplier" onClick={() => +currentPage < totalPages ? paginate(+currentPage + 1) : undefined} />
                </div>
            </div>
            <span className="tableCount">{currentItems.length < 19 ? currentItems.length : 20 * +currentPage} of {dataTable.length}</span>
        </section>
    )
}