import "../../assets/css/dashboard/readyForReview.css"

import sortIcon from "../../assets/images/sort.svg"
import next from "../../assets/images/arrowForward.svg"
import starRating from "../../assets/images/starRating.svg"
import view from "../../assets/images/viewProfile.svg"

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function ReadyForReview(props) {
	const [suppliersReadyForReview, setSuppliersReadyForReview] = useState(props.suppliersReadyForReview)

	const [currentPage, setCurrentPage] = useState(1)

	let totalPages = Math.ceil(suppliersReadyForReview.length / 20);
	let indexOfLastItem = +currentPage * 20;
	let indexOfFirstItem = indexOfLastItem - 20;
	let currentItems = suppliersReadyForReview.slice(indexOfFirstItem, indexOfLastItem);

	let paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	// VARAIBLE TO CONTROL THE SORT ORDER
	const [order, setOrder] = useState(true)

	const navigate = useNavigate()

	// FUNCTION TO SORT TABLE COLUMN
	const sortTable = (order, sortBy) => {
		// ORDER FALSE = ASC; ORDER TRUE = DSC 
		switch (sortBy) {
			case "Name":
				setSuppliersReadyForReview(suppliersReadyForReview.sort((a, b) => {
					let fa = a.companyName.toLocaleLowerCase();
					let fb = b.companyName.toLocaleLowerCase();

					if (!order) {
						if (fa < fb) {
							return -1
						}

						if (fa > fb) {
							return 1
						}
					} else {
						if (fa > fb) {
							return -1
						}

						if (fa < fb) {
							return 1
						}
					}

					return 0
				}))

				setOrder(!order)
				break

			case "Industry":
				setSuppliersReadyForReview(suppliersReadyForReview.sort((a, b) => {
					let fa = a.industries[0].toLocaleLowerCase();
					let fb = b.industries[0].toLocaleLowerCase();

					if (!order) {
						if (fa < fb) {
							return -1
						}

						if (fa > fb) {
							return 1
						}
					} else {
						if (fa > fb) {
							return -1
						}

						if (fa < fb) {
							return 1
						}
					}

					return 0
				}))
				setOrder(!order)
				break

			case "Rating":
				setSuppliersReadyForReview(suppliersReadyForReview.sort((a, b) => {
					if (!order) {
						return a.rating - b.rating
					} else {
						return b.rating - a.rating
					}
				}))
				setOrder(!order)
				break

			case "Percent":
				setSuppliersReadyForReview(suppliersReadyForReview.sort((a, b) => {
					let fa = a.documentPercent;
					let fb = b.documentPercent;

					if (!order) {
						if (fa < fb) {
							return -1
						}

						if (fa > fb) {
							return 1
						}
					} else {
						if (fa > fb) {
							return -1
						}

						if (fa < fb) {
							return 1
						}
					}

					return 0
				}))

				setOrder(!order)
				break

			default:
				break;
		}
	}

	return (
		<section className="readyForReview">
			<table className="readyForReviewTable">
				<thead>
					<tr>
						<th>
							<span>Name</span>
							<img className="sort" onClick={() => sortTable(order, "Name")} src={sortIcon} alt="Prodensa supplier" />
						</th>
						<th>
							<span>Industry</span>
							<img className="sort" onClick={() => sortTable(order, "Industry")} src={sortIcon} alt="Prodensa supplier" />
						</th>
						<th>
							<span>Rating</span>
							<img className="sort" onClick={() => sortTable(order, "Rating")} src={sortIcon} alt="Prodensa supplier" />
						</th>
						<th>
							<span>Document upload %</span>
							<img className="sort" onClick={() => sortTable(order, "Percent")} src={sortIcon} alt="Prodensa supplier" />
						</th>
					</tr>
				</thead>
				<tbody>
					{currentItems.map((supplier, index) => (
						<tr key={index}>
							<td>
								<div className="infoContainer">
									<span onClick={() => navigate("/home/requests/reviewForAuth/" + supplier.supplierID)}>
										{supplier.companyName}
									</span>
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<span>{supplier.industries.map((industry, index) => {
										return supplier.industries.length > index + 1 ? industry + ", " : industry
									})}</span>
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<span>{supplier.rating?.$numberDecimal ?? "Unrated"}</span>
									{supplier.rating?.$numberDecimal && <img className="starRating" src={starRating} alt="Prodensa Supplier" />}
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<span>{supplier.documentPercent.toFixed(2)}%</span>
									<img className="reviewed" src={view} alt="Prodensa Supplier" onClick={() => navigate("/home/requests/reviewForAuth/" + supplier.supplierID)} />
								</div>
							</td>
						</tr>
					))}
				</tbody>
			</table>

			<div className="d-lg-none readyForReviewList">
				{currentItems.map((supplier, index) => (
					<div key={index} className="readyForReviewRow" onClick={() => navigate("/home/requests/reviewForAuth/" + supplier.supplierID)}>
						<h5 className="readyForReviewName">{supplier.companyName}</h5>
						<div className="readyForReviewInformation">
							<span className="readyForReviewValue">{supplier.industries.map((industry, index) => {
								return supplier.industries.length > index + 1 ? industry + ", " : industry
							})}</span>
							<span className="readyForReviewTitle">Industry</span>
							<span className="readyForReviewValue">
								{supplier.rating?.$numberDecimal ?? "Unrated"}
								{supplier.rating?.$numberDecimal && <img className="starRating" src={starRating} alt="Prodensa Supplier" />}
							</span>
							<span className="readyForReviewTitle">Rating</span>
							<span className="readyForReviewValue">{supplier.documentPercent.toFixed(2)}%</span>
							<span className="readyForReviewTitle">Supplier status</span>
						</div>
					</div>
				))}
			</div>

			<div className="addAndPagination">
				<div className="pagination">
					{Array.from({ length: totalPages }).map((_, index) => (
						<button key={index} className={`number ${+currentPage === index + 1 ? 'active' : ''}`} onClick={() => paginate(index + 1)}>
							{index + 1}
						</button>
					))}
					<img className="nextPage" src={next} alt="Prodensa Supplier" onClick={() => +currentPage < totalPages ? paginate(+currentPage + 1) : undefined} />
				</div>
			</div>
			<span className="tableCount">{currentItems.length < 19 ? currentItems.length : 20 * +currentPage} of {suppliersReadyForReview.length}</span>
		</section>
	)
}