import "../../assets/css/clientsAdminView/registerClient.css"
import countryInformation from "../../assets/jsonData/countryInformation.json"

import goBack from "../../assets/images/arrowForward.svg"
import deleteIcon from "../../assets/images/delete.svg"
import moreDots from "../../assets/images/moreDots.svg"

import AddEntityModal from "./addEntity.jsx"
import EditEntityModal from "./editEntity.jsx"

import React, { useEffect, useRef, useState } from "react"
import axios from "axios"
import { Dropdown } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { checkTheEmail } from "../../utilities/validateEmail"
import Swal from "sweetalert2"

const URI_CLIENT = process.env.REACT_APP_URI_CLIENT
const URI_CLIENT_BY_URL = process.env.REACT_APP_URI_CLIENT_BY_URL
const URI_INDUSTRY = process.env.REACT_APP_URI_INDUSTRY

export default function RegisterClient() {
	// CATEGORIES INFORMATION
	const [industrySupplier, setIndustrySupplier] = useState(null)

	// DATA VARIABLES TO ADD TO THE CLIENT (GENERAL INFORMATION)
	const [name, setName] = useState("")
	const [industries, setIndustries] = useState([])
	const [activeModules, setActiveModules] = useState([])
	const [activeSince, setActiveSince] = useState("")
	const [renovationDate, setRenovationDate] = useState("")
	const [commercialName, setCommercialName] = useState("")
	const [signedContract, setSignedContract] = useState("")
	const [notificationContacts, setNotificationContacts] = useState("")
	const [clientUsername, setClientUsername] = useState("")

	// DATA VARIABLES TO ADD TO THE CLIENT (BILLING INFORMATION)
	const [companyName, setCompanyName] = useState("")
	const [taxRegime, setTaxRegime] = useState("")
	const [country, setCountry] = useState("Mexíco");
	const [typePerson, setTypePerson] = useState("");
	const [rfc, setRfc] = useState("")
	const [billingEmail, setBillingEmail] = useState("")
	const [state, setState] = useState("");
	const [postalCode, setPostalCode] = useState("")
	const [streetAndNumber, setStreetAndNumber] = useState("")
	const [neighborhood, setNeighborhood] = useState("")
	const [municipality, setMunicipality] = useState("")
	const [paymentMethod, setPaymentMethod] = useState("")
	const [paymentWay, setPaymentWay] = useState([])
	const [cdfiUse, setCdfiUse] = useState("")
	const [paymentCondition, setPaymentCondition] = useState("")
	const [currencies, setCurrencies] = useState([])
	const [notes, setNotes] = useState("")

	// DATA VARIABLES TO ADD TO THE CLIENT (CONTACT INFORMATION)
	const [contacts, setContacts] = useState([
		{
			contactName: "",
			contactEmail: "",
			contactPhone: ""
		},
		{
			contactName: "",
			contactEmail: "",
			contactPhone: ""
		}
	])

	// DATA VARIABLES TO ADD TO THE CLIENT (ENTITIES)
	const [entities, setEntities] = useState([{
		entityName: "",
		entityUrlName: "",
		entityRfc: "",
		entityState: "",
		entityPostalCode: "",
		entityStreetAndNumber: "",
		entityNeighborhood: "",
		entityMunicipality: "",
		entityNotificacionContacts: "",
		entityUsername: ""
	}])

	// VARIABLES TO MANAGE THE ADD ENTITY MODAL
	const [addModalShow, setAddModalShow] = useState(false)

	// VARIABLES TO MANAGE THE EDIT ENTITY MODAL
	const [editModalShow, setEditModalShow] = useState(false)
	const [entityToEdit, setEntityToEdit] = useState(null)
	const [entityPosition, setEntityPosition] = useState(null)

	// VARIABLE TO MANAGE THE REF
	const moreDotsRef = useRef(null)

	// VARIABLE TO NAVIGATE
	const navigate = useNavigate();

	useEffect(() => {
		getAllSupplierIndustries()

		document.addEventListener("click", closeMenus)

		return function cleanUp() {
			document.removeEventListener("click", closeMenus)
		}
	}, [])

	// FUNTION TO HANDLE THE MENU CLOSE
	const closeMenus = (e) => {
		let menuOptions;

		if (moreDotsRef.current && moreDotsRef.current.contains(e.target)) {
			menuOptions = Array.prototype.slice.call(document.getElementsByClassName("menuOptions"))
			menuOptions.map(element => {
				if (!moreDotsRef.current.nextElementSibling.contains(element)) {
					element.classList.remove("show")
				}
			})

			moreDotsRef.current.nextElementSibling.classList.toggle("show")
		} else {
			menuOptions = Array.prototype.slice.call(document.getElementsByClassName("menuOptions"))
			menuOptions.map(element => {
				element.classList.remove("show")
			})
		}
	}

	// USE EFECT TO MANAGE COUNTRY STATE
	useEffect(() => {
		setTypePerson("");
		setState("")
		setRfc("")
	}, [country])

	// FUNCTION TO ADD OR REMOVE CATEGORIES TO THE CLIENT
	const addCategory = (target) => {
		const industriesCopy = JSON.parse(JSON.stringify(industries))
		if (industriesCopy.some(valueToFind => valueToFind === target.value.toUpperCase())) {
			industriesCopy.splice(industriesCopy.findIndex(valueToFind => valueToFind === target.value.toUpperCase()), 1)
		} else {
			industriesCopy.push(target.value.toUpperCase())
		}

		setIndustries(industriesCopy)
	}

	// FUNCTION TO ADD OR REMOVE PURCHASED MODULES TO THE CLIENT
	const addPurchasedModules = (target) => {
		const purchasedModulesCopy = JSON.parse(JSON.stringify(activeModules))
		if (purchasedModulesCopy.some(valueToFind => valueToFind === target.value.toUpperCase())) {
			purchasedModulesCopy.splice(purchasedModulesCopy.findIndex(valueToFind => valueToFind === target.value.toUpperCase()), 1)
		} else {
			purchasedModulesCopy.push(target.value.toUpperCase())
		}

		setActiveModules(purchasedModulesCopy)
	}

	// FUNCTION TO ADD OR REMOVE PAYMENT WAY TO THE CLIENT
	const addPaymentWay = (target) => {
		const paymentWayCopy = JSON.parse(JSON.stringify(paymentWay))
		if (paymentWayCopy.some(valueToFind => valueToFind === target.value.toUpperCase())) {
			paymentWayCopy.splice(paymentWayCopy.findIndex(valueToFind => valueToFind === target.value.toUpperCase()), 1)
		} else {
			paymentWayCopy.push(target.value.toUpperCase())
		}

		setPaymentWay(paymentWayCopy)
	}

	// FUNCTION TO ADD OR REMOVE PAYMENT WAY TO THE CLIENT
	const addCurrency = (target) => {
		const currenciesCopy = JSON.parse(JSON.stringify(currencies))
		if (currenciesCopy.some(valueToFind => valueToFind === target.value.toUpperCase())) {
			currenciesCopy.splice(currenciesCopy.findIndex(valueToFind => valueToFind === target.value.toUpperCase()), 1)
		} else {
			currenciesCopy.push(target.value.toUpperCase())
		}

		setCurrencies(currenciesCopy)
	}

	// FUNCTION TO GET ALL THE SUPPLIER INDUSTRIES
	const getAllSupplierIndustries = () => {
		axios.get(URI_INDUSTRY).then(response => {
			setIndustrySupplier(response.data)
		}).catch(err => {
			console.log("Error al obtener industrias del servidor" + err)
		})
	}

	// FUNCTION TO HANDLE THE CONTACT INFORMATION CHANGE
	const handleContactInformationChange = (position, property, value) => {
		const contactsCopy = JSON.parse(JSON.stringify(contacts))

		contactsCopy[position][property] = value

		setContacts(contactsCopy)
	}

	// FUNCTION TO ADD CONTACTS
	const handleAddContact = () => {
		const contactsCopy = JSON.parse(JSON.stringify(contacts))

		contactsCopy.push({
			name: "",
			email: "",
			phone: ""
		})

		setContacts(contactsCopy)
	}

	// FUNCTION TO DELETE CONTACTS
	const handleDeleteContact = (position) => {
		const contactsCopy = JSON.parse(JSON.stringify(contacts))

		contactsCopy.splice(position, 1)

		setContacts(contactsCopy)
	}

	// FUNCTION TO DELETE ENTITIES
	const handleDeleteEntities = (position) => {
		const entitiesCopy = JSON.parse(JSON.stringify(entities))

		entitiesCopy.splice(position, 1)

		setEntities(entitiesCopy)
	}

	// FUNTION TO EDIT THE ENTITY
	const handleEditEntity = (entity) => {
		const entitiesCopy = JSON.parse(JSON.stringify(entities))

		entitiesCopy[entityPosition] = entity

		setEntities(entitiesCopy)
	}

	// FUNTION TO SHOW THE EDIT MODAL
	const handleShowEditModal = (position, entity) => {
		setEditModalShow(true)
		setEntityPosition(position)
		setEntityToEdit(entity)
	}

	// FUNCTION TO HANDLE THE ADD CLIENT
	const handleAddClient = () => {
		if (!clientUsername) {
			Swal.fire({
				icon: 'error',
				text: 'Please fill out the super client username field from the client information to continue',
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			})
		} else if (entities.some(entity => !entity.entityUrlName)) {
			Swal.fire({
				icon: 'error',
				text: 'Please fill out the urlName from the entity to continue',
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			})
		} else {
			Swal.fire({
				footer: 'Loading, please wait',
				showConfirmButton: false,
				allowOutsideClick: false,
				allowEscapeKey: false,
				didOpen: () => {
					Swal.showLoading()
				},
			})

			axios.post(URI_CLIENT, {
				clientName: name,
				clientIndustries: industries,
				clientActiveModules: activeModules,
				clientActiveSince: activeSince,
				clientRenovationDate: renovationDate,
				clientCommercialName: commercialName,
				clientSignedContract: signedContract,
				clientNotificationContacts: notificationContacts,
				clientUsername: clientUsername,

				clientCompanyName: companyName,
				clientTaxRegime: taxRegime,
				clientCountry: country,
				clientTypePerson: typePerson,
				clientRfc: rfc,
				clientBillingEmail: billingEmail,
				clientState: state,
				clientPostalCode: postalCode,
				clientStreetAndNumber: streetAndNumber,
				clientNeighborhood: neighborhood,
				clientMunicipality: municipality,
				clientPaymentMethod: paymentMethod,
				clientPaymentWay: paymentWay,
				clientCdfiUse: cdfiUse,
				clientPaymentCondition: paymentCondition,
				clientCurrencies: currencies,
				clientNotes: notes,

				contacts: contacts,
				entities: entities,
			}).then(async response => {
				Swal.fire({
					icon: 'success',
					text: 'Client and users created successfully',
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
				})

				navigate("/home/clients/")
			}).catch(err => {
				Swal.fire({
					icon: 'error',
					text: 'Failed to save the client',
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
				})
				console.log("Error al enviar la informacion del cliente: " + err)
			})
		}
	}

	// FUNCTION TO CHECK IF THE URL NAME ALREADY EXIST
	const checkEntitiesUrlName = async (urlNameToCheck) => {
		const entitiesCopy = JSON.parse(JSON.stringify(entities))

		const localEntityUrlFound = entitiesCopy.some(entity => entity.entityUrlName === urlNameToCheck)

		const databaseClientFound = await axios.post(URI_CLIENT_BY_URL, { clientUrl: urlNameToCheck })

		return localEntityUrlFound || databaseClientFound.data ? true : false
	}

	return (
		<section className="registerClient">
			<div className="titleAndBack">
				<img className="goBack" src={goBack} onClick={() => navigate(-1)} alt="Cattenna Platform" />
				<h3>Add client</h3>
			</div>
			<h5 className="titleHeader">Client Information</h5>
			<div className="inputSections">
				<div className="inputContainer">
					<span className="label">Name</span>
					<input type="text" placeholder="Client name" value={name}
						onChange={(e) => setName(e.target.value)}
					/>
				</div>
				<div className="inputContainer">
					<span className="label">Industry</span>
					<Dropdown>
						<Dropdown.Toggle className={industries.length > 0 ? "" : "default"}>
							{industries.length > 0 ? industries.map(category => category + ", ") : "Industry"}
						</Dropdown.Toggle>

						<Dropdown.Menu>
							{industrySupplier && industrySupplier.map((industry, index) => {
								if (industry.name !== "Global") {
									return (
										<div key={index} className="optionCategory">
											<input id={`categories${index}`} type="checkbox" defaultChecked={industries.some(category => category === industry.name.toUpperCase()) ? true : false} value={industry.name} onClick={(e) => addCategory(e.target)} />
											<label htmlFor={`categories${index}`}>{industry.name}</label>
										</div>
									)
								}
							})}
						</Dropdown.Menu>
					</Dropdown>
				</div>
				<div className="inputContainer">
					<span className="label">Active Modules</span>
					<Dropdown>
						<Dropdown.Toggle className={activeModules.length > 0 ? "" : "default"}>
							{activeModules.length > 0 ? activeModules.map(module => module + ", ") : "Active Modules"}
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<div className="optionCategory">
								<input id="purchasing" type="checkbox" value="Purchasing" onClick={(e) => addPurchasedModules(e.target)} />
								<label htmlFor="purchasing">Purchasing</label>
							</div>
						</Dropdown.Menu>
					</Dropdown>
				</div>
				<div className="inputContainer calendar">
					<span className="label">Active since</span>
					<input value={activeSince} type="date"
						onChange={(e) => setActiveSince(e.target.value)}
					/>
				</div>
				<div className="inputContainer calendar">
					<span className="label">Renovation Date</span>
					<input value={renovationDate} type="date"
						onChange={(e) => setRenovationDate(e.target.value)}
					/>
				</div>
				<div className="inputContainer">
					<span className="label">Commercial Name</span>
					<input type="text" placeholder="Commercial Name" value={commercialName}
						onChange={(e) => setCommercialName(e.target.value)}
					/>
				</div>
				<div className="inputContainer">
					<span className="label">Signed Contract</span>
					<input type="text" placeholder="Client PandaDoc URL" value={signedContract}
						onChange={(e) => setSignedContract(e.target.value)}
					/>
				</div>
				<div className="inputContainer">
					<span className="label">Notification contacts <bg-grey>(Separate by comma)</bg-grey></span>
					<input value={notificationContacts} type="text" placeholder="example1@example.com, example2@example.com"
						onChange={(e) => setNotificationContacts(e.target.value)} />
				</div>
				<div className="inputContainer">
					<span className="label">Super client username</span>
					<input value={clientUsername} type="text" placeholder="example@example.com"
						onChange={(e) => setClientUsername(e.target.value)}
						onBlur={() => setClientUsername(checkTheEmail(clientUsername))} />
				</div>

				<h5 className="sectionTitle">Billing information</h5>
				<div className="inputContainer">
					<span className="label">Company name</span>
					<input type="text" placeholder="Company name" value={companyName}
						onChange={(e) => setCompanyName(e.target.value)}
					/>
				</div>
				<div className="inputContainer">
					<span className="label">Tax regime</span>
					<Dropdown>
						<Dropdown.Toggle className={taxRegime ? "" : "default"}>
							{taxRegime ? taxRegime : "Select option"}
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<Dropdown.Item onClick={(e) => setTaxRegime(e.target.innerText)}>601 - General de Ley Personas Morales</Dropdown.Item>
							<Dropdown.Item onClick={(e) => setTaxRegime(e.target.innerText)}>603 - Personas Morales con Fines no Lucrativos</Dropdown.Item>
							<Dropdown.Item onClick={(e) => setTaxRegime(e.target.innerText)}>610 - Residentes en el Extranjero sin Establecimientos Permanente en México</Dropdown.Item>
							<Dropdown.Item onClick={(e) => setTaxRegime(e.target.innerText)}>620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos</Dropdown.Item>
							<Dropdown.Item onClick={(e) => setTaxRegime(e.target.innerText)}>622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras</Dropdown.Item>
							<Dropdown.Item onClick={(e) => setTaxRegime(e.target.innerText)}>623 - Opcional para Grupos de Sociedades</Dropdown.Item>
							<Dropdown.Item onClick={(e) => setTaxRegime(e.target.innerText)}>624 - Coordinados</Dropdown.Item>
							<Dropdown.Item onClick={(e) => setTaxRegime(e.target.innerText)}>626 - Régimen Simplificado de Confianza</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</div>
				<div className="inputContainer">
					<span className="label">Country</span>
					<Dropdown>
						<Dropdown.Toggle className={country ? "" : "default"}>
							{country ? country : "Select country"}
						</Dropdown.Toggle>

						<Dropdown.Menu>
							{countryInformation.map((information, index) => (
								<Dropdown.Item key={index} onClick={(e) => setCountry(e.target.innerText)}>{information.country}</Dropdown.Item>
							))}
						</Dropdown.Menu>
					</Dropdown>
				</div>
				<div className="inputContainer">
					<span className="label">
						{countryInformation.find(countries => {
							return countries.country.toUpperCase() === country.toUpperCase()
						}).typePersonLabel}
					</span>
					<Dropdown>
						<Dropdown.Toggle className={typePerson ? "" : "default"}>
							{typePerson ? typePerson : "Select option"}
						</Dropdown.Toggle>

						<Dropdown.Menu>
							{countryInformation.find(countries => countries.country.toUpperCase() === country.toUpperCase()).typePersonValues.map((person, index) => (
								<Dropdown.Item key={index} onClick={(e) => setTypePerson(e.target.innerText)}>{person}</Dropdown.Item>
							))}
						</Dropdown.Menu>
					</Dropdown>
				</div>
				<div className={`inputContainer`}>
					<span className="label">
						{countryInformation.find(countries => {
							return countries.country.toUpperCase() === country.toUpperCase()
						}).rfcLabel}
					</span>
					<input id="inputRfc" value={rfc}
						onChange={(e) => {
							if (country === "Mexíco")
								setRfc(e.target.value)
							else if (country === "United states of america") {
								const value = e.target.value.replace(/[^\d]/g, "")
								setRfc(value)
							}
						}}
						type="text"
						placeholder={countryInformation.find(countries => {
							return countries.country.toUpperCase() === country.toUpperCase()
						}).rfcPlaceholder} />
				</div>
				<div className="inputContainer">
					<span className="label">Billing email</span>
					<input value={billingEmail} onChange={(e) => setBillingEmail(e.target.value)} type="email" placeholder="Email address"
						onBlur={() => setBillingEmail(checkTheEmail(billingEmail))}
					/>
				</div>
				<div className="inputContainer">
					<span className="label">State</span>
					<Dropdown>
						<Dropdown.Toggle className={`${state ? "" : "default"}`}>
							{state ? state : "State"}
						</Dropdown.Toggle>

						<Dropdown.Menu>
							{countryInformation.find(countries => countries.country.toUpperCase() === country.toUpperCase()).states.map((state, index) => (
								<Dropdown.Item key={index} onClick={(e) => setState(e.target.innerText)}>{state.name}</Dropdown.Item>
							))}
						</Dropdown.Menu>
					</Dropdown>
				</div>
				<div className="inputContainer">
					<span className="label">C.P:</span>
					<input type="text" placeholder="Postal code" value={postalCode}
						onChange={(e) => setPostalCode(e.target.value)}
					/>
				</div>
				<div className="inputContainer">
					<span className="label">Street, interior & exterior number</span>
					<input type="text" placeholder="Street, interior & exterior number" value={streetAndNumber}
						onChange={(e) => setStreetAndNumber(e.target.value)}
					/>
				</div>
				<div className="inputContainer">
					<span className="label">Neighborhood</span>
					<input type="text" placeholder="Neighborhood" value={neighborhood}
						onChange={(e) => setNeighborhood(e.target.value)}
					/>
				</div>
				<div className="inputContainer">
					<span className="label">Municipality</span>
					<input type="text" placeholder="Municipality" value={municipality}
						onChange={(e) => setMunicipality(e.target.value)}
					/>
				</div>
				<div className="inputContainer">
					<span className="label">Payment method</span>
					<Dropdown>
						<Dropdown.Toggle className={paymentMethod ? "" : "default"}>
							{paymentMethod ? paymentMethod : "Select option"}
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<Dropdown.Item onClick={(e) => setPaymentMethod(e.target.innerText)}>Pago PUE</Dropdown.Item>
							<Dropdown.Item onClick={(e) => setPaymentMethod(e.target.innerText)}>Pago PPD</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</div>
				<div className="inputContainer">
					<span className="label">Payment way</span>
					<Dropdown>
						<Dropdown.Toggle className={paymentWay.length > 0 ? "" : "default"}>
							{paymentWay.length > 0 ? paymentWay.map(payment => payment + ", ") : "Payment way"}
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<div className="optionCategory">
								<input id="cash" type="checkbox" value="Cash" onClick={(e) => addPaymentWay(e.target)} />
								<label htmlFor="cash">Cash</label>
							</div>
							<div className="optionCategory">
								<input id="wireTransfer" type="checkbox" value="Wire Transfer" onClick={(e) => addPaymentWay(e.target)} />
								<label htmlFor="wireTransfer">Wire Transfer</label>
							</div>
						</Dropdown.Menu>
					</Dropdown>
				</div>
				<div className="inputContainer">
					<span className="label">Use of CDFI</span>
					<Dropdown>
						<Dropdown.Toggle className={cdfiUse ? "" : "default"}>
							{cdfiUse ? cdfiUse : "Select option"}
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<Dropdown.Item onClick={(e) => setCdfiUse(e.target.innerText)}>Adquisición de mercancia</Dropdown.Item>
							<Dropdown.Item onClick={(e) => setCdfiUse(e.target.innerText)}>Gastos en general</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</div>
				<div className="inputContainer">
					<span className="label">Payment condition</span>
					<input type="text" placeholder="Payment condition" value={paymentCondition}
						onChange={(e) => setPaymentCondition(e.target.value)}
					/>
				</div>
				<div className="inputContainer">
					<span className="label">Currency</span>
					<Dropdown>
						<Dropdown.Toggle className={currencies.length > 0 ? "" : "default"}>
							{currencies.length > 0 ? currencies.map(currency => currency + ", ") : "Currency"}
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<div className="optionCategory">
								<input id="dollar" type="checkbox" value="Dollar" onClick={(e) => addCurrency(e.target)} />
								<label htmlFor="dollar">Dollar</label>
							</div>
							<div className="optionCategory">
								<input id="pesos" type="checkbox" value="Pesos" onClick={(e) => addCurrency(e.target)} />
								<label htmlFor="pesos">Pesos</label>
							</div>
						</Dropdown.Menu>
					</Dropdown>
				</div>
				<div className="inputContainer fullWidth">
					<span className="label">Notes</span>
					<textarea value={notes} rows="5"
						onChange={(e) => setNotes(e.target.value)} />
				</div>

				<h5 className="sectionTitle">Contact information</h5>
				<div className="inputContainer d-lg-block d-none">
					<span className="label">Contact Name</span>
				</div>
				<div className="inputContainer d-lg-block d-none">
					<span className="label">Email</span>
				</div>
				<div className="inputContainer d-lg-block d-none">
					<span className="label">Phone</span>
				</div>
				{contacts.map((contact, indexContact) => (
					<div key={indexContact} className="contactInformationRow">
						<div className="inputContainer">
							<span className="label d-lg-none">Contact Name</span>
							<input type="text" placeholder="Contact Name" value={contact.contactName}
								onChange={(e) => handleContactInformationChange(indexContact, "contactName", e.target.value)}
							/>
						</div>
						<div className="inputContainer">
							<span className="label d-lg-none">Email</span>
							<input type="text" placeholder="Contact Email" value={contact.contactEmail}
								onChange={(e) => handleContactInformationChange(indexContact, "contactEmail", e.target.value)}
							/>
						</div>
						<div className="inputContainer">
							<span className="label d-lg-none">Phone</span>
							<input type="text" placeholder="Contact Phone" value={contact.contactPhone}
								onChange={(e) => handleContactInformationChange(indexContact, "contactPhone", e.target.value)}
							/>
						</div>
						<img className="deleteIcon" src={deleteIcon} onClick={() => handleDeleteContact(indexContact)} alt="Prodensa Supplier" />
					</div>
				))}
				<button className="btnBgBlueTextGradient addContact" onClick={() => handleAddContact()}>Add Contact</button>
			</div>

			<h5 className="titleHeader">Multi-Entity</h5>
			<table className="entityTable">
				<thead>
					<tr>
						<th></th>
						<th>
							<span>Entity</span>
						</th>
						<th>
							<span>Location</span>
						</th>
					</tr>
				</thead>
				<tbody>
					{entities.map((entity, index) => (
						<tr key={index}>
							<td>
								<div className="infoContainer">
									<span>{index + 1 < 10 ? "0" + (index + 1) : index + 1}</span>
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<span>{!entity.entityName ? "DEFAULT ENTITY" : entity.entityName}</span>
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<span>
										{!entity.entityMunicipality ? "DEFAULT MUNICIPALITY" : entity.entityMunicipality}, {!entity.entityState ? "DEFAULT STATE" : entity.entityState}, {country}
									</span>
									<img ref={moreDotsRef} className="moreDots" src={moreDots} alt="Cattenna Platform" onClick={(e) => moreDotsRef.current = e.target} />
									<div className="menuOptions">
										<span onClick={() => handleShowEditModal(index, entity)}>Edit</span>
										{entities.length > 1 && <span onClick={() => handleDeleteEntities(index)}>Delete</span>}
									</div>
								</div>
							</td>
						</tr>
					))}
				</tbody>
			</table>
			<div className="entityList d-lg-none">
				{entities.map((entity, index) => (
					<div key={index} className="entityRow">
						<h5 className="entityName">{!entity.entityName ? "DEFAULT ENTITY" : entity.entityName}</h5>
						<div className="entityInformation">
							<span className="entityValue">
								{!entity.entityMunicipality ? "DEFAULT MUNICIPALITY" : entity.entityMunicipality}, {!entity.entityState ? "DEFAULT STATE" : entity.entityState}, {country}
							</span>
							<span className="entityTitle">Location</span>
							<img ref={moreDotsRef} className="moreDots" src={moreDots} alt="Cattenna Platform" onClick={(e) => moreDotsRef.current = e.target} />
							<div className="menuOptions">
								<span onClick={() => handleShowEditModal(index, entity)}>Edit</span>
								{entities.length > 1 && <span onClick={() => handleDeleteEntities(index)}>Delete</span>}
							</div>
						</div>
					</div>
				))}
			</div>
			<button className="btnBgBlueTextGradient addEntity" onClick={() => setAddModalShow(true)}>Add Entity</button>

			<button className="btnBgBlueTextGradient addClient" onClick={() => handleAddClient()}>Add Client</button>

			<AddEntityModal
				show={addModalShow}
				onHide={() => setAddModalShow(false)}
				setEntities={setEntities}
				country={country}
				companyName={companyName}
				rfc={rfc}
				state={state}
				postalCode={postalCode}
				streetAndNumber={streetAndNumber}
				neighborhood={neighborhood}
				municipality={municipality}
				checkEntitiesUrlName={checkEntitiesUrlName} />

			{entityToEdit && <EditEntityModal
				show={editModalShow}
				onHide={() => setEditModalShow(false)}
				entityToEdit={entityToEdit}
				handleEntityChange={handleEditEntity}
				country={country}
				companyName={companyName}
				rfc={rfc}
				state={state}
				postalCode={postalCode}
				streetAndNumber={streetAndNumber}
				neighborhood={neighborhood}
				municipality={municipality}
				checkEntitiesUrlName={checkEntitiesUrlName} />}
		</section>
	)
}