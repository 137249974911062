import Axios from "axios"
import Swal from "sweetalert2"
import validateDocuments from "../../../assets/jsonData/legalDocumentsID.json"

const INDUSTRIES_URL = process.env.REACT_APP_URI_INDUSTRY
const CLIENT_URL = process.env.REACT_APP_URI_CLIENT
const SUPPLIER_DOCUMENT_REFERENCE = process.env.REACT_APP_URI_SUPPLIER_DOCUMENT_REFERENCE

const SITUACION_FISCAL_COMPLIANCE_URL = process.env.REACT_APP_URI_VALIDATE_SITUACION_FISCAL
const OPINION_CUMPLIMIENTO_URL = process.env.REACT_APP_URI_VALIDATE_OPINION_CUMPLIMIENTO
const REPSE_URL = process.env.REACT_APP_URI_VALIDATE_REPSE
const OPINION_IMSS_URL = process.env.REACT_APP_URI_VALIDATE_OPINION_IMSS
const OPINION_INFONAVIT_URL = process.env.REACT_APP_URI_VALIDATE_OPINION_INFONAVIT


//#region CONSTANTS
export const MONTH_STATUS = {
    compliant: 'compliant',
    nonCompliant: 'nonCompliant',
    noActions: 'noActions',
    pendingMonth: 'pendingMonth',
    withFile: "withFile"
}

export const STATUS_LABELS = {
    compliant: 'Compliant',
    nonCompliant: 'Non-compliant',
    noActions: 'No actions needed',
    pendingMonth: 'Pending month',
    comment: 'Comment',
    withFile: "Pending review"
}

export const MONTHS = [
    {
        label: "Jan",
        value: 1,
        fullLabel: "January"
    },
    {
        label: "Feb",
        value: 2,
        fullLabel: "February"
    },
    {
        label: "Mar",
        value: 3,
        fullLabel: "March"
    },
    {
        label: "Apr",
        value: 4,
        fullLabel: "April"
    },
    {
        label: "May",
        value: 5,
        fullLabel: "May"
    },
    {
        label: "Jun",
        value: 6,
        fullLabel: "June"
    },
    {
        label: "Jul",
        value: 7,
        fullLabel: "July"
    },
    {
        label: "Aug",
        value: 8,
        fullLabel: "August"
    },
    {
        label: "Sep",
        value: 9,
        fullLabel: "September"
    },
    {
        label: "Oct",
        value: 10,
        fullLabel: "October"
    },
    {
        label: "Nov",
        value: 11,
        fullLabel: "November"
    },
    {
        label: "Dec",
        value: 12,
        fullLabel: "December"
    },
]

// what roles can upload a file
export const ROLES_CAN_UPLOAD_FILE = ["Super admin", "Supplier", "Master"]


// #region DOCUMENTS
const comprobanteDomicilioFiscal = "6564fc4a9081ec1ad85d7423"
const comprobanteDomicilioMoral = "6564fd7f9081ec1ad85d742f"
const caratulaBancariaUsaFisica = "663bb05df13a85d0d6b3a1d7"
const caratulaBancariaUsaMoral = "663bb05df13a85d0d6b3a1d8"

// documents that need validation
export const DOCUMENTS_THAT_NEEDS_VALIDATION = [
    validateDocuments.situacionFiscalFisica,
    validateDocuments.opinionCumplimientoFisica,
    validateDocuments.situacionFiscalMoral,
    validateDocuments.opinionCumplimientoMoral,
    validateDocuments.repseMoral,
    validateDocuments.opinionIMSSMoral,
    validateDocuments.opinionInfonavitMoral,
    validateDocuments.repseFisica,
    validateDocuments.opinionIMSSFisica,
    validateDocuments.opinionInfonavitFisica
]

const DOCUMENTS_NEED_TYPE_OF_PERSON = [
    validateDocuments.situacionFiscalFisica,
    validateDocuments.situacionFiscalMoral
]

const DOCUMENTS_NEED_RFC_SUPPLIER = [
    validateDocuments.situacionFiscalFisica,
    validateDocuments.situacionFiscalMoral,
    validateDocuments.opinionIMSSMoral,
    validateDocuments.opinionIMSSFisica,
    validateDocuments.repseFisica,
    validateDocuments.repseMoral,
    validateDocuments.opinionInfonavitMoral,
    validateDocuments.opinionInfonavitFisica,
    validateDocuments.opinionCumplimientoFisica,
    validateDocuments.opinionCumplimientoMoral,
]

const DOCUMENTS_NEED_COMPANY_NAME = [
    validateDocuments.situacionFiscalFisica,
    validateDocuments.situacionFiscalMoral,
    validateDocuments.repseMoral,
    validateDocuments.repseFisica,
    validateDocuments.opinionInfonavitMoral,
    validateDocuments.opinionInfonavitFisica,
    validateDocuments.opinionIMSSMoral,
    validateDocuments.opinionIMSSFisica,
    validateDocuments.opinionCumplimientoFisica,
    validateDocuments.opinionCumplimientoMoral,
]

export const DOCUMENTS_WITH_INFO_ICON = [
    validateDocuments.opinionCumplimientoFisica,
    validateDocuments.opinionCumplimientoMoral,
    validateDocuments.situacionFiscalFisica,
    validateDocuments.situacionFiscalMoral,
    validateDocuments.opinionIMSSFisica,
    validateDocuments.opinionIMSSMoral,
    validateDocuments.opinionInfonavitFisica,
    validateDocuments.opinionInfonavitMoral,
    validateDocuments.comprobanteDomicilioFisica,
    validateDocuments.comprobanteDomicilioMoral,
    validateDocuments.caratulaBancariaMXNFisica,
    validateDocuments.caratulaBancariaMXNMoral,
    validateDocuments.caratulaBancariaUSAFisica,
    validateDocuments.caratulaBancariaUSDFisica,
    validateDocuments.caratulaBancariaUSDMoral,
    validateDocuments.caratulaBancariaUSAMoral,
]

export const INFO_ICON_MESSAGE = {
    [validateDocuments.opinionCumplimientoFisica]: "From this month onwards",
    [validateDocuments.opinionCumplimientoMoral]: "From this month onwards",
    [validateDocuments.opinionIMSSFisica]: "From this month onwards",
    [validateDocuments.opinionIMSSMoral]: "From this month onwards",
    [validateDocuments.opinionInfonavitFisica]: "From this month onwards",
    [validateDocuments.opinionInfonavitMoral]: "From this month onwards",
    [validateDocuments.situacionFiscalFisica]: "From this month onwards",
    [validateDocuments.situacionFiscalMoral]: "From this month onwards",
    [validateDocuments.comprobanteDomicilioFisica]: "No longer than 3 months old",
    [validateDocuments.comprobanteDomicilioMoral]: "No longer than 3 months old",
    [validateDocuments.caratulaBancariaMXNFisica]: "No longer than 3 months old",
    [validateDocuments.caratulaBancariaMXNMoral]: "No longer than 3 months old",
    [validateDocuments.caratulaBancariaUSAFisica]: "No longer than 3 months old",
    [validateDocuments.caratulaBancariaUSDFisica]: "No longer than 3 months old",
    [validateDocuments.caratulaBancariaUSDMoral]: "No longer than 3 months old",
    [validateDocuments.caratulaBancariaUSAMoral]: "No longer than 3 months old",
}

export const DOC_DUE_DATE_TYPE = {
    manual: "Manual",
    automatic: "Automatic"
}

export const CAN_MODIFY_DOC_FILE = [
    comprobanteDomicilioFiscal,
    comprobanteDomicilioMoral
]

export const NO_ACTIONS_PROPERTY = [
    caratulaBancariaUsaFisica,
    caratulaBancariaUsaMoral,
    validateDocuments.caratulaBancariaUSDFisica,
    validateDocuments.caratulaBancariaUSDMoral,
    validateDocuments.repseFisica,
    validateDocuments.repseMoral
]

// Create row number for the table
export function convertIndexToRowNumber(index) {
    if (index + 1 >= 10) return index + 1

    return `0${index + 1}`
}

// filter clients for input search on client docs table
export function filterClients(clients, search) {
    if (search === "") return clients

    return clients.filter(client => client.clientName.toLowerCase().includes(search.toLowerCase()))
}

// Get all industries
export async function getAllIndustries() {
    try {
        const { data } = await Axios.get(INDUSTRIES_URL)

        return data
    } catch (error) {
        console.log("Error al obtener las industrias")
    }
}

// Get all clients
export async function getAllClients() {
    try {
        const { data } = await Axios.get(CLIENT_URL)

        return data
    } catch (error) {
        console.log("Error al obtener todos los clientes")
    }
}

// get the url for the document validation
function getUrl(docId) {
    switch (docId) {
        case validateDocuments.situacionFiscalFisica:
        case validateDocuments.situacionFiscalMoral:
            return SITUACION_FISCAL_COMPLIANCE_URL
        case validateDocuments.opinionCumplimientoFisica:
        case validateDocuments.opinionCumplimientoMoral:
            return OPINION_CUMPLIMIENTO_URL
        case validateDocuments.repseMoral:
        case validateDocuments.repseFisica:
            return REPSE_URL
        case validateDocuments.opinionIMSSMoral:
        case validateDocuments.opinionIMSSFisica:
            return OPINION_IMSS_URL
        case validateDocuments.opinionInfonavitMoral:
        case validateDocuments.opinionInfonavitFisica:
            return OPINION_INFONAVIT_URL
        default:
            return ""
    }
}

// check if the doc upload needs validation
export async function validateFile(doc, extraData) {
    return new Promise(async (resolve, reject) => {
        if (!DOCUMENTS_THAT_NEEDS_VALIDATION.includes(doc.documentID)) return resolve({ save: true, doc })

        Swal.fire({
            footer: 'Loading, please wait',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading()
            },
        })

        const needTypePerson = DOCUMENTS_NEED_TYPE_OF_PERSON.includes(doc.documentID)

        const needRfcSupplier = DOCUMENTS_NEED_RFC_SUPPLIER.includes(doc.documentID)

        const needCompanyName = DOCUMENTS_NEED_COMPANY_NAME.includes(doc.documentID)

        const formattedDocumentName = `${extraData.docDateAttempt.year}-${extraData.docDateAttempt.month}-${doc.document.name}`

        const formData = new FormData()
        formData.append("rfc", "temporal")
        formData.append("document", doc.document, formattedDocumentName)
        formData.append("docDateAttempt", JSON.stringify(extraData.docDateAttempt))

        if (needTypePerson) formData.append("typePerson", extraData.typePerson)

        if (needRfcSupplier) formData.append("rfcSupplier", extraData.rfcSupplier)

        if (needCompanyName) formData.append("companyName", extraData.companyName)

        const url = getUrl(doc.documentID)

        try {
            const { data } = await Axios.post(url, formData)

            if (data.error) {
                Swal.fire({
                    icon: 'error',
                    text: `${data.message}`,
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true,
                })

                doc.validated = false
                doc.document = ""
                doc.compliant = false


                return resolve({ save: false, doc })
            }

            doc.validated = true
            doc.compliant = true

            Swal.fire({
                icon: 'success',
                text: `Document validated successfully`,
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
            })

            return resolve({ save: true, doc })
        } catch (error) {
            console.log("Error al validar documento: " + error)

            Swal.fire({
                icon: 'error',
                text: `Failed to validate document`,
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
            })

            doc.validated = false
            doc.document = ""
            doc.compliant = false

            return resolve({ save: false, doc })
        }
    })
}

// Get document reference
export async function getDocumentReference(id) {
    try {
        const { data } = await Axios.get(SUPPLIER_DOCUMENT_REFERENCE + id)

        return data
    } catch (error) {
        console.log("Error al obtener las referencias de documentos")
    }
}

// ADD PERIODICITY TO GENERIC DOCUMENTS WHEN DOCS ARE FROM A CLIENT
export function updateClientDocuments(clientDocuments, allClients) {
    const uniquePeriodic = clientDocuments.periodic.reduce((acc, item) => {
        const found = acc.find(doc => doc.documentID === item.documentID)
        if (!found) {
            acc.push(item)
        }
        return acc
    }, [])

    uniquePeriodic.forEach(doc => {
        allClients.forEach(client => {
            client.entities.forEach(entity => {
                entity.entityIndustries.forEach(entIndustry => {
                    entIndustry.industryDocumentReference.forEach(docRef => {
                        if (docRef._id === doc.documentID) {
                            doc.periodicity = docRef.periodicity
                        }
                    })
                })
            })
        })
    })

    const generalDocs = clientDocuments.general.map(gDoc => {
        gDoc.periodicity = 0
        return gDoc
    })

    return [...uniquePeriodic, ...generalDocs].sort((a, b) => {
        if (a.documentName < b.documentName) {
            return -1
        }
        if (a.documentName > b.documentName) {
            return 1
        }
        return 0
    })
}

// Add client name to each document
export function getClientName(clientDocuments, allClients) {
    const result = clientDocuments.map(doc => {
        let clientName = "";
        allClients.forEach(client => {
            const clientFound = client.entities.find(entity => entity._id === doc.clientID)
            if (clientFound) {
                clientName = clientFound.entityName;
            }
        })

        return {
            ...doc,
            clientName: clientName
        }
    })

    return result
}

// Get periodicitiy for each document
export function getClientDocPeriodicity(newClientDocuments, allClients) {
    newClientDocuments.forEach(doc => {
        doc.clientDocuments.general.forEach(gDoc => {
            gDoc.periodicity = 0
        })

        doc.clientDocuments.periodic.forEach(pDoc => {
            allClients.forEach(client => {
                client.entities.forEach(entity => {
                    entity.entityIndustries.forEach(entIndustry => {
                        entIndustry.industryDocumentReference.forEach(docRef => {
                            if (docRef._id === pDoc.documentID) {
                                pDoc.periodicity = docRef.periodicity
                            }
                        })
                    })
                })
            })
        })
    })

    return newClientDocuments
}

// create a unique list of documents
export function createUniqueDocuments(documents) {
    documents.forEach(doc => {
        const uniquePeriodic = doc.clientDocuments.periodic.reduce((acc, item) => {
            const existingDoc = acc.find(doc => doc.documentID === item.documentID);
            if (!existingDoc) {
                acc.push(item);
            }
            return acc;
        }, []);

        doc.clientDocuments.allDocs = [...uniquePeriodic, ...doc.clientDocuments.general];
    });

    return documents;
}

// CHECK IF CLIENTS HAVE AT LEAST ONE DOCUMENT WITHOUT COMPLIANCY
export function checkClientCompliance(newClientDocuments) {
    return newClientDocuments.map(client => {
        const hasCompliance = Object.keys(client.clientDocuments).some(doc => {
            return client.clientDocuments[doc].some(doc => doc.compliant === false)
        })

        return {
            ...client,
            hasCompliance
        }
    })
}

// CREATE UNIQUE LIST OF YEARS FOR YEAR FILTER
export function createUniqueYears(items) {
    const years = [...new Set(items.periodic.map(doc => doc.yearCreatedAt)),
    ...new Set(items.general.map(doc => doc.yearCreatedAt))];

    const uniqueYears = [...new Set(years)];

    uniqueYears.sort((a, b) => b - a);

    return uniqueYears;
}

export function updateGeneralDocs(documents, allIndustries) {
    const uniquePeriodic = documents.periodic.reduce((acc, item) => {
        const found = acc.find(doc => doc.documentID === item.documentID)
        if (!found) {
            acc.push(item)
        }
        return acc
    }, [])

    uniquePeriodic.forEach(doc => {
        allIndustries.forEach(industry => {
            const have = industry.documentReference.find(docRef => docRef._id === doc.documentID)
            if (have) {
                doc.periodicity = have.periodicity
                doc.dueDateType = have.dueDateType
            }
        })
    })

    const generalDocs = documents.general.map(doc => {
        doc.periodicity = 0
        return doc
    })

    generalDocs.forEach(doc => {
        allIndustries.forEach(industry => {
            const have = industry.documentReference.find(docRef => docRef._id === doc.documentID)
            if (have) {
                doc.dueDateType = have.dueDateType
            }
        })
    })

    return [...uniquePeriodic, ...generalDocs].sort((a, b) => {
        if (a.documentName < b.documentName) {
            return -1
        }
        if (a.documentName > b.documentName) {
            return 1
        }
        return 0
    })
}

// GET THE STATUS FOR EACH DOCUMENT (COMPLIANT, NON-COMPLIANT, NO ACTIONS, PENDING MONTH)
export function getDocumentStatus(items, doc, year, month, currentMonth, currentYear) {
    if (doc.periodicity === 0) {
        if (items.historicDocuments.length === 0) {
            if (doc.monthCreatedAt === month && doc.yearCreatedAt === year) {
                const docFounded = items.general.find(gDoc => gDoc._id === doc._id)
                return docFounded.compliant ? MONTH_STATUS.compliant : (docFounded?.document ? MONTH_STATUS.withFile : MONTH_STATUS.nonCompliant)
            }

            return MONTH_STATUS.noActions
        }

        const docsFounded = [...items.general.filter(genDoc => genDoc.documentID === doc.documentID), ...items.historicDocuments.filter(historicDoc => historicDoc.documentID === doc.documentID)]

        const currentDoc = docsFounded.find(gDoc => gDoc.yearCreatedAt === year && gDoc.monthCreatedAt === month)

        if (!currentDoc) return MONTH_STATUS.noActions

        return currentDoc?.compliant ? MONTH_STATUS.compliant : (currentDoc?.document ? MONTH_STATUS.withFile : MONTH_STATUS.nonCompliant)
    }

    const docsFounded = items.periodic.filter(periodicDoc => periodicDoc.documentID === doc.documentID)
    const lowestDate = docsFounded.reduce((acc, curr) => {
        if (acc.yearCreatedAt < curr.yearCreatedAt) return acc
        if (acc.yearCreatedAt === curr.yearCreatedAt && acc.monthCreatedAt < curr.monthCreatedAt) return acc

        return curr
    })

    if (year < lowestDate.yearCreatedAt || (lowestDate.yearCreatedAt === year && month < lowestDate.monthCreatedAt)) return "noActions"

    // first month the doc is requested
    const monthCreated = lowestDate.monthCreatedAt

    // first year the doc is requested
    const yearCreated = lowestDate.yearCreatedAt

    // how many months since the doc was requested need a new request
    const periodicity = doc.periodicity

    // months since the doc was requested
    const monthsFromStart = (year - yearCreated) * 12 + (month - monthCreated)

    // check if the doc needs a new request
    const needNewRequest = monthsFromStart % periodicity === 0

    if (!needNewRequest) return MONTH_STATUS.noActions

    if (needNewRequest && month > currentMonth && year === currentYear) return MONTH_STATUS.pendingMonth

    const currentDoc = docsFounded.find(periodicDoc => periodicDoc.yearCreatedAt === year && periodicDoc.monthCreatedAt === month)

    return currentDoc?.compliant ? MONTH_STATUS.compliant : (currentDoc?.document ? MONTH_STATUS.withFile : MONTH_STATUS.nonCompliant)
}

// GET THE FIRST REQUEST OF A ONCE DOCUMENT
export function getFirstRequest(items, doc) {
    if (items.historicDocuments.length === 0) return doc

    return items.historicDocuments.reduce((prevDoc, currDoc) => {
        if (!prevDoc) return currDoc

        if (currDoc.documentID !== doc.documentID) return prevDoc

        if (currDoc.yearCreatedAt < prevDoc.yearCreatedAt) return currDoc

        if (currDoc.yearCreatedAt === prevDoc.yearCreatedAt
            && currDoc.monthCreatedAt < prevDoc.monthCreatedAt) return currDoc;

        return prevDoc
    }, null);
}

// CHECK IF THE USER CAN ACCESS TO THE NO ACTIONS STATUS
export function getNoActionsAccess(docStatus, doc, month, yearFilter, items, currentMonth, currentYear) {
    const onceDoc = doc.periodicity === 0
    const noActionAccess = docStatus === MONTH_STATUS.noActions && onceDoc

    let beforeFirstRequest = false
    if (onceDoc) {
        const firstRequest = items.historicDocuments.length === 0 ? doc : getFirstRequest(items, doc)

        beforeFirstRequest = month < firstRequest.monthCreatedAt && yearFilter <= firstRequest.yearCreatedAt
    }

    const dateAfterToday = month > currentMonth && yearFilter === currentYear

    return {
        noActionAccess,
        beforeFirstRequest,
        dateAfterToday
    }

}

// GET THE DOCUMENT SELECTED FOR THE RESPECTIVE SQUARE(DATE)
export function getSelectedDocument(documentRef, items, year, month) {
    const generalArray = items.historicDocuments.length === 0 ? items.general : [...items.general, ...items.historicDocuments]
    const arrayToSearch = documentRef?.periodicity === 0 ? generalArray : items.periodic

    let documentClient = arrayToSearch.find(doc => doc.documentID === documentRef.documentID &&
        doc.yearCreatedAt === year && doc.monthCreatedAt === month)

    if (!documentClient) {
        documentClient = {
            documentID: documentRef.documentID,
            documentName: documentRef.documentName,
            dayCreatedAt: 1,
            yearCreatedAt: year,
            monthCreatedAt: month,
            validated: false,
            uploadedAt: null,
            dueDate: "",
            dueDateType: documentRef.dueDateType,
            document: null,
            compliant: null,
            comment: ""
        }
    }

    documentClient.periodicity = documentRef.periodicity
    documentClient.dueDateType = documentRef.dueDateType

    return documentClient
}

//GET THE LAST UPLOADED DATE OF THE DOCUMENT FOR INFO SHOW
export function getLastUploaded(document, items, year, month) {
    const documentRef = getSelectedDocument(document, items, year, month)

    if (document.periodicity === 1) return convertDate(documentRef?.uploadedAt)

    if (document.periodicity === 0) {
        const allGenDocs = [...items.general, ...items.historicDocuments]
        const allGenDocsFiltered = allGenDocs.filter(doc => doc.documentID === document.documentID)

        const lastUploaded = allGenDocsFiltered.reduce((prevDoc, currDoc) => {
            if (!prevDoc) return currDoc

            if (!currDoc.uploadedAt) return prevDoc

            if (new Date(currDoc.uploadedAt) > new Date(prevDoc.uploadedAt)) return currDoc

            return prevDoc
        }, null)

        return convertDate(lastUploaded?.uploadedAt)
    }

    const allPeriodicDocs = items.periodic.filter(doc => doc.documentID === document.documentID)
    const lastUploaded = allPeriodicDocs.reduce((prevDoc, currDoc) => {
        if (!prevDoc) return currDoc

        if (!currDoc.uploadedAt) return prevDoc

        if (!prevDoc.uploadedAt && currDoc.uploadedAt) return currDoc

        if (new Date(currDoc.uploadedAt) > new Date(prevDoc.uploadedAt)) return currDoc

        return prevDoc
    }, null)

    return convertDate(lastUploaded?.uploadedAt)
}

// Capitalize first letter of a string
export function capitalizeFirstLetter(string) {
    if (!string) return string
    return string.charAt(0).toUpperCase() + string.slice(1);
}

// Convert date to a readable format
export function convertDate(date) {
    if (!date) return "N/A"

    const formattedDate = new Date(date).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }).replace(/,/g, '')
    return formattedDate;
}

export function convertDateForInput(date) {
    if (!date) return null

    let [month, day, year] = date.split("/")

    if (parseInt(month) < 10) {
        month = `0${parseInt(month)}`
    }

    if (parseInt(day) < 10) {
        day = `0${parseInt(day)}`
    }

    return `${year}-${month}-${day}`
}

export function debounce(cb, delay = 1000) {
    let timeout

    return (...args) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            cb(...args)
        }, delay)
    }
}