import "../../assets/css/clientAlerts/actionNeededList.css"

import sortIcon from "../../assets/images/sort.svg"
import person from "../../assets/images/person.svg"
import next from "../../assets/images/arrowForward.svg"
import folder from "../../assets/images/folder.svg"

import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import moment from "moment"

export default function ActionNeededList(props) {
    // VARIABLE TO CONTROL DATA FROM TABLE
    const [dataTable, setDataTable] = useState([])
    const [dataTableCopy, setDataTableCopy] = useState([])

    const [currentPage, setCurrentPage] = useState(1)

    let totalPages = Math.ceil(dataTableCopy.length / 20);
    let indexOfLastItem = +currentPage * 20;
    let indexOfFirstItem = indexOfLastItem - 20;
    let currentItems = dataTableCopy.slice(indexOfFirstItem, indexOfLastItem);

    let paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // VARAIBLE TO CONTROL THE SORT ORDER
    const [order, setOrder] = useState(true)

    // VARIABLE TO MANAGE FILTER AND MOBILE SORT MENU
    const filterRef = useRef(null)

    // VARIABLES TO FILTER DATA
    const [supplierFilter, setSupplierFilter] = useState([])

    // VARIABLES TO KNOW IF THE FILTER IS ON
    const [supplierIsFiltered, setSupplierIsFiltered] = useState(false)

    // VARIABLE TO MANAGE THE NAVIGATE TO OTHER COMPONENTS
    const navigate = useNavigate()

    // useEffect to add body listener
    useEffect(() => {
        document.body.addEventListener("click", closeMenusFilters)

        return function cleanUp() {
            document.body.removeEventListener("click", closeMenusFilters)
        }
    }, [])

    useEffect(() => {
        clearFilter()
        setCurrentPage(1)
        setDataTable(props.suppliersActionNeeded)
        setDataTableCopy(props.suppliersActionNeeded)
    }, [props.suppliersActionNeeded])

    // FUNCTION TO CLOSE MENU FILTERS
    const closeMenusFilters = (e) => {
        let filtersContainer;
        let menuFiltersContainer;

        if (filterRef.current && filterRef.current.lastElementChild && filterRef.current.contains(e.target)) {
            filtersContainer = Array.prototype.slice.call(document.getElementsByClassName("actionNeededFilter"))
            filtersContainer.map(element => {
                if (!filterRef.current.contains(element)) {
                    element.classList.remove("show")
                }
            })
            filterRef.current.classList.toggle("show")

            menuFiltersContainer = Array.prototype.slice.call(document.getElementsByClassName("actionNeededFilterOptions"))
            menuFiltersContainer.map(element => {
                if (!filterRef.current.lastElementChild.contains(element)) {
                    element.classList.remove("show")
                }
            })
            filterRef.current.lastElementChild.classList.toggle("show")
        } else {
            filtersContainer = Array.prototype.slice.call(document.getElementsByClassName("actionNeededFilter"))
            filtersContainer.map(element => {
                element.classList.remove("show")
            })

            menuFiltersContainer = Array.prototype.slice.call(document.getElementsByClassName("actionNeededFilterOptions"))
            menuFiltersContainer.map(element => {
                element.classList.remove("show")
            })
        }
    }

    // FUNCTIONS TO ADD FILTERS
    const addSupplier = (target) => {
        if (supplierFilter.some(valueToFind => valueToFind === target.value)) {
            supplierFilter.splice(supplierFilter.findIndex(valueToFind => valueToFind === target.value), 1)
            setSupplierFilter(supplierFilter)
        } else {
            supplierFilter.push(target.value)
            setSupplierFilter(supplierFilter)
        }
    }

    // FUNCTION TO FILTER THE SUPPLIER DIRECTORY BY FILTER BUTTONS
    const filterData = () => {
        let dataFiltered = JSON.parse(JSON.stringify(dataTable));

        if (supplierFilter.length > 0) {
            dataFiltered = dataFiltered.filter(prevState => {
                return supplierFilter.includes(prevState.companyName)
            })

            setSupplierIsFiltered(true)
        }

        setDataTableCopy(dataFiltered)
    }

    // FUNCTION TO CLEAR THE SUPPLIER DIRECTORY BY FILTER BUTTONS AND RESET ALL ON DEFAULT CASE
    const clearFilter = (filter) => {
        switch (filter) {
            case "supplier":
                const supplierChecks = Array.prototype.slice.call(document.getElementsByClassName("supplierCheck"))

                setSupplierFilter([])
                supplierChecks.forEach(element => {
                    element.checked = false
                });
                setSupplierIsFiltered(false)
                break;

            default:
                clearFilter("supplier")
                setDataTableCopy(dataTable)
                break;
        }
    }

    // FUNCTION TO CREATE THE INDUSTRY LIST TO SHOW ON FILTER BUTTON
    const createSupplierFilter = () => {
        const filterSupplier = new Set(dataTable.flatMap(supplier => {
            return supplier.companyName
        }))

        return Array.from(filterSupplier)
    }

    // FUNCTION TO SORT TABLE COLUMN
    const sortTable = (order, sortBy) => {
        // ORDER FALSE = ASC; ORDER TRUE = DSC 
        switch (sortBy) {
            case "Name":
                setDataTableCopy(dataTableCopy.sort((a, b) => {
                    let fa = a.companyName.toLocaleLowerCase();
                    let fb = b.companyName.toLocaleLowerCase();

                    if (!order) {
                        if (fa < fb) {
                            return -1
                        }

                        if (fa > fb) {
                            return 1
                        }
                    } else {
                        if (fa > fb) {
                            return -1
                        }

                        if (fa < fb) {
                            return 1
                        }
                    }

                    return 0
                }))

                setOrder(!order)
                break

            case "Status":
                setDataTableCopy(dataTableCopy.sort((a, b) => {
                    let fa = a.status.toLocaleLowerCase();
                    let fb = b.status.toLocaleLowerCase();

                    if (!order) {
                        if (fa < fb) {
                            return -1
                        }

                        if (fa > fb) {
                            return 1
                        }
                    } else {
                        if (fa > fb) {
                            return -1
                        }

                        if (fa < fb) {
                            return 1
                        }
                    }

                    return 0
                }))

                setOrder(!order)
                break

            case "TypeOfAction":
                setDataTableCopy(dataTableCopy.sort((a, b) => {
                    let fa = a.typeOfAction.toLocaleLowerCase();
                    let fb = b.typeOfAction.toLocaleLowerCase();

                    if (!order) {
                        if (fa < fb) {
                            return -1
                        }

                        if (fa > fb) {
                            return 1
                        }
                    } else {
                        if (fa > fb) {
                            return -1
                        }

                        if (fa < fb) {
                            return 1
                        }
                    }

                    return 0
                }))

                setOrder(!order)
                break

            case "CreatedAt":
                setDataTableCopy(dataTableCopy.sort((a, b) => {
                    let da = new Date(a.createdAt);
                    let db = new Date(b.createdAt);
                    if (!order) {
                        return da - db
                    } else {
                        return db - da
                    }
                }))

                setOrder(!order)
                break
            default:
                break;
        }
    }

    return (
        <section className="actionNeeded">
            <div className="filters">
                <div className={`actionNeededFilter ${supplierIsFiltered ? "active" : undefined}`} onClick={(e) => filterRef.current = e.target}>
                    <img src={person} alt="Prodensa Supplier" />
                    <span>Supplier</span>
                    <div className="actionNeededFilterOptions" onClick={(e) => !e.target.matches("button") && e.stopPropagation()}>
                        <span className="title">
                            <img className="d-lg-none imgMenu" src={person} alt="Prodensa Supplier" />
                            Supplier
                        </span>
                        <div className="optionsScroll">
                            {createSupplierFilter().map((supplier, index) => {
                                return (
                                    <div key={index} className="option">
                                        <input className="supplierCheck" value={supplier} type="checkbox" id={`supplier${index}`} onClick={(e) => addSupplier(e.target)} />
                                        <label htmlFor={`supplier${index}`} >{supplier}</label>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="actions">
                            <button onClick={() => clearFilter("supplier")}>Clear</button>
                            <button onClick={() => filterData()}>Apply</button>
                        </div>
                    </div>
                </div>
            </div>

            <table className="tableActionNeeded">
                <thead>
                    <tr>
                        <th>
                            <span>Name</span>
                            <img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Name")} />
                        </th>
                        {/* <th>
                            <span>Status</span>
                            <img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Status")} />
                        </th> */}
                        <th>
                            <span>Type of action</span>
                            <img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "TypeOfAction")} />
                        </th>
                        <th>
                            <span>Created At</span>
                            <img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "CreatedAt")} />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((supplier, index) => (
                        <tr key={index}>
                            <td>
                                <div className="infoContainer">
                                    <span onClick={() => navigate("/home/suppliers/supplierProfile/" + supplier.supplierID)}>
                                        {supplier.companyName}
                                    </span>
                                </div>
                            </td>
                            {/* <td>
                                <div className="infoContainer">
                                    <span className={`${supplier.status === "Active" ? "success" : ""} ${supplier.status === "Hold payment" ? "failedAlone" : ""} ${supplier.status === "Ready for review" ? "success" : ""} ${supplier.status === "Pre-authorized" || supplier.status === "Review requested" ? "pending" : ""}`}>
                                        {supplier.status}
                                    </span>
                                </div>
                            </td> */}
                            <td>
                                <div className="infoContainer">
                                    <span>{supplier.typeOfAction}</span>
                                </div>
                            </td>
                            <td>
                                <div className="infoContainer">
                                    <span>{moment(supplier.createdAt).format("DD MMMM YYYY")}</span>
                                    <img className="actionIcon" src={folder} alt="Prodensa Supplier" onClick={() => navigate("/home/suppliers/supplierProfile/" + supplier.supplierID)} />
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="d-lg-none actionNeededList">
                {currentItems.map((supplier, index) => (
                    <div key={index} className="actionNeededRow" onClick={() => navigate("/home/suppliers/supplierProfile/" + supplier.supplierID)}>
                        <h5 className="actionNeededName">
                            {supplier.companyName}
                            <img className="actionIcon" src={folder} alt="Prodensa Supplier" onClick={() => navigate("/home/suppliers/supplierProfile/" + supplier.supplierID)} />
                        </h5>
                        <div className="actionNeededInformation">
                            {/* <span className={`actionNeededValue ${supplier.status === "Active" ? "success" : ""} ${supplier.status === "Hold payment" ? "failedAlone" : ""} ${supplier.status === "Ready for review" ? "success" : ""} ${supplier.status === "Pre-authorized" || supplier.status === "Review requested" ? "pending" : ""}`}>{supplier.status}</span>
                            <span className="actionNeededTitle">Status</span> */}
                            <span className="actionNeededValue">{supplier.typeOfAction}</span>
                            <span className="actionNeededTitle">Type Of Action</span>
                            <span className="actionNeededValue">{moment(supplier.createdAt).format("DD MMMM YYYY")}</span>
                            <span className="actionNeededTitle">Created At</span>
                        </div>
                    </div>
                ))}
            </div>

            <div className="addAndPagination">
                <div className="pagination">
                    {Array.from({ length: totalPages }).map((_, index) => (
                        <button key={index} className={`number ${+currentPage === index + 1 ? 'active' : ''}`} onClick={() => paginate(index + 1)}>
                            {index + 1}
                        </button>
                    ))}
                    <img className="nextPage" src={next} alt="Prodensa Supplier" onClick={() => +currentPage < totalPages ? paginate(+currentPage + 1) : undefined} />
                </div>
            </div>
            <span className="tableCount">{currentItems.length < 19 ? currentItems.length : 20 * +currentPage} of {dataTable.length}</span>
        </section>
    )
}