import Axios from "axios"
import { useState, useMemo, useRef, useEffect } from 'react'
import { useCookies } from "react-cookie"
import Swal from 'sweetalert2'
import { Dropdown, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import DocumentComplianceHeader from './documentComplianceHeader.jsx'
import DocumentNamesSection from './documentNamesSection.jsx'
import DocumentMonthsSection from './documentMonthsSection.jsx'
import { addAction } from "../../../utilities/utils.js"
import {
    MONTHS,
    MONTH_STATUS,
    ROLES_CAN_UPLOAD_FILE,
    DOC_DUE_DATE_TYPE,
    CAN_MODIFY_DOC_FILE,
    NO_ACTIONS_PROPERTY,
    DOCUMENTS_WITH_INFO_ICON,
    INFO_ICON_MESSAGE,
    convertDate,
    convertDateForInput,
    validateFile,
    createUniqueYears,
    getFirstRequest,
    getSelectedDocument,
    // debounce
} from './utils.js'

import close from "../../../assets/images/close.svg"
import info from "../../../assets/images/info.svg"

const SUPPLIER_ALERT = process.env.REACT_APP_SUPPLIER_ALERT

export default function DocumentComplianceTable(props) {
    const {
        headerTitle,
        showStatus,
        clientFilter,
        supplierID,
        items,
        serverDate,
        selectedClient,
        handleChangeClient,
        updateDocument,
        typePerson,
        rfc,
        companyName,
        clients = [],
        handleSaveChanges
    } = props

    const [yearFilter, setYearFilter] = useState(serverDate.year)
    const [yearList, setYearList] = useState(null)
    const [showDocumentModal, setShowDocumentModal] = useState(false)
    const [modalInfo, setModalInfo] = useState(null)
    // const [activeDebounce, setActiveDebounce] = useState(false)
    const [activeBlurSave, setActiveBlurSave] = useState("")
    const [mousePos, setMousePos] = useState({ x: null, y: null })

    const [cookie] = useCookies(["userLogged"])
    const userLogged = useMemo(() => cookie.userLogged, [cookie.userLogged]);

    const modalWidth = useMemo(() => 600, []);

    const currentMonth = useMemo(() => {
        const currentDate = new Date();
        return currentDate.getMonth() + 1;
    }, []);

    const selectDoc = useRef(null)

    // CREATE UNIQUE LIST OF YEARS FROM ALL DOCUMENTS
    useEffect(() => {
        if (!items) return

        const uniqueYears = createUniqueYears(items)

        setYearList(uniqueYears)
    }, [items])

    // POSITION MODAL WHEN OPENED
    useEffect(() => {
        const screenWidth = window.innerWidth;

        if (screenWidth <= 991) return

        const modalClass = document.querySelector('.document-modal .modal-content');
        const modalHeight = modalClass ? modalClass.offsetHeight : 0;

        const modalPosition = { x: 0, y: 0 };

        if (modalClass && mousePos.x && mousePos.y) {
            if (mousePos.y < modalHeight + 30) {
                modalPosition.y = mousePos.y - 5;
            } else {
                modalPosition.y = mousePos.y - modalHeight - 70
            }

            const axisXCalc = ((screenWidth - modalWidth) / 2) + (modalWidth / 2);

            if (mousePos.x > axisXCalc) {
                modalPosition.x = mousePos.x - axisXCalc;
            }

            if (mousePos.x + (modalWidth / 2) > screenWidth) {
                modalPosition.x = axisXCalc - (modalWidth - (screenWidth - mousePos.x));
            }

            modalClass.style.left = `${modalPosition.x}px`;
            modalClass.style.top = `${modalPosition.y}px`;
        }
    }, [mousePos]);

    // const debounceLogComment = useCallback(
    //     debounce(newModalInfo => {
    //         const typeOfDoc = newModalInfo?.periodicity !== 0 ? "periodic" : "general"
    //         const clientId = selectedClient?._id

    //         const updatedSupplier = updateDocument(newModalInfo, typeOfDoc, clientId)

    //         handleSaveChanges(updatedSupplier)
    //     }, 1000), [updateDocument, handleSaveChanges, selectedClient]
    // )

    // useEffect(() => {
    //     if (!modalInfo || !activeDebounce) return

    //     debounceLogComment(modalInfo)
    // }, [modalInfo?.comment])

    // OPEN MODAL WHEN CLICK ON A SQUARE
    const handleModalInfo = (items, document, month, year, event, currentStatus) => {
        if (document.periodicity === 0) {
            const firstRequest = getFirstRequest(items, document)

            const beforeFirstRequest = month < firstRequest.monthCreatedAt && year <= firstRequest.yearCreatedAt

            if (beforeFirstRequest) return
        }


        const pendingMonth = currentStatus === MONTH_STATUS.pendingMonth
        const noActions = currentStatus === MONTH_STATUS.noActions && (month > currentMonth || document.periodicity !== 0);


        if (noActions || pendingMonth) return

        const mouseX = event.clientX;
        const mouseY = event.clientY;

        setMousePos({ x: mouseX, y: mouseY });

        const documentClient = getSelectedDocument(document, items, year, month)

        if (document.periodicity === 0) {
            const docInGeneral = items.general.find(doc => doc.documentID === documentClient.documentID)

            const newGeneral = docInGeneral.monthCreatedAt === documentClient.monthCreatedAt && docInGeneral.yearCreatedAt === documentClient.yearCreatedAt

            documentClient.newGeneral = !newGeneral
        }

        setModalInfo(documentClient)
        setActiveBlurSave(documentClient.comment)
        setShowDocumentModal(true)
        // setTimeout(() => setActiveDebounce(true), 1)
    }

    const handleCloseModal = () => {
        setShowDocumentModal(false)
        // setActiveDebounce(false)
        setModalInfo(null)
    }

    const handleYearFilter = (year) => {
        setYearFilter(year)
    }

    const handleFileChange = async (e) => {
        const file = e.target.files[0]
        const typeOfDoc = modalInfo?.periodicity !== 0 ? "periodic" : "general"
        const clientId = selectedClient?._id
        let docUpdated = { ...modalInfo }

        if (!file) return

        if (file.type !== "application/pdf") {
            Swal.fire({
                icon: "error",
                text: "The file is not a PDF.",
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true
            })

            return
        }

        const action = docUpdated.document ? "Document replaced" : "Document uploaded"

        docUpdated.document = file

        const modalDate = {
            day: docUpdated.dayCreatedAt,
            month: docUpdated.monthCreatedAt,
            year: docUpdated.yearCreatedAt
        }

        const extraData = {
            typePerson: typePerson,
            rfcSupplier: rfc,
            companyName: companyName,
            docDateAttempt: modalDate
        }

        try {
            const docValidated = await validateFile(docUpdated, extraData)

            const updatedSupplier = updateDocument(docValidated.doc, typeOfDoc, clientId)

            if (!docValidated.save) {
                setModalInfo(docValidated.doc)
                selectDoc.current.value = null
            }

            if (docValidated.save) {
                setShowDocumentModal(false)
                handleSaveChanges(updatedSupplier)
                addAction(action, supplierID)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleDocCompliant = (value) => {
        const typeOfDoc = modalInfo?.periodicity !== 0 ? "periodic" : "general"
        const clientId = selectedClient?._id
        const docUpdated = { ...modalInfo }
        docUpdated.compliant = value

        const updatedSupplier = updateDocument(docUpdated, typeOfDoc, clientId)

        setModalInfo(docUpdated)

        handleSaveChanges(updatedSupplier)
    }

    const handleDocComment = (e) => {
        setModalInfo(prevState => {
            return {
                ...prevState,
                comment: e.target.value
            }
        })
    }

    const handleSaveComment = async() => {
        if (modalInfo?.comment === activeBlurSave) return

        const typeOfDoc = modalInfo?.periodicity !== 0 ? "periodic" : "general"
        const clientId = selectedClient?._id

        const updatedSupplier = updateDocument(modalInfo, typeOfDoc, clientId)
        
        try {
            const result = await handleSaveChanges(updatedSupplier)
            if (result) {
                setActiveBlurSave(modalInfo?.comment)
                const dataToSend = {
                    supplierAlert: {
                        dataID: userLogged.dataID,
                        rol: userLogged.rol,
                        typeOfAlert: "COMMENTS",
                        alertMessage: "NEW COMMENTS IN DOCUMENT COMPLIANCE",
                        supplierID: supplierID
                    }
                }

                Axios.post(SUPPLIER_ALERT, dataToSend)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleDocDueDate = (e) => {
        const docUpdated = { ...modalInfo }
        const [year, month, _day] = e.target.value.split("-")
        const formattedDate = `${parseInt(month)}/${1}/${year}`

        docUpdated.dueDate = formattedDate
        const typeOfDoc = modalInfo?.periodicity !== 0 ? "periodic" : "general"
        const clientId = selectedClient?._id

        const updatedSupplier = updateDocument(docUpdated, typeOfDoc, clientId)

        setModalInfo(docUpdated)

        handleSaveChanges(updatedSupplier)
    }

    // Click on the file button to open the file dialog
    const handleClickFileButton = () => {
        selectDoc.current.click()
    }

    const handleDownloadFile = (doc) => {
        const url = `${process.env.REACT_APP_URI_UPLOADS}${rfc}/${doc?.document}`
        window.open(url, '_blank')
    }

    const handleDeleteFile = () => {
        const docUpdated = { ...modalInfo }
        docUpdated.document = null
        docUpdated.validated = false
        docUpdated.compliant = false
        docUpdated.uploadedAt = ""

        const typeOfDoc = modalInfo?.periodicity !== 0 ? "periodic" : "general"
        const clientId = selectedClient?._id

        const updatedSupplier = updateDocument(docUpdated, typeOfDoc, clientId)

        setModalInfo(docUpdated)

        handleSaveChanges(updatedSupplier)

        addAction("Document removed", supplierID)
    }

    const handleDocNA = () => {
        const docUpdated = { ...modalInfo }
        docUpdated.noActions = !docUpdated.noActions

        const typeOfDoc = modalInfo?.periodicity !== 0 ? "periodic" : "general"
        const clientId = selectedClient?._id

        const updatedSupplier = updateDocument(docUpdated, typeOfDoc, clientId)

        setModalInfo(docUpdated)

        handleSaveChanges(updatedSupplier)
    }

    const statusDropdownText = (status) => {
        return status ? "Compliant" : "Non-compliant"
    }

    const periodicityText = (periodicity) => {
        if (periodicity === 0) return "Once"

        if (periodicity === 1) return `${periodicity} month`

        return `${periodicity} months`
    }

    const toggleClass = modalInfo?.compliant ? "newAlone" : "failedAlone"
    const modalMonth = MONTHS.find(month => month.value === modalInfo?.monthCreatedAt)?.label

    const supplierUser = useMemo(() => userLogged && userLogged.rol === "Supplier", [userLogged])

    const selectFileText = modalInfo?.document && modalInfo?.document !== "" ? (modalInfo?.document?.name ? modalInfo?.document?.name : modalInfo?.document) : "Select file"
    const fileExist = modalInfo?.document && modalInfo?.document !== ""

    const isComprobanteDomicilio = CAN_MODIFY_DOC_FILE.includes(modalInfo?.documentID.toString())
    const FileButtonNoAccess = (!isComprobanteDomicilio) && (modalInfo?.compliant || !ROLES_CAN_UPLOAD_FILE.includes(userLogged?.rol))

    const deleteButton = !modalInfo?.compliant && ROLES_CAN_UPLOAD_FILE.includes(userLogged?.rol) && fileExist

    const isHistoric = items?.historicDocuments.find(doc => doc._id === modalInfo?._id)

    const manualDueDate = modalInfo?.dueDateType === DOC_DUE_DATE_TYPE.manual

    const compliantSpanClass = modalInfo?.compliant ? "successAlone" : "failedAlone"

    return (
        <main>
            {/* HEADER FOR TABLE */}
            <DocumentComplianceHeader
                year={yearFilter}
                yearList={yearList}
                handleYearFilter={handleYearFilter}
                headerTitle={headerTitle}
                showStatus={showStatus}
                clientFilter={clientFilter}
                selectedClient={selectedClient}
                handleChangeClient={handleChangeClient}
                clients={clients}
            />

            {/* LEFT SIDE OF TABLE WHERE DOCUMENT NAMES ARE */}
            <DocumentNamesSection items={items} />

            {/* RIGHT SIDE OF TABLE WHERE MONTHS ARE */}
            <DocumentMonthsSection
                items={items}
                handleModalInfo={handleModalInfo}
                yearFilter={yearFilter}
                currentMonth={currentMonth}
                serverDate={serverDate}
                modalInfo={modalInfo}
            />


            {/* MODAL FOR A DOCUMENT SELECTED */}
            <Modal className='document-modal' centered show={showDocumentModal} onHide={handleCloseModal}>
                <img className="close-modal" src={close} alt="Catenna Platform" onClick={handleCloseModal} />

                <Modal.Header>
                    <Modal.Title>
                        {DOCUMENTS_WITH_INFO_ICON.includes(modalInfo?.documentID) &&
                            <OverlayTrigger placement='top' overlay={<Tooltip id='toolTipDocComInfo'>{INFO_ICON_MESSAGE[modalInfo?.documentID]}</Tooltip>}>
                                <img className="info-icon" src={info} alt="Prodensa Supplier" />
                            </OverlayTrigger>
                        }
                        {modalInfo?.documentName}
                    </Modal.Title>
                    {NO_ACTIONS_PROPERTY.includes(modalInfo?.documentID.toString()) &&
                        <label className="switch">
                            <span id="label-on" className={`insideLabel ${modalInfo?.noActions ? "" : "hidden"}`}>N/A</span>
                            <span id="label-off" className={`insideLabel ${modalInfo?.noActions ? "hidden" : ""}`}>N/A</span>
                            <input type="checkbox" checked={modalInfo?.noActions} onChange={handleDocNA} />
                            <span className={`slider round ${modalInfo?.noActions ? "isChecked" : ""}`}></span>
                        </label>
                    }
                </Modal.Header>

                <Modal.Body>
                    <section className='document-info-section'>
                        <div id='doc-year' className='info-section'>
                            <strong>{modalInfo?.yearCreatedAt?.toString()}</strong>
                            <span>Year</span>
                        </div>

                        <div id='doc-periodicity' className='info-section'>
                            <strong>{periodicityText(modalInfo?.periodicity)}</strong>
                            <span>Periodicity</span>
                        </div>

                        <div id='doc-month' className='info-section'>
                            <strong>{modalMonth}</strong>
                            <span>Month</span>
                        </div>

                        <div id='doc-last-uploaded' className='info-section'>
                            <strong>{convertDate(modalInfo?.uploadedAt)}</strong>
                            <span>Date uploaded</span>
                        </div>

                        <div id='doc-select-file' className='info-section'>
                            <div>
                                <strong>Document</strong>
                                <span style={{ marginLeft: "5px" }}>Upload (5MB max)</span>
                            </div>
                            <div className='buttons-container'>
                                <input ref={selectDoc} type='file' style={{ display: "none" }} onChange={handleFileChange} />
                                {modalInfo?.document && !modalInfo?.compliant ?
                                    <OverlayTrigger placement='top' overlay={<Tooltip>replace file</Tooltip>}>
                                        <button
                                            id="select-doc" className='btnBgBlueTextGradient'
                                            disabled={FileButtonNoAccess || isHistoric}
                                            onClick={handleClickFileButton}>
                                            {selectFileText}
                                        </button>
                                    </OverlayTrigger>
                                    :
                                    <button
                                        id="select-doc" className='btnBgBlueTextGradient'
                                        disabled={FileButtonNoAccess || isHistoric}
                                        onClick={handleClickFileButton}>
                                        {selectFileText}
                                    </button>
                                }
                                {fileExist && <button id='download-doc' className='btnBgBlueTextGradient' onClick={() => handleDownloadFile(modalInfo)}></button>}
                                {deleteButton && <button id='delete-doc' className='btnBgBlueTextGradient' onClick={handleDeleteFile} disabled={isHistoric}></button>}
                            </div>
                        </div>

                        {/* STATUS FOR USER SUPPLIER */}
                        {supplierUser && <div id='doc-status-sup' className='info-section'>
                            <strong className={compliantSpanClass}>{statusDropdownText(modalInfo?.compliant)}</strong>
                            <span>Compliance</span>
                        </div>}

                        {!supplierUser && <div id='doc-status' className='info-section'>
                            <strong>Compliance</strong>
                            <Dropdown>
                                <Dropdown.Toggle className={toggleClass} disabled={isHistoric}>
                                    {statusDropdownText(modalInfo?.compliant)}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => handleDocCompliant(true)}>Compliant</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleDocCompliant(false)}>Non-compliant</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>}

                        {fileExist && <button id='download-doc-mobile' className='btnBgBlueTextGradient' onClick={() => handleDownloadFile(modalInfo)}></button>}
                        {deleteButton && <button id='delete-doc-mobile' className='btnBgBlueTextGradient' onClick={handleDeleteFile} disabled={isHistoric}></button>}

                        {manualDueDate && <div id="expiration-date" className='info-section inputContainer calendar'>
                            <strong>Expiration Date</strong>
                            <input type="date" onChange={handleDocDueDate} value={convertDateForInput(modalInfo?.dueDate)} />
                        </div>}

                        <div id='doc-comments' className='info-section'>
                            <label htmlFor='comments-area'>Comments</label>
                            <textarea
                                value={modalInfo?.comment}
                                onChange={handleDocComment}
                                onBlur={handleSaveComment}
                                placeholder='Comments'
                                id='comments-area'
                                rows="4"
                                disabled={supplierUser || isHistoric}
                            />
                        </div>
                    </section>
                </Modal.Body>
            </Modal>
        </main>
    )
}
