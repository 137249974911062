import "../../assets/css/clientsAdminView/clientsAdminView.css"

import status from "../../assets/images/status.svg"
import industry from "../../assets/images/industries.svg"
import location from "../../assets/images/location.svg"
import sortIcon from "../../assets/images/sort.svg"
import viewProfile from "../../assets/images/viewProfile.svg"
import next from "../../assets/images/arrowForward.svg"

import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import moment from "moment"

const URI_CLIENT = process.env.REACT_APP_URI_CLIENT

export default function ClientsAdminView() {
	// VARIABLE TO CONTROL DATA FROM TABLE
	const [dataTable, setDataTable] = useState([])
	const [dataTableCopy, setDataTableCopy] = useState([])

	const [currentPage, setCurrentPage] = useState(1)

	let totalPages = Math.ceil(dataTableCopy.length / 8);
	let indexOfLastItem = currentPage * 8;
	let indexOfFirstItem = indexOfLastItem - 8;
	let currentItems = dataTableCopy.slice(indexOfFirstItem, indexOfLastItem);

	let paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	// VARAIBLE TO CONTROL THE SORT ORDER
	const [order, setOrder] = useState(true)

	// VARIABLES TO CONTROL SORT MENU SHOW AND HIDE
	const [showSortMenu, setShowSortMenu] = useState(false)

	// VARIABLE TO MANAGE FILTER AND MOBILE SORT MENU
	const sortMenuRef = useRef(null)
	const filterRef = useRef(null)

	// VARIABLE TO CONTROL THE LIST OF LOCATIONS
	const [locationList, setLocationList] = useState([])
	const [locationListCopy, setLocationListCopy] = useState([])

	// VARIABLES TO FILTER DATA
	const [statusFilter, setStatusFilter] = useState([])
	const [industryFilter, setIndustryFilter] = useState([])
	const [locationFilter, setLocationFilter] = useState([])

	// VARIABLES TO KNOW IF THE FILTER IS ON
	const [statusIsFiltered, setStatusIsFiltered] = useState(false)
	const [industryIsFiltered, setIndustryIsFiltered] = useState(false)
	const [locationIsFiltered, setLocationIsFiltered] = useState(false)

	//VARIABLE TO CONTROL THE FILTER DATA AFTER CLEAR A SINGLE FILTER
	const [hardFilterData, setHardFilterReset] = useState(false)

	//VARIABLE TO SHOW RESET ALL FILTERS
	const [resetAll, setResetAll] = useState(false)

	// VARIABLE TO NAVIGATE
	const navigate = useNavigate();

	// useEffect to add body listener
	useEffect(() => {
		getAllClients()

		document.body.addEventListener("click", closeMenusFilters)

		return function cleanUp() {
			document.body.removeEventListener("click", closeMenusFilters)
		}
	}, [])

	// COMPARATION TO SHOW THE RESET ALL BUTTON
	useEffect(() => {
		if (industryIsFiltered || statusIsFiltered || locationIsFiltered) {
			setResetAll(true)
		} else {
			setResetAll(false)
		}
	})

	// ACTION TO EXECUTE WHEN YOU CLEAR A SINGLE FILTER TO RESET THE TABLE
	useEffect(() => {
		if (hardFilterData) {
			filterData()
			setHardFilterReset(false)
		}
	}, [hardFilterData])

	// FUNCTION TO CLOSE MENU FILTERS
	const closeMenusFilters = (e) => {
		let filtersContainer;
		let menuFiltersContainer;
		const searchContainer = document.getElementsByClassName("searchContainer")

		if (filterRef.current && filterRef.current.lastElementChild && filterRef.current.contains(e.target)) {
			filtersContainer = Array.prototype.slice.call(document.getElementsByClassName("filter"))
			filtersContainer.map(element => {
				if (!filterRef.current.contains(element)) {
					element.classList.remove("show")
				}
			})
			filterRef.current.classList.toggle("show")

			menuFiltersContainer = Array.prototype.slice.call(document.getElementsByClassName("filterOptions"))
			menuFiltersContainer.map(element => {
				if (!filterRef.current.lastElementChild.contains(element)) {
					element.classList.remove("show")
				}
			})
			filterRef.current.lastElementChild.classList.toggle("show")

			if (filterRef.current.classList.contains("location")) {
				searchContainer[1].classList.toggle("show")
			} else {
				searchContainer[1].classList.remove("show")
			}
		} else {
			filtersContainer = Array.prototype.slice.call(document.getElementsByClassName("filter"))
			filtersContainer.map(element => {
				element.classList.remove("show")
			})

			menuFiltersContainer = Array.prototype.slice.call(document.getElementsByClassName("filterOptions"))
			menuFiltersContainer.map(element => {
				element.classList.remove("show")
			})

			if (searchContainer[1]) {
				searchContainer[1].classList.remove("show")
			}
		}

		if (sortMenuRef.current && !sortMenuRef.current.contains(e.target)) {
			setShowSortMenu(false)
		}
	}

	// FUNCTION TO SORT TABLE COLUMN
	const sortTable = (order, sortBy) => {
		// ORDER FALSE = ASC; ORDER TRUE = DSC 
		switch (sortBy) {
			case "Name":
				setDataTableCopy(dataTableCopy.sort((a, b) => {
					let fa = a.generalInformation.clientName.toLocaleLowerCase();
					let fb = b.generalInformation.clientName.toLocaleLowerCase();

					if (!order) {
						if (fa < fb) {
							return -1
						}

						if (fa > fb) {
							return 1
						}
					} else {
						if (fa > fb) {
							return -1
						}

						if (fa < fb) {
							return 1
						}
					}

					return 0
				}))

				setOrder(!order)
				break

			case "Industry":
				setDataTableCopy(dataTableCopy.sort((a, b) => {
					let fa = a.generalInformation.clientIndustries[0].toLocaleLowerCase();
					let fb = b.generalInformation.clientIndustries[0].toLocaleLowerCase();

					if (!order) {
						if (fa < fb) {
							return -1
						}

						if (fa > fb) {
							return 1
						}
					} else {
						if (fa > fb) {
							return -1
						}

						if (fa < fb) {
							return 1
						}
					}

					return 0
				}))
				setOrder(!order)
				break

			case "Location":
				setDataTableCopy(dataTableCopy.sort((a, b) => {
					let fa = a.billingInformation.clientState.toLocaleLowerCase();
					let fb = b.billingInformation.clientState.toLocaleLowerCase();

					if (!order) {
						if (fa < fb) {
							return -1
						}

						if (fa > fb) {
							return 1
						}
					} else {
						if (fa > fb) {
							return -1
						}

						if (fa < fb) {
							return 1
						}
					}

					return 0
				}))
				setOrder(!order)
				break

			case "Status":
				setDataTableCopy(dataTableCopy.sort((a, b) => {
					if (!order) {
						return b.generalInformation.clientActive - a.generalInformation.clientActive
					} else {
						return a.generalInformation.clientActive - b.generalInformation.clientActive
					}
				}))

				setOrder(!order)
				break

			case "Date":
				setDataTableCopy(dataTableCopy.sort((a, b) => {
					let da = new Date(a.createdAt);
					let db = new Date(b.createdAt);
					if (!order) {
						return da - db
					} else {
						return db - da
					}
				}))
				setOrder(!order)
				break

			case "Entities":
				setDataTableCopy(dataTableCopy.sort((a, b) => {
					if (!order) {
						return a.entities.length - b.entities.length
					} else {
						return b.entities.length - a.entities.length
					}
				}))
				setOrder(!order)
				break

			default:
				break;
		}
	}

	// FUNCTIONS TO ADD FILTERS
	const addStatus = (target) => {
		if (statusFilter.some(valueToFind => valueToFind === target.value)) {
			statusFilter.splice(statusFilter.findIndex(valueToFind => valueToFind === target.value), 1)
			setStatusFilter(statusFilter)
		} else {
			statusFilter.push(target.value)
			setStatusFilter(statusFilter)
		}
	}

	const addIndustry = (target) => {
		if (industryFilter.some(valueToFind => valueToFind === target.value)) {
			industryFilter.splice(industryFilter.findIndex(valueToFind => valueToFind === target.value), 1)
			setIndustryFilter(industryFilter)
		} else {
			industryFilter.push(target.value)
			setIndustryFilter(industryFilter)
		}
	}

	const addLocation = (target) => {
		if (locationFilter.some(valueToFind => valueToFind === target.value)) {
			locationFilter.splice(locationFilter.findIndex(valueToFind => valueToFind === target.value), 1)
			setLocationFilter(locationFilter)
		} else {
			locationFilter.push(target.value)
			setLocationFilter(locationFilter)
		}
	}

	// FUNCTION TO GET ALL CLIENTS
	const getAllClients = () => {
		axios.get(URI_CLIENT).then(response => {
			setDataTable(response.data)
			setDataTableCopy(response.data)
			createLocationFilter(response.data)
		}).catch(err => {
			console.log("Error al obtener los clientes")
		})
	}

	// FUNCTION TO FILTER THE CLIENTS BY INPUT SEARCH
	const searchFilter = (value) => {
		setCurrentPage(1)
		let dataFiltered = []
		dataFiltered = dataTable.filter(prevState => {
			if (prevState.generalInformation.clientName.toLocaleLowerCase().includes(value.toLocaleLowerCase())) {
				return prevState
			}
		})

		setDataTableCopy(dataFiltered)
	}

	// FUNCTION TO FILTER THE CLIENTS BY FILTER BUTTONS
	const filterData = () => {
		const generalSearch = document.getElementById("generalSearch")
		generalSearch.value = ""

		let dataFiltered = JSON.parse(JSON.stringify(dataTable));

		if (statusFilter.length > 0) {
			dataFiltered = dataFiltered.filter(prevState => {
				return statusFilter.includes(prevState.generalInformation.clientActive ? "Active" : "Disable")
			})

			setStatusIsFiltered(true)
		}

		if (industryFilter.length > 0) {
			dataFiltered = dataFiltered.filter(prevState => {
				return prevState.generalInformation.clientIndustries.some(industry => {
					return industryFilter.includes(industry)
				})
			})

			setIndustryIsFiltered(true)
		}

		if (locationFilter.length > 0) {
			dataFiltered = dataFiltered.filter(prevState => {
				return locationFilter.includes(prevState.billingInformation.clientState)
			})

			setLocationIsFiltered(true)
		}

		setDataTableCopy(dataFiltered)
	}

	// FUNCTION TO CLEAR THE CLIENTS BY FILTER BUTTONS AND RESET ALL ON DEFAULT CASE
	const clearFilter = (filter) => {
		switch (filter) {
			case "status":
				const statusChecks = Array.prototype.slice.call(document.getElementsByClassName("statusCheck"))

				setStatusFilter([])
				statusChecks.forEach(element => {
					element.checked = false
				});
				setStatusIsFiltered(false)
				setHardFilterReset(true)
				break;

			case "industry":
				const industryChecks = Array.prototype.slice.call(document.getElementsByClassName("industryCheck"))

				setIndustryFilter([])
				industryChecks.forEach(element => {
					element.checked = false
				});
				setIndustryIsFiltered(false)
				setHardFilterReset(true)
				break;

			case "location":
				const locationChecks = Array.prototype.slice.call(document.getElementsByClassName("locationCheck"))

				setLocationFilter([])
				locationChecks.forEach(element => {
					element.checked = false
				});
				setLocationIsFiltered(false)
				setHardFilterReset(true)
				break;

			default:
				clearFilter("status")
				clearFilter("industry")
				clearFilter("location")
				break;
		}
	}

	// FUNCTION TO CREATE THE INDUSTRY LIST TO SHOW ON FILTER BUTTON
	const createIndustryFilter = () => {
		const filterIndustry = new Set(dataTable.flatMap(client => {
			return client.generalInformation.clientIndustries.map(industry => industry)
		}))

		return Array.from(filterIndustry)
	}

	// FUNCTION TO CREATE THE LOCATION LIST TO SHOW ON FILTER BUTTON
	const createLocationFilter = (data) => {
		const filterLocation = new Set(data.map(client => {
			return client.billingInformation.clientState
		}))

		setLocationList(Array.from(filterLocation).sort((a, b) => {
			if (a.toLocaleLowerCase() > b.toLocaleLowerCase()) {
				return 1
			}

			if (a.toLocaleLowerCase() < b.toLocaleLowerCase()) {
				return -1
			}

			return 0
		}))

		setLocationListCopy(Array.from(filterLocation).sort((a, b) => {
			if (a.toLocaleLowerCase() > b.toLocaleLowerCase()) {
				return 1
			}

			if (a.toLocaleLowerCase() < b.toLocaleLowerCase()) {
				return -1
			}

			return 0
		}))
	}

	// FUNCTION TO FILTER THE SUPPLIER DIRECTORY BY INPUT SEARCH
	const searchLocation = (value) => {

		let dataFiltered = []
		dataFiltered = locationList.filter(prevState => {
			if (prevState.toLocaleLowerCase().includes(value.toLocaleLowerCase())) {
				return prevState
			}

		})

		setLocationListCopy(dataFiltered)
	}

	return (
		<section className="clientsAdminView">
			<div className="titleAndSearch">
				<h3 className="title">Clients</h3>
				<div className="searchAndSort">
					<div className="searchContainer">
						<input id="generalSearch" type="text" placeholder="Search by name..."
							onFocus={() => clearFilter()} onChange={(e) => searchFilter(e.target.value)} />
					</div>
					<button ref={sortMenuRef} className="d-lg-none sortMobile" onClick={() => setShowSortMenu(true)}>Sort</button>
					<div className={`d-lg-none sortDataContainer ${showSortMenu ? "show" : ""}`}>
						<h5 className="title">Sort</h5>
						<span onClick={() => { sortTable(false, "Name") }}>Name (ascending)</span>
						<span onClick={() => { sortTable(true, "Name") }}>Name (descending)</span>
						<span onClick={() => { sortTable(false, "Industry") }}>Industry (ascending)</span>
						<span onClick={() => { sortTable(true, "Industry") }}>Industry (descending)</span>
						<span onClick={() => { sortTable(false, "Location") }}>Location (ascending)</span>
						<span onClick={() => { sortTable(true, "Location") }}>Location (descending)</span>
						<span onClick={() => { sortTable(false, "Status") }}>Status (ascending)</span>
						<span onClick={() => { sortTable(true, "Status") }}>Status (descending)</span>
						<span onClick={() => { sortTable(false, "Date") }}>Registration (ascending)</span>
						<span onClick={() => { sortTable(true, "Date") }}>Registration (descending)</span>
						<span onClick={() => { sortTable(false, "Entities") }}>Entities (ascending)</span>
						<span onClick={() => { sortTable(true, "Entities") }}>Entities (descending)</span>
					</div>
				</div>
			</div>
			<div className="filters">
				<div className={`filter ${statusIsFiltered ? "active" : undefined}`} onClick={(e) => filterRef.current = e.target}>
					<img src={status} alt="Prodensa Supplier" />
					<span>Status</span>
					<div className="filterOptions" onClick={(e) => !e.target.matches("button") && e.stopPropagation()}>
						<span className="title">
							<img className="d-lg-none imgMenu" src={status} alt="Prodensa Supplier" />
							Status
						</span>
						<div className="optionsScroll">
							<div className="option">
								<input className="statusCheck" value="Active" type="checkbox" id={`statusActive`} onClick={(e) => addStatus(e.target)} />
								<label htmlFor={`statusActive`}>Active</label>
							</div>
							<div className="option">
								<input className="statusCheck" value="Disable" type="checkbox" id={`statusDisable`} onClick={(e) => addStatus(e.target)} />
								<label htmlFor={`statusDisable`}>Disable</label>
							</div>
						</div>
						<div className="actions">
							<button onClick={() => clearFilter("status")}>Clear</button>
							<button onClick={() => filterData()}>Apply</button>
						</div>
					</div>
				</div>
				<div className={`filter ${industryIsFiltered ? "active" : undefined}`} onClick={(e) => filterRef.current = e.target}>
					<img src={industry} alt="Prodensa Supplier" />
					<span>Industry</span>
					<div className="filterOptions" onClick={(e) => !e.target.matches("button") && e.stopPropagation()}>
						<span className="title">
							<img className="d-lg-none imgMenu" src={industry} alt="Prodensa Supplier" />
							Industry
						</span>
						<div className="optionsScroll">
							{createIndustryFilter().map((industry, index) => {
								return (
									<div key={index} className="option">
										<input className="industryCheck" value={industry} type="checkbox" id={`industry${index}`} onClick={(e) => addIndustry(e.target)} />
										<label htmlFor={`industry${index}`} >{industry}</label>
									</div>
								)
							})}
						</div>
						<div className="actions">
							<button onClick={() => clearFilter("industry")}>Clear</button>
							<button onClick={() => filterData()}>Apply</button>
						</div>
					</div>
				</div>
				<div className={`filter location ${locationIsFiltered ? "active" : undefined}`} onClick={(e) => filterRef.current = e.target}>
					<img src={location} alt="Prodensa Supplier" />
					<span>Location</span>
					<div className="searchContainer" onClick={(e) => e.stopPropagation()}>
						<input type="text" placeholder="Search cities"
							onChange={(e) => searchLocation(e.target.value)} />
					</div>
					<div className="filterOptions location" onClick={(e) => !e.target.matches("button") && e.stopPropagation()}>
						<span className="title">
							<img className="d-lg-none imgMenu" src={location} alt="Prodensa Supplier" />
							Location
						</span>
						<div className="optionsScroll">
							{locationListCopy.map((filter, index) => (
								<div key={index} className="option">
									<input className="locationCheck" value={filter} type="checkbox" id={`location${index}`} onClick={(e) => addLocation(e.target)} />
									<label htmlFor={`location${index}`} >{filter}</label>
								</div>
							))}
						</div>
						<div className="actions">
							<button onClick={() => clearFilter("location")}>Clear</button>
							<button onClick={() => filterData()}>Apply</button>
						</div>
					</div>
				</div>
				<button className="btnBgBlueTextGradient" onClick={() => navigate("./registerClient/")}>New Client</button>
			</div>
			<span className={`resetAll ${resetAll ? "show" : ""}`} onClick={() => clearFilter()}>Reset All</span>

			<table className="tableClientsAdminView">
				<thead>
					<tr>
						<th>
							<span>Name</span>
							<img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Name")} />
						</th>
						<th>
							<span>Industry</span>
							<img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Industry")} />
						</th>
						<th>
							<span>Location</span>
							<img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Location")} />
						</th>
						<th>
							<span>Status</span>
							<img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Status")} />
						</th>
						<th>
							<span>Registration</span>
							<img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Date")} />
						</th>
						<th>
							<span>Entities</span>
							<img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Entities")} />
						</th>
					</tr>
				</thead>
				<tbody>
					{currentItems.map((data, index) => (
						<tr key={index}>
							<td>
								<div className="infoContainer">
									<span onClick={() => navigate("./clientProfile/" + data._id)}>
										{data.generalInformation.clientName}
									</span>
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<span>{data.generalInformation.clientIndustries.map((industry, index) => {
										return data.generalInformation.clientIndustries.length > index + 1 ? industry + ", " : industry
									})}</span>
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<span>{data.billingInformation.clientState}</span>
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<span className={`${data.generalInformation.clientActive ? "success" : "failedAlone"}`}>
										{data.generalInformation.clientActive ? "Active" : "Disabled"}
									</span>
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<span>{moment(data.createdAt).format("DD MMM YYYY")}</span>
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<span>{data.entities.length}</span>
									<img className="viewProfile" src={viewProfile} alt="Prodensa Supplier" onClick={() => navigate("./clientProfile/" + data._id)} />
								</div>
							</td>
						</tr>
					))}
				</tbody>
			</table>

			<div className="d-lg-none clientList">
				{currentItems.map((data, index) => (
					<div key={index} className="clientRow" onClick={() => navigate("./clientProfile/" + data._id)}>
						<h5 className="clientName">{data.generalInformation.clientName}</h5>
						<div className="clientInformation">
							<span className="clientValue">{data.billingInformation.clientState}</span>
							<span className="clientTitle">Location</span>
							<span className={`clientValue ${data.generalInformation.clientActive ? "success" : "failedAlone"}`}>
								{data.generalInformation.clientActive ? "Active" : "Disabled"}
							</span>
							<span className="clientTitle">Status</span>
							<span className="clientValue">{data.generalInformation.clientIndustries.map((industry, index) => {
								return data.generalInformation.clientIndustries.length > index + 1 ? industry + ", " : industry
							})}</span>
							<span className="clientTitle">Industry</span>
							<span className="clientValue">{moment(data.createdAt).format("DD MMM YYYY")}</span>
							<span className="clientTitle">Registration</span>
							<span className="clientValue">{data.entities.length}</span>
							<span className="clientTitle">Entities</span>
						</div>
					</div>
				))}
			</div>

			<div className="addAndPagination">
				<div className="pagination">
					{Array.from({ length: totalPages }).map((_, index) => (
						<button key={index} className={`number ${currentPage === index + 1 ? 'active' : ''}`} onClick={() => paginate(index + 1)}>
							{index + 1}
						</button>
					))}
					<img className="nextPage" src={next} alt="Prodensa Supplier" onClick={() => currentPage < totalPages ? setCurrentPage(currentPage + 1) : ""} />
				</div>
			</div>
			<span className="tableCount">{currentItems.length < 7 ? currentItems.length : 8 * currentPage} of {dataTable.length}</span>

			<button className="btnBgBlueTextGradient d-lg-none" onClick={() => navigate("./registerClient/")}>New Client</button>
		</section>
	)
}