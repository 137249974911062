import { convertIndexToRowNumber } from './utils.js'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export default function DocumentNamesSection({ items }) {
    return (
        <section className='document-name-section'>
            <div className='document-row'>
                <span id='name-span'>Name</span>
            </div>
            {items?.allDocs?.map((document, index) => (
                <div className='document-row' key={document._id}>
                    <span className='row-number'>{convertIndexToRowNumber(index)}</span>
                    <OverlayTrigger placement='top' overlay={<Tooltip id='toolTipDocComName'>{document.documentName}</Tooltip>}>
                        <span className='document-name'>{document.documentName}</span>
                    </OverlayTrigger>
                </div>
            ))}
        </section>
    );
}