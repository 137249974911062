import "../../assets/css/dashboard/dashboard.css"

import React, { useEffect, useState } from "react"
import { Tab, Tabs } from "react-bootstrap"
import { useCookies } from "react-cookie"
import NonCompliant from "./nonCompliant.jsx"
import ReadyForReview from "./readyForReview.jsx"
import NewRequest from "./newRequest.jsx"
import axios from "axios"
import currencyFormat from "../../utilities/currencyFormat"
import Swal from "sweetalert2"

const URI_DASHBOARD = process.env.REACT_APP_URI_DASHBOARD

export default function Dashboard() {
	//VARIABLE TO MANAGE WHOS LOG IN
	const [cookie] = useCookies(["userLogged"])
	const userLogged = cookie.userLogged

	// VARIABLE TO MANAGE THE INFORMATION FOR THE DASHBOARD
	const [dashboardInformation, setDashboardInformation] = useState(null)

	// VARIABLE TO MANAGE THE TABS
	const [key, setKey] = useState(0)

	useEffect(() => {
		getDashboardInformation()
	}, [])

	// FUNCTION TO GET THE INFORMATION TO DISPLAY IN THE SQUARES AND TABLES
	const getDashboardInformation = () => {
		Swal.fire({
			footer: 'Loading, please wait',
			showConfirmButton: false,
			allowOutsideClick: false,
			allowEscapeKey: false,
			didOpen: () => {
				Swal.showLoading()
			},
		})

		axios.post(URI_DASHBOARD, {
			rol: userLogged.rol,
			dataID: userLogged.dataID,
		}).then(response => {
			setDashboardInformation(response.data)
			Swal.close()
		}).catch(error => {
			console.log(error.message)
		})
	}

	return (
		<section className="dashboard">
			<div className="infomationList">
				{dashboardInformation && dashboardInformation.resumedInformation.map((item, index) => (
					<React.Fragment key={index}>
						<div className="informationItem">
							<h3>{dashboardInformation.resumedInformation.length === index + 1 ? currencyFormat(item.value) : item.value}</h3>
							<span>{item.title}</span>
						</div>
						{index === 7 && <div className="w-100 d-none d-lg-block"></div>}
					</React.Fragment>
				))}
			</div>

			<Tabs
				id="controlled-tab-example"
				activeKey={key}
				onSelect={(k) => setKey(k)}
			>
				<Tab eventKey={0} title="Non-compliant">
					{dashboardInformation && <NonCompliant
						suppliersNonCompliant={dashboardInformation.tableInformation.nonCompliant}
					/>}
				</Tab>
				<Tab eventKey={1} title="Ready to review">
					{dashboardInformation && <ReadyForReview
						suppliersReadyForReview={dashboardInformation.tableInformation.readyForReview}
					/>}
				</Tab>
				<Tab eventKey={2} title="New request">
					{dashboardInformation && <NewRequest
						suppliersNewRequest={dashboardInformation.tableInformation.newRequest}
					/>}
				</Tab>
			</Tabs>
		</section>
	)
}