import { useEffect, useState, useMemo, useCallback } from "react";
// import Axios from "axios"
import "../../../assets/css/tabs/documentComplianceNew.css"

import DocumentComplianceTable from './documentComplianceTable';

import {
    getAllIndustries,
    getAllClients,
    getDocumentReference,
    updateClientDocuments,
    getClientName,
    getClientDocPeriodicity,
    createUniqueDocuments,
    checkClientCompliance,
    updateGeneralDocs
} from './utils';

export default function DocumentComplianceNew(props) {
    const {
        documentCompliance,
        supplier,
        setSupplier,
        supplierID,
        typePerson,
        serverDate,
        rfc,
        companyName,
        handleSaveChanges,
        asClient = null,
    } = props

    const [allClients, setAllClients] = useState(null)
    const [allIndustries, setAllIndustries] = useState(null)
    const [genericDocuments, setGenericDocuments] = useState(null)
    const [clientDocuments, setClientDocuments] = useState(null)
    const [selectedClient, setSelectedClient] = useState(null)

    useEffect(() => {
        if (!documentCompliance) return

        setGenericDocuments(documentCompliance.genericDocuments)
        setClientDocuments(documentCompliance.clients)
    }, [documentCompliance])

    // FETCH ALL DATA NEEDED
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch all clients
                const clientsData = await getAllClients()
                setAllClients(clientsData)

                const industriesData = await getAllIndustries()
                setAllIndustries(industriesData)

                const genDocData = await getDocumentReference(supplierID)
                setSupplier(prevState => {
                    return {
                        ...prevState,
                        documentCompliance: genDocData
                    }
                })
            } catch (error) {
                console.error("Error fetching data: ", error)
            }
        }

        fetchData()
    }, [supplierID])

    // GET PERIODICITY FOR EACH CLIENT DOCUMENT AND CLIENT NAME
    useEffect(() => {
        if (!allClients || !clientDocuments || clientDocuments?.length === 0) return

        // Add client name to each document
        let newClientDocuments = getClientName(clientDocuments, allClients)
        // console.log(test)

        // Add periodicity for each document
        newClientDocuments = getClientDocPeriodicity(newClientDocuments, allClients)

        // Create a unique list of documents
        newClientDocuments = createUniqueDocuments(newClientDocuments)

        // CHECK IF CLIENTS HAVE AT LEAST ONE DOCUMENT WITHOUT COMPLIANCY
        newClientDocuments = checkClientCompliance(newClientDocuments)

        setClientDocuments(newClientDocuments)
        setSelectedClient(prev => {
            if (!prev) return newClientDocuments[0]

            return prev
        })
    }, [allClients, supplier, documentCompliance])

    useEffect(() => {
        if (!allIndustries || !genericDocuments || genericDocuments?.length === 0 || !allClients) return

        setGenericDocuments(prev => {
            return {
                ...prev,
                allDocs: asClient ? updateClientDocuments(genericDocuments, allClients) : updateGeneralDocs(genericDocuments, allIndustries)
            }
        })
    }, [allClients, allIndustries, supplier, documentCompliance])

    const handleChangeClient = (client) => {
        setSelectedClient(client)
    }

    const updateDocument = useCallback((docUpdated, typeOfDoc, clientId) => {
        if (clientId) {
            // Update client document
            const updatedClientDocs = clientDocuments.map(client => {
                if (client._id === clientId) {
                    const docExist = client.clientDocuments[typeOfDoc].some(doc => doc.documentID === docUpdated.documentID &&
                        doc.monthCreatedAt === docUpdated.monthCreatedAt &&
                        doc.yearCreatedAt === docUpdated.yearCreatedAt)

                    let newDocs
                    if (docExist) {
                        newDocs = client.clientDocuments[typeOfDoc].map(doc => {
                            return doc.documentID === docUpdated.documentID &&
                                doc.monthCreatedAt === docUpdated.monthCreatedAt &&
                                doc.yearCreatedAt === docUpdated.yearCreatedAt ? docUpdated : doc;
                        })
                    } else {
                        newDocs = [...client.clientDocuments[typeOfDoc]]
                        newDocs.unshift(docUpdated)
                    }

                    const clientUpdated = {
                        ...client,
                        clientDocuments: {
                            ...client.clientDocuments,
                            [typeOfDoc]: newDocs
                        }
                    };

                    return clientUpdated;
                }

                return client;
            });

            const updateClientSelected = updatedClientDocs.find(client => client._id === selectedClient._id)

            setSelectedClient(updateClientSelected)
            setClientDocuments(updatedClientDocs)

            const updatedSupplier = {
                ...supplier,
                documentCompliance: {
                    ...supplier.documentCompliance,
                    clients: updatedClientDocs
                }
            }

            setSupplier(updatedSupplier)
            return updatedSupplier
        }

        // Update general documents
        let genDocsClone
        if (typeOfDoc === 'general' && docUpdated.newGeneral) {
            const genDocsCopy = { ...genericDocuments }

            const genDocsUpdated = genDocsCopy.general.map(doc => {
                if (doc.documentID === docUpdated.documentID) {
                    genDocsCopy.historicDocuments.push(doc)

                    return docUpdated
                }

                return doc
            })

            genDocsClone = {
                ...genDocsCopy,
                general: genDocsUpdated
            }

            setGenericDocuments(genDocsClone)
        } else {
            const docExist = genericDocuments[typeOfDoc].some(doc => doc.documentID === docUpdated.documentID &&
                doc.monthCreatedAt === docUpdated.monthCreatedAt &&
                doc.yearCreatedAt === docUpdated.yearCreatedAt)

            let newDocs
            if (docExist) {
                newDocs = genericDocuments[typeOfDoc].map(doc => doc.documentID === docUpdated.documentID &&
                    doc.monthCreatedAt === docUpdated.monthCreatedAt &&
                    doc.yearCreatedAt === docUpdated.yearCreatedAt ? docUpdated : doc)
            } else {
                newDocs = [...genericDocuments[typeOfDoc]]
                newDocs.unshift(docUpdated)
            }


            genDocsClone = {
                ...genericDocuments,
                [typeOfDoc]: newDocs
            }

            setGenericDocuments(genDocsClone)
        }

        const updatedSupplier = {
            ...supplier,
            documentCompliance: {
                ...supplier.documentCompliance,
                genericDocuments: genDocsClone
            }
        }

        setSupplier(updatedSupplier)

        return updatedSupplier
    }, [clientDocuments, genericDocuments, supplier, selectedClient])

    const showClientsTable = useMemo(() => clientDocuments?.length > 0, [clientDocuments])

    return (
        <main className="doc-comp-tab-section">
            <DocumentComplianceTable
                headerTitle={"Documents"}
                supplierID={supplierID}
                items={genericDocuments}
                serverDate={serverDate}
                updateDocument={updateDocument}
                typePerson={typePerson}
                rfc={rfc}
                companyName={companyName}
                showStatus
                handleSaveChanges={handleSaveChanges}
            />

            {showClientsTable &&
                <DocumentComplianceTable
                    headerTitle={"Specific documents by client"}
                    supplierID={supplierID}
                    items={selectedClient?.clientDocuments}
                    clients={clientDocuments}
                    selectedClient={selectedClient}
                    serverDate={serverDate}
                    updateDocument={updateDocument}
                    typePerson={typePerson}
                    rfc={rfc}
                    companyName={companyName}
                    handleChangeClient={handleChangeClient}
                    clientFilter
                    handleSaveChanges={handleSaveChanges}
                />
            }
        </main>
    )
}
