import "../../assets/css/clientsAdminView/massiveUploadModal.css"

import close from "../../assets/images/close.svg"

import axios from "axios";
import { useRef, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import Swal from "sweetalert2";

const URI_MASSIVE_SUPPLIER_UPLOAD = process.env.REACT_APP_URI_CLIENT_MASSIVE_SUPPLIER_UPLOAD

export default function MassiveUploadModal(props) {
	// VARIABLES RECIVED BY PROPS
	const { show, onHide, entities } = props

	// VARIABLE TO MANAGE TO WICH ENTITY UPLOAD THE SUPPLIERS
	const [selectedEntityID, setSelectedEntityID] = useState(null)

	// VARIABLE TO MANAGE THE REF
	const massiveUploadRef = useRef(null)

	// FUNCTION TO MASSIVE UPLOAD SUPPLIERS
	const handleMassiveUpload = async () => {
		const element = massiveUploadRef.current;

		if (element.files[0]) {
			Swal.fire({
				footer: 'Loading, please wait',
				showConfirmButton: false,
				allowOutsideClick: false,
				allowEscapeKey: false,
				didOpen: () => {
					Swal.showLoading()
				},
			})

			const allowedExtensions = /(\.xlsx|\.xls)$/i;

			if (!allowedExtensions.exec(element.files[0].name)) {
				Swal.fire({
					icon: 'error',
					text: 'Please select a excel file',
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
					didOpen: () => {
						Swal.hideLoading()
					}
				})
				element.value = '';
				return;
			}

			const formData = new FormData()

			formData.append("rfc", "temporal")
			formData.append("clientID", selectedEntityID)
			formData.append("document", element.files[0])

			axios.post(URI_MASSIVE_SUPPLIER_UPLOAD, formData).then(response => {
				if (response.data.errorMessage)
					return Swal.fire({
						icon: 'error',
						text: response.data.errorMessage,
						showConfirmButton: false,
						timer: 5000,
						timerProgressBar: true,
					})

				Swal.fire({
					icon: 'success',
					text: response.data.answer,
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
				})
			}).catch(error => {
				Swal.fire({
					icon: 'error',
					text: error.message,
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
				})
			})

			element.value = '';
		}
		onHide()
	}

	return (
		<Modal
			show={show}
			onHide={onHide}
			size="lg"
			centered
			className="massiveSupplierUpload">

			<Modal.Body>
				<input ref={massiveUploadRef} type="file" className="d-none" onChange={() => handleMassiveUpload()} accept=".xlsx, .xls" />
				<img className="closeModal" src={close} onClick={onHide} alt="Prodensa Supplier" />

				<div className="inputContainer">
					<span className="label">Select the entity to upload the suppliers</span>
					<Dropdown>
						<Dropdown.Toggle className={`${selectedEntityID ? "" : "default"}`}>
							{selectedEntityID ? entities.find(entity => entity._id.toString() === selectedEntityID).entityName : "Select entity"}
						</Dropdown.Toggle>

						<Dropdown.Menu>
							{entities.map((entity, index) => (
								<Dropdown.Item key={index} onClick={() => setSelectedEntityID(entity._id.toString())}>{entity.entityName}</Dropdown.Item>
							))}
						</Dropdown.Menu>
					</Dropdown>
				</div>

				<div className="actions">
					<button className={`btnBgBlueTextGradient ${selectedEntityID ? "show" : ""}`}
						onClick={() => massiveUploadRef.current.click()}>Select file and upload</button>
					<button className="btnBgRedTextWhite" onClick={onHide}>Cancel</button>
				</div>
			</Modal.Body>
		</Modal>
	)
}